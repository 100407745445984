import React, {useEffect, useState} from "react";
import axios from "axios";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";

function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [passwordsMatchError, setPasswordsMatchError] = useState(false); // New state for current vs new password check
    const [isFormValid, setIsFormValid] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false); // New state for updating status
    const [message, setMessage] = useState(null); // For success or error messages
    const [messageColor, setMessageColor] = useState(""); // For message color (green for success, red for error)

    // Password validation function
    const validatePassword = (value) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/; // At least 8 chars, uppercase, lowercase, number, special char
        return regex.test(value);
    };

    // Handle blur on new password
    const handleNewPasswordBlur = (e) => {
        const value = e.target.value;

        // Validate password strength
        const passwordIsValid = validatePassword(value);
        setNewPasswordError(!passwordIsValid);

        // Check if new password matches current password
        if (value === currentPassword) {
            setPasswordsMatchError(true);
        } else {
            setPasswordsMatchError(false);
        }

        checkFormValidity();
    };

    // Handle blur on confirm password
    const handleConfirmPasswordBlur = (e) => {
        const value = e.target.value;
        setConfirmPasswordError(value !== newPassword);
        checkFormValidity();
    };

    // Check if the form is valid
    const checkFormValidity = () => {
        const isValid =
            currentPassword &&
            newPassword &&
            confirmPassword &&
            !newPasswordError &&
            !confirmPasswordError &&
            !passwordsMatchError && // Ensure new password is not the same as the current password
            newPassword === confirmPassword;
        setIsFormValid(isValid);
    };

    // Call checkFormValidity on every input change
    useEffect(() => {
        checkFormValidity();
    }, [currentPassword, newPassword, confirmPassword, newPasswordError, confirmPasswordError, passwordsMatchError]);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        if (isFormValid) {
            setIsUpdating(true); // Disable the button and show "Updating password..."
            setMessage(null); // Reset any previous message

            const csrfToken = await getCsrfTokenFromCookies();
            const data = {
                currentPassword,
                newPassword,
            };

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/changepassword`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-csrf-token": csrfToken,
                        },
                        withCredentials: true,
                    }
                );

                // If the response is successful, handle success
                if (response.status === 200) {
                    setMessage("Password was successfully changed.");
                    setMessageColor("green");
                    setCurrentPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                }
            } catch (error) {
                console.error("Error updating password:", error);

                // Handle any errors from the backend response
                if (error.response && error.response.data) {
                    // Check different error formats (message or error)
                    const errorMessage = error.response.data.message || error.response.data.error || "Something went wrong.";
                    setMessage(errorMessage);
                } else {
                    // Handle cases where there's no response from the server (e.g., network issues)
                    setMessage("An unexpected error occurred.");
                }

                setMessageColor("red");
            } finally {
                setIsUpdating(false); // Re-enable the button after the request finishes
            }
        }
    };

    return (
        <Card id="change-password">
            <MDBox p={3}>
                <MDTypography variant="h5">Change Password</MDTypography>
            </MDBox>
            <MDBox component="form" pb={3} px={3} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MDInput
                            fullWidth
                            label="Current Password"
                            inputProps={{type: "password", autoComplete: ""}}
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDInput
                            fullWidth
                            label="New Password"
                            inputProps={{type: "password", autoComplete: ""}}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            onBlur={handleNewPasswordBlur}
                            error={newPasswordError || passwordsMatchError} // Show error if passwords match
                            helperText={
                                newPasswordError
                                    ? "Password must be at least 8 characters with uppercase, lowercase, number, and special character."
                                    : passwordsMatchError
                                        ? "Your current and new password are the same. Please enter a new password."
                                        : ""
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MDInput
                            fullWidth
                            label="Confirm New Password"
                            inputProps={{type: "password", autoComplete: ""}}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onBlur={handleConfirmPasswordBlur}
                            error={confirmPasswordError}
                            helperText={confirmPasswordError ? "Passwords do not match." : ""}
                        />
                    </Grid>
                </Grid>

                {/* Message display for success or error */}
                {message && (
                    <MDBox mt={2}>
                        <MDTypography variant="h6" style={{color: messageColor}}>
                            {message}
                        </MDTypography>
                    </MDBox>
                )}

                <MDBox mt={3}>
                    <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        type="submit"
                        disabled={!isFormValid || isUpdating} // Disable when form is invalid or updating
                    >
                        {isUpdating ? "Updating password..." : "Update Password"}
                    </MDButton>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default ChangePassword;