import React, {useState} from 'react';
import {useParams, useRevalidator} from 'react-router-dom';
import {Box, CircularProgress, MenuItem, Modal, Select, TextField} from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";

import axios from "axios";
import {getCsrfTokenFromCookies} from "util/getCsrfTokenFromCookies";

const AddActivity = ({onActivityAdded}) => {
    const {clientId} = useParams();
    const [open, setOpen] = useState(false);
    const [activityType, setActivityType] = useState('');
    const [subType, setSubType] = useState('');
    const [productType, setProductType] = useState('');
    const [date, setDate] = useState(null);
    const [benefit, setBenefit] = useState('');
    const [premium, setPremium] = useState('');
    const [commission, setCommission] = useState('');
    const [monthlyContribution, setMonthlyContribution] = useState('');
    const [initialContribution, setInitialContribution] = useState('');
    const [dependents, setDependents] = useState('');
    const [closeReason, setCloseReason] = useState('');
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('idle');
    const [errorMessage, setErrorMessage] = useState('');
    const {revalidate} = useRevalidator();

    const isFollowUpValid = () => subType && date && notes;

    const isSaleValid = () => {
        if (!productType || !notes) return false;

        if (['life', 'disability', 'dreadedDisease'].includes(productType)) {
            return benefit && premium && commission;
        }

        if (['taxFree', 'retirement'].includes(productType)) {
            return monthlyContribution && initialContribution && commission;
        }

        if (productType === 'medicalAid') {
            return premium && dependents && commission;
        }

        return true; // For 'other' product type
    };

    const isCloseValid = () => closeReason && notes;


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setActivityType('');
        setSubType('');
        setProductType('');
        setDate(null);
        setBenefit('');
        setPremium('');
        setCommission('');
        setMonthlyContribution('');
        setInitialContribution('');
        setDependents('');
        setCloseReason('');
        setNotes('');
        setStatus('idle');
        setErrorMessage('');
    };

    const handleAdd = async () => {
        setStatus('loading');
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const activityData = {
                clientId,
                type: activityType,
                ...(activityType === 'followUp' && {
                    subType,
                    followUpDate: date,
                }),
                ...(activityType === 'sale' && {
                    productType,
                    ...(productType === 'medicalAid' && {
                        premium,
                        dependents,
                        commission,
                    }),
                    ...((['life', 'disability', 'dreadedDisease'].includes(productType)) && {
                        benefit,
                        premium,
                        commission,
                    }),
                    ...((['taxFree', 'retirement'].includes(productType)) && {
                        monthlyContribution,
                        initialContribution,
                        commission,
                    }),
                }),
                ...(activityType === 'close' && {
                    closeReason,
                }),
                notes,
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/provider/createActivity`,
                activityData,
                {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );
            setStatus('success');
            if (onActivityAdded) {
                onActivityAdded();
            }
            revalidate()
        } catch (error) {
            setStatus('error');
            setErrorMessage(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };


    const renderFollowUpFields = () => (
        <>
            <Select
                fullWidth
                value={subType}
                onChange={(e) => setSubType(e.target.value)}
                displayEmpty
                sx={{mb: 2, height: "37px"}}
            >
                <MenuItem value="" disabled>Select Type</MenuItem>
                <MenuItem value="call">Call</MenuItem>
                <MenuItem value="meeting">Meeting</MenuItem>
            </Select>
            <MDBox mb={2}>
                <MDDatePicker
                    input={{placeholder: "Select follow-up date", sx: {height: "37px", mb: 2}}}
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                />
            </MDBox>
        </>
    );


    const renderSaleFields = () => (
        <>
            <Select
                fullWidth
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                sx={{mb: 2, height: "37px"}}
                displayEmpty
            >
                <MenuItem value="" disabled>Select Product Type</MenuItem>
                <MenuItem value="life">Life</MenuItem>
                <MenuItem value="disability">Disability</MenuItem>
                <MenuItem value="dreadedDisease">Dreaded Disease</MenuItem>
                <MenuItem value="retirement">Retirement</MenuItem>
                <MenuItem value="taxFree">Tax-Free</MenuItem>
                <MenuItem value="medicalAid">Medical Aid</MenuItem>
                <MenuItem value="other">Other</MenuItem>
            </Select>

            {['life', 'disability', 'dreadedDisease'].includes(productType) && (
                <>
                    <MDInput
                        type="number"
                        label="Benefit Amount"
                        value={benefit}
                        onChange={(e) => setBenefit(e.target.value)}
                        sx={{mb: 2}}
                    />
                    <MDInput
                        type="number"
                        label="Premium"
                        value={premium}
                        onChange={(e) => setPremium(e.target.value)}
                        sx={{mb: 2}}
                    />
                    <MDInput
                        type="number"
                        label="Commission"
                        value={commission}
                        onChange={(e) => setCommission(e.target.value)}
                        sx={{mb: 2}}
                    />
                </>
            )}

            {['taxFree', 'retirement'].includes(productType) && (
                <>
                    <MDInput
                        type="number"
                        label="Monthly Contribution"
                        value={monthlyContribution}
                        onChange={(e) => setMonthlyContribution(e.target.value)}
                        sx={{mb: 2}}
                    />
                    <MDInput
                        type="number"
                        label="Initial Contribution"
                        value={initialContribution}
                        onChange={(e) => setInitialContribution(e.target.value)}
                        sx={{mb: 2}}
                    />
                    <MDInput
                        type="number"
                        label="Commission"
                        value={commission}
                        onChange={(e) => setCommission(e.target.value)}
                        sx={{mb: 2}}
                    />
                </>
            )}

            {productType === 'medicalAid' && (
                <>
                    <TextField
                        fullWidth
                        label="Premium"
                        value={premium}
                        onChange={(e) => setPremium(e.target.value)}
                        sx={{mb: 2}}
                    />
                    <TextField
                        fullWidth
                        label="Dependents"
                        value={dependents}
                        onChange={(e) => setDependents(e.target.value)}
                        sx={{mb: 2}}
                    />
                    <TextField
                        fullWidth
                        label="Commission"
                        value={commission}
                        onChange={(e) => setCommission(e.target.value)}
                        sx={{mb: 2}}
                    />
                </>
            )}
        </>
    );

    const renderCloseFields = () => (
        <Select
            fullWidth
            value={closeReason}
            onChange={(e) => setCloseReason(e.target.value)}
            sx={{mb: 2, height: "37px"}}
            displayEmpty
        >
            <MenuItem value="" disabled>Select Reason</MenuItem>
            <MenuItem value="notReachable">Client Not Reachable</MenuItem>
            <MenuItem value="affordability">Affordability Issues</MenuItem>
            <MenuItem value="noTakeup">Client decided not to take up the proposal</MenuItem>
            <MenuItem value="comeBack">Interested but will come back to me</MenuItem>
            <MenuItem value="noContact">Client asked not to be contacted again</MenuItem>
        </Select>
    );

    return (
        <>
            <MDButton variant="gradient" color="info" size="small" onClick={handleOpen}>
                Add Activity
            </MDButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="add-activity-modal"
                aria-describedby="add-activity-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <MDTypography variant="h6" component="h2" mb={2}>
                        Add New Activity
                    </MDTypography>
                    {status === 'idle' && (
                        <>
                            <Select
                                fullWidth
                                value={activityType}
                                onChange={(e) => setActivityType(e.target.value)}
                                displayEmpty
                                sx={{mb: 2, height: 37}}
                            >
                                <MenuItem value="" disabled>Select Activity Type</MenuItem>
                                <MenuItem value="followUp">Follow-up</MenuItem>
                                <MenuItem value="sale">Sale</MenuItem>
                                <MenuItem value="close">Close</MenuItem>
                            </Select>

                            {activityType === 'followUp' && renderFollowUpFields()}
                            {activityType === 'sale' && renderSaleFields()}
                            {activityType === 'close' && renderCloseFields()}

                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                placeholder="Add notes here..."
                                variant="outlined"
                                sx={{mb: 2}}
                            />
                            <MDBox display="flex" justifyContent="flex-end" mt={2}>
                                <MDButton color="secondary" onClick={handleClose} sx={{mr: 1}}>
                                    Cancel
                                </MDButton>
                                <MDButton
                                    color="info"
                                    onClick={handleAdd}
                                    disabled={
                                        !activityType ||
                                        (activityType === 'followUp' && !isFollowUpValid()) ||
                                        (activityType === 'sale' && !isSaleValid()) ||
                                        (activityType === 'close' && !isCloseValid())
                                    }>
                                    Add Activity
                                </MDButton>
                            </MDBox>
                        </>
                    )}
                    {status === 'loading' && (
                        <MDBox display="flex" justifyContent="center" alignItems="center" height={200}>
                            <CircularProgress/>
                        </MDBox>
                    )}
                    {status === 'success' && (
                        <MDBox textAlign="center">
                            <MDTypography variant="body1" mb={2}>
                                Activity added successfully!
                            </MDTypography>
                            <MDButton color="info" onClick={handleClose}>
                                Close
                            </MDButton>
                        </MDBox>
                    )}
                    {status === 'error' && (
                        <MDBox textAlign="center">
                            <MDTypography variant="body1" color="error" mb={2}>
                                {errorMessage}
                            </MDTypography>
                            <MDButton color="info" onClick={() => setStatus('idle')}>
                                Try Again
                            </MDButton>
                        </MDBox>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default AddActivity;
