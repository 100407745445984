// index.js (DashboardNavbar component)
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

import {navbar, navbarContainer, navbarDesktopMenu, navbarIconButton, navbarMobileMenu, navbarRow} from "./styles";
import {setMiniSidenav, setOpenConfigurator, setTransparentNavbar, useMaterialUIController} from "context";
import YouTrackFeedbackForm from "./YouTrackFeedbackForm"; // Add this import

function DashboardNavbar({absolute = false, light = false, isMini = false, brand}) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode} = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const route = useLocation().pathname.split("/").slice(1);

    useEffect(() => {
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        window.addEventListener("scroll", handleTransparentNavbar);
        handleTransparentNavbar();

        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
    const handleCloseMenu = () => setOpenMenu(false);

    const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    return (
        <>
            <AppBar
                position={absolute ? "absolute" : navbarType}
                color="inherit"
                sx={(theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
            >
                {miniSidenav && (
                    <MDBox display="flex" alignItems="center" mb={1} ml={1}>
                        <MDBox component={Link} to="/app" display="flex" alignItems="center">
                            <MDBox component="img" src={brand} alt="Brand" width="2rem"/>
                            <MDBox ml={1}>
                                <MDTypography variant="button" fontWeight="medium">
                                    GrowYourBucks
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )}
                <Toolbar sx={(theme) => navbarContainer(theme)}>
                    <MDBox color="inherit" mb={{xs: 1, md: 0}} sx={(theme) => navbarRow(theme, {isMini})}>
                        <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
                            <Icon fontSize="medium" sx={iconsStyle}>
                                {miniSidenav ? "menu_open" : "menu"}
                            </Icon>
                        </IconButton>
                    </MDBox>
                    {isMini ? null : (
                        <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
                            <MDBox color={light ? "white" : "inherit"}>
                                <Link to="/app/profile-settings">
                                    <IconButton sx={navbarIconButton} size="small" disableRipple>
                                        <Icon sx={iconsStyle}>account_circle</Icon>
                                    </IconButton>
                                </Link>
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarMobileMenu}
                                    onClick={handleMiniSidenav}
                                >
                                    <Icon sx={iconsStyle} fontSize="medium">
                                        {miniSidenav ? "menu_open" : "menu"}
                                    </Icon>
                                </IconButton>
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarIconButton}
                                    onClick={handleConfiguratorOpen}
                                >
                                    <Icon sx={iconsStyle}>settings</Icon>
                                </IconButton>
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarIconButton}
                                    onClick={() => {
                                        if (typeof window.openFeedbackForm === "function") {
                                            window.openFeedbackForm();
                                        } else {
                                            console.error("YouTrack form not initialized.");
                                        }
                                    }}
                                >
                                    <MDBadge badgeContent={0} color="error" size="xs" circular>
                                        <Icon sx={iconsStyle}>help</Icon>
                                    </MDBadge>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                    )}
                </Toolbar>
            </AppBar>
            <YouTrackFeedbackForm/>
        </>
    );
}

DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
    brand: PropTypes.string
};

export default DashboardNavbar;