import {useEffect, useState} from 'react';

const Logout = () => {
    const [loggedOut, setLoggedOut] = useState(false);

    useEffect(() => {
        // Send logout request to server
        fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
            method: 'POST',
            credentials: 'include', // Include HTTP-only cookies
        })
            .then((res) => {
                if (res.ok) {
                    // Remove any local storage items
                    localStorage.clear();
                    // Redirect to login page
                    window.location.href = '/login';
                }
            })
            .catch((err) => console.error(err));
    }, []);

    return null;
};

export default Logout;