import React from "react";
import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function BasicInfo({data}) {
    return (
        <MDBox>
            <Card id="basic-info" sx={{overflow: "visible"}}>
                <MDBox p={3}>
                    <MDTypography variant="h5">Basic Info</MDTypography>
                </MDBox>
                <MDBox pb={3} px={3}>
                    <Grid container spacing={3}>
                        {["firstName", "lastName", "email", "phone"].map((field) => (
                            <Grid item xs={12} sm={6} key={field}>
                                <MDBox display="flex" alignItems="center" justifyContent="space-between">
                                    <MDTypography variant="body1">{data[field]}</MDTypography>
                                    <MDButton
                                        component={Link} // Render as a Link component
                                        to={`editbasicinfo/${field}`} // Use correct dynamic URL
                                        variant="text"
                                        color="info"
                                    >
                                        Edit
                                    </MDButton>
                                </MDBox>
                            </Grid>
                        ))}
                    </Grid>
                </MDBox>
            </Card>
        </MDBox>
    );
}

export default BasicInfo;