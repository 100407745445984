import {useMemo} from "react";
import PropTypes from "prop-types";
import {Bar} from "react-chartjs-2";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip} from "chart.js";
import MDBox from "../../../../../../components/MDBox";
import configs from "./configs";
import colors from "../../../../../../assets/theme/base/colors";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

function VerticalBarChart({height = "19.125rem", chart}) {
    const chartDatasets = chart.datasets
        ? chart.datasets.map((dataset) => ({
            ...dataset,
            weight: 5,
            borderWidth: 0,
            borderRadius: 4,
            backgroundColor: dataset.color.map((color) =>
                colors[color] ? colors[color].main : colors.dark.main
            ),  // Use an array of colors
            fill: false,
            maxBarThickness: 100,
        }))
        : [];

    const {data, options} = configs(chart.labels || [], chartDatasets);

    return useMemo(
        () => (
            <MDBox height={height}>
                <Bar data={data} options={options} redraw/>
            </MDBox>
        ),
        [chart, height]
    );
}

// Setting default values for the props of VerticalBarChart


// Typechecking props for the VerticalBarChart
VerticalBarChart.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default VerticalBarChart;