import {Form, Link, redirect, useActionData} from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "pages/public/components/BasicLayout";
import bgImage from "assets/images/back9.png";
import {useEffect, useState} from "react";
import TrackingScriptLoader from "../../util/TrackingScriptLoader";

function Login() {
    const actionData = useActionData();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = () => {
        setIsSubmitting(true); // Disable button and show "Logging in..."
    };
    useEffect(() => {
        if (actionData?.formSubmitted === false) {
            setIsSubmitting(false); // Re-enable the button on error
        }
    }, [actionData]);
    return (

        <BasicLayout image={bgImage}>
            <TrackingScriptLoader
                id="facebook-tracking"
                inlineScript={`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '2243764045999242');
fbq('track', 'PageView');
        `}
            />
            <MDBox position="relative" mt={3}>
                <Card>
                    <MDBox
                        variant="contained"
                        bgColor="transparent"
                        borderRadius="none"
                        coloredShadow="none"
                        shadow="none"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h6" fontWeight="medium" color="dark" mt={3}>
                            Sign in
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                        {/* Use Form component from react-router-dom */}
                        <Form method="post" onSubmit={handleSubmit}>
                            <MDBox mb={2}>
                                <MDInput name="email" type="email" label="Email" fullWidth required
                                         autoComplete="email"/>
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput name="password" type="password" label="Password" fullWidth required
                                         autoComplete="current-password"/>
                            </MDBox>
                            {/* Display error message */}
                            {actionData?.error && (
                                <MDBox mb={2}>
                                    <MDTypography variant="body2" color="error" align="center">
                                        {actionData.error}
                                    </MDTypography>
                                </MDBox>
                            )}
                            <MDTypography variant="button" color="text">
                                Forgot your password?{" "}
                                <MDTypography
                                    component={Link}
                                    to="/requestpasswordreset"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Reset it
                                </MDTypography>
                            </MDTypography>
                            <MDBox mt={4} mb={1}>
                                <MDButton type="submit" variant="gradient" color="info" fullWidth
                                          disabled={isSubmitting}>
                                    {isSubmitting ? "Logging in..." : "Login"}
                                </MDButton>
                            </MDBox>
                            <MDBox mt={3} mb={1} textAlign="center">
                                <MDTypography variant="button" color="text">
                                    Don&apos;t have an account?{" "}
                                    <MDTypography
                                        component={Link}
                                        to="/"
                                        variant="button"
                                        color="info"
                                        fontWeight="medium"
                                        textGradient

                                    >
                                        Sign up
                                    </MDTypography>
                                </MDTypography>
                            </MDBox>
                        </Form>
                    </MDBox>
                </Card>
            </MDBox>
        </BasicLayout>
    );
}

export default Login;

// Action function for form submission
export async function loginAction({request}) {
    const formData = await request.formData();
    const postData = Object.fromEntries(formData);

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/login`,
            {
                email: postData.email,
                password: postData.password,
            },
            {withCredentials: true}
        );
        if (response.data.phoneVerified === true) {
            return redirect('/app');
        } else {
            const encodedPhone = encodeURIComponent(response.data.phone);
            return redirect(`/verifyphone?phone=${encodedPhone}`);
        }
    } catch (error) {
        console.error(error); // Handle and display error if needed
        // Return error message to be displayed in the form
        return {
            formSubmitted: false,
            error: error.response?.data?.message || "Invalid credentials. Please try again. If you don't have an account, please sign up below"
        };
    }
}