import React, {useState} from 'react';
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDAlert from 'components/MDAlert';
import {Link} from 'react-router-dom';
import {getCsrfTokenFromCookies} from 'util/getCsrfTokenFromCookies';

const EmailVerificationAlert = () => {
    const [buttonText, setButtonText] = useState('Send Again');
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleSendAgain = async () => {
        setButtonDisabled(true);

        try {
            const csrfToken = await getCsrfTokenFromCookies();
            await axios.get(`${process.env.REACT_APP_API_URL}/auth/resendemail`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-csrf-token": csrfToken,
                },
                withCredentials: true,
            });
            setButtonText('Check your mail');
        } catch (error) {
            console.error('Failed to resend the email:', error);
            setButtonDisabled(false);
        }
    };

    return (
        <MDBox mb={4}>
            <MDAlert color="error">
                <MDBox display="flex" flexDirection="column" alignItems="flex-start">
                    <MDBox mb={2}>
                        Your e-mail is not verified yet. Please click on the link we have e-mailed you to verify your
                        email.
                    </MDBox>
                    <MDBox display="flex" flexDirection={{xs: 'column', sm: 'row'}} gap={1}>
                        <MDButton
                            size="small"
                            color="dark"
                            onClick={handleSendAgain}
                            disabled={buttonDisabled}
                        >
                            {buttonText}
                        </MDButton>
                        <MDButton
                            size="small"
                            color="dark"
                            component={Link}
                            to="/app/profile-settings"
                        >
                            Change Email
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDAlert>
        </MDBox>
    );
};

export default EmailVerificationAlert;