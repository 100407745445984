import {createContext, useContext, useState} from "react";

// Create the context
const GlobalContext = createContext();

// Create a provider component
export const ProfileProvider = ({children}) => {
    const [profileType, setProfileType] = useState(null);

    return (
        <GlobalContext.Provider value={{profileType, setProfileType}}>
            {children}
        </GlobalContext.Provider>
    );
};

// Create a custom hook to use the ProfileContext
export const useProfile = () => {
    return useContext(GlobalContext);
};
