import React, {useEffect, useState} from 'react';
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import Icon from "@mui/material/Icon";
import Modal from "@mui/material/Modal";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import {getCsrfTokenFromCookies} from "../../../../util/getCsrfTokenFromCookies";
import axios from "axios";
import {useMaterialUIController} from "context";

function AssignmentHolidays() {
    const [controller] = useMaterialUIController();
    const {darkMode} = controller;
    const [showModal, setShowModal] = useState(false);
    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeHolidays, setActiveHolidays] = useState(null);
    const [editingHolidayId, setEditingHolidayId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [holidayToDelete, setHolidayToDelete] = useState(null);

    const fetchActiveHolidays = async () => {
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/provider/getAssignmentHoliday`,
                {
                    withCredentials: true,
                    headers: {"x-csrf-token": csrfToken}
                }
            );

            setActiveHolidays(response.data);
        } catch (err) {
            setError("Failed to fetch active holidays");
        }
    };

    useEffect(() => {
        fetchActiveHolidays();
    }, []);


    const handleDelete = async (holidayId) => {
        setLoading(true);
        setError(null);

        try {
            const csrfToken = await getCsrfTokenFromCookies();
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/provider/deleteAssignmentHoliday/${holidayId}`,
                {
                    withCredentials: true,
                    headers: {"x-csrf-token": csrfToken}
                }
            );
            setShowDeleteModal(false);
            fetchActiveHolidays();
        } catch (err) {
            setError(err.response?.data?.error || "Failed to delete assignment holiday");
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (dates.length !== 2) return;
        setLoading(true);
        setError(null);

        try {
            const csrfToken = await getCsrfTokenFromCookies();
            await axios.post(
                `${process.env.REACT_APP_API_URL}/provider/createAssignmentHoliday`,
                {
                    startDate: dates[0],
                    endDate: dates[1]
                },
                {
                    withCredentials: true,
                    headers: {"x-csrf-token": csrfToken}
                }
            );

            setShowModal(false);
            fetchActiveHolidays();
        } catch (err) {
            setError(err.response?.data?.error || "Failed to set assignment holiday");
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async () => {
        if (dates.length !== 2) return;
        setLoading(true);
        setError(null);

        try {
            const csrfToken = await getCsrfTokenFromCookies();
            await axios.put(
                `${process.env.REACT_APP_API_URL}/provider/editAssignmentHoliday/${editingHolidayId}`,
                {
                    startDate: dates[0],
                    endDate: dates[1]
                },
                {
                    withCredentials: true,
                    headers: {"x-csrf-token": csrfToken}
                }
            );

            setShowModal(false);
            setEditingHolidayId(null);
            fetchActiveHolidays();
        } catch (err) {
            setError(err.response?.data?.error || "Failed to edit assignment holiday");
        } finally {
            setLoading(false);
        }
    };


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: darkMode ? '#1a2035' : 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        color: darkMode ? 'white' : 'inherit'
    };

    return (
        <MDBox>
            <MDTypography variant="h4" fontWeight="medium" mb={2}>
                Assignment Holidays
            </MDTypography>

            {activeHolidays && activeHolidays.map((holiday, index) => (
                <MDBox key={index} display="flex" alignItems="center" mb={2}>
                    <MDTypography variant="body2">
                        Holiday Period: {new Date(holiday.startDate).toLocaleDateString()} -
                        {new Date(holiday.endDate).toLocaleDateString()}
                    </MDTypography>
                    <Icon
                        sx={{cursor: 'pointer', ml: 2}}
                        fontSize="small"
                        onClick={() => {
                            setDates([holiday.startDate, holiday.endDate]);
                            setEditingHolidayId(holiday.id);
                            setShowModal(true);
                        }}
                    >
                        edit
                    </Icon>
                    <Icon
                        sx={{cursor: 'pointer', ml: 2}}
                        fontSize="small"
                        onClick={() => {
                            setHolidayToDelete(holiday.id);
                            setShowDeleteModal(true);
                        }}
                        color="error"
                    >
                        delete
                    </Icon>
                </MDBox>
            ))}


            {!activeHolidays?.length && (
                <MDTypography variant="body2" mb={2}>
                    No active assignment holidays
                </MDTypography>
            )}

            <MDButton
                variant="outlined"
                color="info"
                onClick={() => setShowModal(true)}
                startIcon={<Icon>event</Icon>}
                disabled={loading}
            >
                Add Assignment Holiday
            </MDButton>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="holiday-modal-title"
            >
                <MDBox sx={modalStyle}>
                    <MDTypography id="holiday-modal-title" variant="h6" mb={2}>
                        Set Assignment Holiday Period
                    </MDTypography>
                    <MDTypography variant="body2" size='small' mb={2}>Setting assignment holidays, will pause
                        assignment of mandates during these periods. Credits will be rolled over that are
                        accumulated, billing will continue as normal.</MDTypography>

                    <Flatpickr
                        value={dates}
                        options={{
                            mode: "range",
                            dateFormat: "Y-m-d",
                            minDate: "today",
                            theme: darkMode ? "dark" : "light",
                        }}
                        render={({defaultValue, value, ...props}, ref) => {
                            return <input placeholder="Select dates" {...props} ref={ref}/>;
                        }}
                        onChange={date => setDates(date)}
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '0.375rem',
                            border: '1px solid',
                            borderColor: darkMode ? '#ffffff1f' : '#d2d6da',
                            backgroundColor: 'transparent',
                            color: darkMode ? '#fff' : '#344767',
                            fontSize: '0.875rem',
                            transition: 'box-shadow 150ms ease, border-color 150ms ease',
                        }}
                    />


                    <MDBox mt={4} display="flex" gap={2}>
                        <MDButton
                            variant="gradient"
                            color="info"
                            onClick={editingHolidayId ? handleEdit : handleSubmit}
                            disabled={loading || dates.length !== 2}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </MDButton>
                        <MDButton
                            variant="outlined"
                            color="error"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </MDButton>
                    </MDBox>

                    {error && (
                        <MDTypography variant="caption" color="error" mt={2}>
                            {error}
                        </MDTypography>
                    )}
                </MDBox>
            </Modal>
            <Modal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                aria-labelledby="delete-modal-title"
            >
                <MDBox sx={modalStyle}>
                    <MDTypography id="delete-modal-title" variant="h6" mb={2}>
                        Confirm Delete
                    </MDTypography>
                    <MDTypography variant="body2" mb={3}>
                        Are you sure you want to delete this assignment holiday?
                    </MDTypography>
                    <MDBox display="flex" gap={2}>
                        <MDButton
                            variant="gradient"
                            color="error"
                            onClick={async () => {
                                try {
                                    const csrfToken = await getCsrfTokenFromCookies();
                                    await axios.delete(
                                        `${process.env.REACT_APP_API_URL}/provider/deleteAssignmentHoliday/${holidayToDelete}`,
                                        {
                                            withCredentials: true,
                                            headers: {"x-csrf-token": csrfToken}
                                        }
                                    );
                                    setShowDeleteModal(false);
                                    fetchActiveHolidays();
                                } catch (err) {
                                    setError(err.response?.data?.error || "Failed to delete assignment holiday");
                                }
                            }}
                        >
                            Delete
                        </MDButton>
                        <MDButton
                            variant="outlined"
                            color="dark"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </MDButton>
                    </MDBox>
                </MDBox>
            </Modal>
        </MDBox>

    );
}

export default AssignmentHolidays;
