import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function BasicInfo(props) {
    return (
        <Card id="basic-info" sx={{overflow: "visible"}}>
            <MDBox p={3}>
                <MDTypography variant="h5">Basic Info</MDTypography>
            </MDBox>
            <MDBox pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MDBox mb={2}>
                            <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                First Name
                            </MDTypography>
                            <MDTypography variant="body2" fontWeight="regular" color="text">
                                {props.clientData.firstName}
                            </MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox mb={2}>
                            <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                Last Name
                            </MDTypography>
                            <MDTypography variant="body2" fontWeight="regular" color="text">
                                {props.clientData.lastName}
                            </MDTypography>
                        </MDBox>
                    </Grid>

                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MDBox mb={2}>
                            <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                Email
                            </MDTypography>
                            <MDTypography variant="body2" fontWeight="regular" color="text">
                                {props.clientData.email}
                            </MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox mb={2}>
                            <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                Phone
                            </MDTypography>
                            <MDTypography variant="body2" fontWeight="regular" color="text">
                                {props.clientData.phone}
                            </MDTypography>
                        </MDBox>
                    </Grid>

                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MDBox mb={2}>
                            <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                Email Verified
                            </MDTypography>
                            <MDTypography variant="body2" fontWeight="regular" color="text">
                                {props.clientData.emailVerified ? "Yes" : "No"}
                            </MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MDBox mb={2}>
                            <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                Phone Verified
                            </MDTypography>
                            <MDTypography variant="body2" fontWeight="regular" color="text">
                                {props.clientData.phoneVerified ? "Yes" : "No"}
                            </MDTypography>
                        </MDBox>
                    </Grid>

                </Grid>
            </MDBox>
        </Card>
    );
}

export default BasicInfo;