import {useEffect} from 'react';
import PropTypes from 'prop-types';

function TrackingScriptLoader({src, inlineScript, async = true, id}) {
    useEffect(() => {
        // Check if the Facebook Pixel should run based on the environment variable
        const isPixelLive = process.env.REACT_APP_FACEBOOK_PIXEL_LIVE === 'true'

        if (isPixelLive === false) {
            console.log('Facebook Pixel is disabled.');
            return; // Skip script execution if pixel is disabled
        }

        // Check if Facebook Pixel is already initialized to avoid duplicates
        if (window.fbq && window.fbq.instance && window.fbq.instance.loaded) {
            console.log('Facebook Pixel is already initialized.');
            return;
        }

        // Load external script if not already present
        if (src && !document.getElementById(id)) {
            const script = document.createElement('script');
            script.src = src;
            script.async = async;
            script.id = id;
            document.body.appendChild(script);

            script.onload = () => console.log(`Script ${src} loaded successfully.`);
            script.onerror = () => console.error(`Error loading script ${src}.`);
        }

        // Inject inline JavaScript (Pixel initialization) if needed
        if (inlineScript && !window.fbq) {
            const script = document.createElement('script');
            script.innerHTML = inlineScript;
            script.async = async;
            script.id = `${id}-inline`;
            document.body.appendChild(script);
        }

        // Cleanup script on unmount (optional)
        return () => {
            const existingScript = document.getElementById(id);
            if (existingScript) {
                existingScript.remove();
                console.log(`Script with id "${id}" removed.`);
            }
        };
    }, [src, inlineScript, async, id]);

    return null; // No UI rendering, only script injection
}

TrackingScriptLoader.propTypes = {
    src: PropTypes.string,        // External script URL
    inlineScript: PropTypes.string, // Inline JavaScript to execute
    async: PropTypes.bool,         // Load script asynchronously
    id: PropTypes.string.isRequired, // Unique ID to avoid duplicates
};

export default TrackingScriptLoader;

export function loadScript({src, inlineScript, async = true, id}) {

    // Check if the Facebook Pixel should run based on the environment variable
    const isPixelLive = process.env.REACT_APP_FACEBOOK_PIXEL_LIVE === 'true'

    if (isPixelLive === false) {
        console.log('Facebook Pixel is disabled.');
        return; // Skip loading the script if pixel is disabled
    }

    // Check if the script with the given id already exists
    if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.async = async;
        script.id = id;

        if (src) {
            // Load external script
            script.src = src;
            script.onload = () => console.log(`Script ${src} loaded successfully.`);
            script.onerror = () => console.error(`Error loading script ${src}.`);
        } else if (inlineScript) {
            // Add inline script
            script.innerHTML = inlineScript;
        }

        // Append the script to the body
        document.body.appendChild(script);
    } else {
        console.log(`Script with id "${id}" is already loaded.`);
    }
}

export function removeScript(id) {
    const script = document.getElementById(id);
    if (script) {
        script.remove();
        console.log(`Script with id "${id}" removed.`);
    }
}
