import {ThemeProvider} from "@mui/material/styles";
import theme from "assets/theme/index.js";
import CssBaseline from "@mui/material/CssBaseline";
import {Outlet, useNavigation} from "react-router-dom";
import Loader from "../pages/loader";

const BaseLayout = () => {
    const navigation = useNavigation();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {navigation.state === "loading" ? <Loader/> : <Outlet/>}
        </ThemeProvider>
    );
};
export default BaseLayout
