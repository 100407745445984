import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Modal, Select, TextField} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from "axios";
import {getCsrfTokenFromCookies} from "../../../../util/getCsrfTokenFromCookies";

function YouTrackFeedbackForm() {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        type: 'Bug/Issue/Error',
        summary: '',
        details: ''
    });
    const [errors, setErrors] = useState({
        type: false,
        summary: false,
        details: false
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    useEffect(() => {
        window.openFeedbackForm = () => setIsOpen(true);
        return () => {
            delete window.openFeedbackForm;
        };
    }, []);

    const handleClose = () => {
        setIsOpen(false);
        setFormData({type: 'Bug/Issue/Error', summary: '', details: ''});
        setErrors({type: false, summary: false, details: false});
        setSubmitStatus(null);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
        if (value.trim() !== '') {
            setErrors({...errors, [name]: false});
        }
    };

    const validateForm = () => {
        const newErrors = {
            type: !formData.type,
            summary: !formData.summary.trim(),
            details: !formData.details.trim()
        };
        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }
        setIsSubmitting(true);
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/clients/ticket`, {
                    type: formData.type,
                    summary: formData.summary,
                    details: formData.details
                }, {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );
            if (response.status === 200) {
                setSubmitStatus('success');
            } else {
                throw new Error('Submission failed');
            }
        } catch (error) {
            setSubmitStatus('error');
            console.error('Error submitting feedback:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <MDBox
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: 500,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 4,
                    maxHeight: "80vh",
                    overflowY: "auto",
                }}
            >
                {submitStatus === 'success' ? (
                    <>
                        <MDBox>
                            <MDTypography variant="h6" color="dark" mb={2}>
                                Thank you for submitting your request.
                            </MDTypography>
                            <MDTypography variant="h6" color="info">
                                Even though we don't attend to all requests as we
                                prioritise them according to our development needs. Your feedback helps us improve our
                                app
                                for everyone.
                            </MDTypography>
                        </MDBox>
                        <MDBox mt={3} display="flex" justifyContent="flex-end">
                            <MDButton onClick={handleClose} color="primary">
                                Close
                            </MDButton>
                        </MDBox>
                    </>
                ) : (
                    <>
                        <MDTypography variant="h6" mb={3}>
                            Submit Feedback
                        </MDTypography>

                        {submitStatus === 'error' && (
                            <MDTypography variant="body2" color="error" mb={2}>
                                Error submitting feedback. Please try again later.
                            </MDTypography>
                        )}

                        <FormControl fullWidth sx={{mb: 2}} error={errors.type}>
                            <InputLabel>Type</InputLabel>
                            <Select
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                                label="Type"
                                sx={{height: '56px'}}
                            >
                                <MenuItem value="Bug/Issue/Error">Bug/Issue/Error</MenuItem>
                                <MenuItem value="Feature Request/Suggestion">Feature Request/Suggestion</MenuItem>
                            </Select>
                            {errors.type && (
                                <MDTypography variant="caption" color="error">
                                    Please select a type
                                </MDTypography>
                            )}
                        </FormControl>
                        <TextField
                            fullWidth
                            name="summary"
                            label="Summary"
                            value={formData.summary}
                            onChange={handleChange}
                            margin="normal"
                            error={errors.summary}
                            helperText={errors.summary ? "Summary is required" : ""}
                        />
                        <TextField
                            fullWidth
                            name="details"
                            label="Details"
                            value={formData.details}
                            onChange={handleChange}
                            margin="normal"
                            multiline
                            rows={4}
                            error={errors.details}
                            helperText={errors.details ? "Details are required" : ""}
                        />
                        <MDBox mt={3} display="flex" justifyContent="flex-end">
                            <MDButton onClick={handleClose} color="secondary" sx={{mr: 2}}>
                                Close
                            </MDButton>
                            <MDButton
                                onClick={handleSubmit}
                                color="primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </MDButton>
                        </MDBox>
                    </>
                )}
            </MDBox>
        </Modal>
    );
}

export default YouTrackFeedbackForm;