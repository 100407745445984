import React, {useState} from 'react';
import {Form, useActionData} from 'react-router-dom';
import MDBox from '../../../components/MDBox';
import MDButton from '../../../components/MDButton';
import MDInput from '../../../components/MDInput';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import axios from 'axios';
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";

export const CreateServiceProfile = () => {
    const actionData = useActionData();
    const [clients, setClients] = useState(['']);
    const [mandates, setMandates] = useState(['']);
    const [subordinates, setSubordinates] = useState(['']);

    const addClientField = () => setClients([...clients, '']);
    const addMandateField = () => setMandates([...mandates, '']);
    const addSubordinateField = () => setSubordinates([...subordinates, '']);

    return (
        <MDBox p={3}>
            <h1>Create Service Profile</h1>

            {actionData?.success && (
                <MDBox color="success.main" mb={2}>
                    {actionData.success}
                </MDBox>
            )}
            {actionData?.error && (
                <MDBox color="error.main" mb={2}>
                    {actionData.error}
                </MDBox>
            )}

            <Form method="post" encType="multipart/form-data">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <MDInput
                            fullWidth
                            name="userEmail"
                            label="User Email"
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <MDInput
                            fullWidth
                            name="type"
                            label="Type"
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Clients (Optional)</h3>
                        {clients.map((client, index) => (
                            <MDBox key={index} display="flex" alignItems="center" mb={2}>
                                <MDInput
                                    fullWidth
                                    name="clients[]"
                                    label={`Client Email #${index + 1}`}
                                    value={client}
                                    onChange={(e) => {
                                        const newClients = [...clients];
                                        newClients[index] = e.target.value;
                                        setClients(newClients);
                                    }}
                                />
                                {index === clients.length - 1 && (
                                    <MDButton
                                        variant="text"
                                        color="info"
                                        onClick={addClientField}
                                        sx={{ml: 2}}
                                    >
                                        <Icon>add</Icon>
                                    </MDButton>
                                )}
                            </MDBox>
                        ))}
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Mandates (Optional)</h3>
                        {mandates.map((mandate, index) => (
                            <MDBox key={index} display="flex" alignItems="center" mb={2}>
                                <MDInput
                                    fullWidth
                                    name="mandate[]"
                                    label={`Mandate #${index + 1}`}
                                    value={mandate}
                                    onChange={(e) => {
                                        const newMandates = [...mandates];
                                        newMandates[index] = e.target.value;
                                        setMandates(newMandates);
                                    }}
                                />
                                {index === mandates.length - 1 && (
                                    <MDButton
                                        variant="text"
                                        color="info"
                                        onClick={addMandateField}
                                        sx={{ml: 2}}
                                    >
                                        <Icon>add</Icon>
                                    </MDButton>
                                )}
                            </MDBox>
                        ))}
                    </Grid>

                    <Grid item xs={12}>
                        <h3>Subordinates (Optional)</h3>
                        {subordinates.map((subordinate, index) => (
                            <MDBox key={index} display="flex" alignItems="center" mb={2}>
                                <MDInput
                                    fullWidth
                                    name="subordinates[]"
                                    label={`Subordinate Email #${index + 1}`}
                                    value={subordinate}
                                    onChange={(e) => {
                                        const newSubordinates = [...subordinates];
                                        newSubordinates[index] = e.target.value;
                                        setSubordinates(newSubordinates);
                                    }}
                                />
                                {index === subordinates.length - 1 && (
                                    <MDButton
                                        variant="text"
                                        color="info"
                                        onClick={addSubordinateField}
                                        sx={{ml: 2}}
                                    >
                                        <Icon>add</Icon>
                                    </MDButton>
                                )}
                            </MDBox>
                        ))}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <MDInput
                            fullWidth
                            name="rewardPoints"
                            label="Reward Points"
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <MDInput
                            fullWidth
                            name="affiliation"
                            label="Affiliation"
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <MDInput
                            fullWidth
                            name="credits"
                            label="Credits"
                            type="number"
                        />
                    </Grid>
                </Grid>

                <MDBox mt={3} display="flex" justifyContent="flex-end">
                    <MDButton color="primary" type="submit">
                        Create Profile
                    </MDButton>
                </MDBox>
            </Form>
        </MDBox>
    );
};

export const createServiceProfileAction = async ({request}) => {
    const formData = await request.formData();

    const serviceProfileData = {
        userEmail: formData.get('userEmail'),
        type: formData.get('type'),
        clients: formData.getAll('clients[]').filter(Boolean),
        mandate: formData.getAll('mandate[]').filter(Boolean),
        subordinates: formData.getAll('subordinates[]').filter(Boolean),
        rewardPoints: formData.get('rewardPoints') || 0,
        affiliation: formData.get('affiliation') || '',
        credits: formData.get('credits') || 0,
    };

    try {
        const csrfToken = await getCsrfTokenFromCookies();
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/createServiceProfile`, serviceProfileData, {
            headers: {"x-csrf-token": csrfToken},
            withCredentials: true,
        });

        if (response.status === 201) {
            return {success: 'Service profile created successfully!'};
        } else {
            return {error: 'Failed to create service profile. Please try again.'};
        }
    } catch (error) {
        console.error('Error creating service profile:', error);
        return {error: error.response?.data?.message || 'An error occurred during creation.'};
    }
};