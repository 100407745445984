import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {useLoaderData} from "react-router-dom";
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";
import axios from "axios";
import {formatCurrency} from "../../../util/generalUtils";

export function Assumptions(props) {
    const loaderdata = useLoaderData();
    return (
        <>
            <Card id="assumptions" sx={{overflow: "visible"}}>
                <MDBox p={3}>
                    <MDTypography variant="h5">Assumptions</MDTypography>
                </MDBox>
                <MDBox pb={3} px={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MDBox mb={2}>
                                <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                    Monthly Income
                                </MDTypography>
                                <MDTypography variant="body2" fontWeight="regular" color="text">
                                    {formatCurrency(loaderdata.clientProfile.monthlyIncome)}
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDBox mb={2}>
                                <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                    Date of Birth
                                </MDTypography>
                                <MDTypography variant="body2" fontWeight="regular" color="text">
                                    {new Date(loaderdata.clientProfile.dateOfBirth).toLocaleDateString()}
                                </MDTypography>
                            </MDBox>
                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MDBox mb={2}>
                                <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                    Dependents
                                </MDTypography>
                                <MDTypography variant="body2" fontWeight="regular" color="text">
                                    {loaderdata.clientProfile.dependents}
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDBox mb={2}>
                                <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                    Retirement Age
                                </MDTypography>
                                <MDTypography variant="body2" fontWeight="regular" color="text">
                                    {loaderdata.clientProfile.retirementAge}
                                </MDTypography>
                            </MDBox>
                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MDBox mb={2}>
                                <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                    Tax Free Interest
                                </MDTypography>
                                <MDTypography variant="body2" fontWeight="regular" color="text">
                                    {loaderdata.clientProfile.taxFreeInterest} %
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDBox mb={2}>
                                <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                    Pension Interest
                                </MDTypography>
                                <MDTypography variant="body2" fontWeight="regular" color="text">
                                    {loaderdata.clientProfile.pensionInterest} %
                                </MDTypography>
                            </MDBox>
                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MDBox mb={2}>
                                <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                    Inflation Rate
                                </MDTypography>
                                <MDTypography variant="body2" fontWeight="regular" color="text">
                                    {loaderdata.clientProfile.inflationRate} %
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MDBox mb={2}>
                                <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                                    Life Exepctancy
                                </MDTypography>
                                <MDTypography variant="body2" fontWeight="regular" color="text">
                                    {loaderdata.clientProfile.lifeExpectancy}
                                </MDTypography>
                            </MDBox>
                        </Grid>

                    </Grid>
                </MDBox>
            </Card>
            <Card id="assumptions" sx={{overflow: "visible", marginTop: 3}}>
                <MDBox px={3} py={3}>
                    <MDTypography variant="h4" gutterBottom>
                        Financial Calculations Reference Guide
                    </MDTypography>

                    {/* Section 1: Tax Benefit Calculations */}
                    <MDBox mt={2}>
                        <MDTypography variant="h5" gutterBottom>
                            1. Tax Benefit Calculations
                        </MDTypography>

                        <MDTypography variant="h6" gutterBottom>
                            Retirement Tax Benefits
                        </MDTypography>
                        <MDTypography variant='body2'>
                            <b>Variables:</b> Income, Current Age, Retirement Age, Life Expectancy, Contributions,
                            Interest Rate, Inflation Rate.
                        </MDTypography>
                        <MDTypography variant='body2'>
                            <b>Calculation:</b> The retirement tax benefit is calculated by first determining the
                            current tax paid based on
                            income and age. Then we calculate the tax benefit available (up to 27.5% of taxable income,
                            capped at R350,000 per year). After that, we calculate the tax benefit used based on actual
                            contributions. Finally, we calculate the interest benefit and compounded loss due to
                            taxation.
                        </MDTypography>

                        <MDTypography variant="h6" gutterBottom>
                            Medical Tax Benefits
                        </MDTypography>
                        <MDTypography variant='body2'>
                            <b>Variables:</b> Income, Dependants, Medical Aid Contributions.
                        </MDTypography>
                        <MDTypography variant='body2'>
                            <b>Calculation:</b> The medical tax credit is calculated based on the number of dependants.
                            The yearly medical
                            aid benefit available and used is calculated, along with any remaining benefits.
                        </MDTypography>
                    </MDBox>

                    {/* Section 2: Retirement Calculations */}
                    <MDBox mt={4}>
                        <MDTypography variant="h5" gutterBottom>
                            2. Retirement Calculations
                        </MDTypography>

                        <MDTypography variant="h6" gutterBottom>
                            Retirement Savings Needs
                        </MDTypography>
                        <MDTypography variant='body2'>
                            <b>Variables:</b> Monthly Income Needed, Investment Return, Inflation Rate, Years in
                            Retirement, Years Until Retirement, Existing Provisions, Monthly Contributions, Retirement
                            Provision Percentage.
                        </MDTypography>
                        <MDTypography variant='body2'>
                            <b>Calculation:</b> We begin by calculating the inflation-adjusted return, followed by
                            determining the future
                            monthly income needed at retirement. Next, we calculate the present value of retirement
                            income, the future value of existing provisions, and contributions. Finally, we determine if
                            there is a shortfall and calculate the additional savings needed to cover the shortfall.
                        </MDTypography>
                    </MDBox>

                    {/* Section 3: Life and Disability Cover */}
                    <MDBox mt={4}>
                        <MDTypography variant="h5" gutterBottom>
                            3. Life and Disability Cover (Income Replacement)
                        </MDTypography>

                        <MDTypography variant='body2'>
                            <b>Variables:</b> Income, Provision Percentage, Years, Interest Rate.
                        </MDTypography>
                        <MDTypography variant='body2'>
                            <b>Calculation:</b> The income replacement is calculated based on the provision percentage
                            of the income to be
                            replaced. Using the present value of annuity formula, we determine the capital required
                            today to provide the necessary income replacement for the given number of years.
                        </MDTypography>
                    </MDBox>

                    {/* Formula List Section */}
                    <MDBox mt={4}>
                        <MDTypography variant="h5" gutterBottom>
                            Formula List
                        </MDTypography>

                        <MDTypography variant='body2'>
                            1. <b>Present Value of Annuity:</b><br/> PV = (PMT × (1 - (1 + r)<sup>-n</sup>)) / r
                        </MDTypography>
                        <MDTypography variant='body2'>
                            2. <b>Future Value of Lump Sum and Contributions:</b> <br/> FV = PV × (1 +
                            r)<sup>n</sup> and FV =
                            PMT × ((1 + r)<sup>n</sup> - 1) / r × (1 + r)
                        </MDTypography>
                        <MDTypography variant='body2'>
                            3. <b>Payment Calculation (PMT):</b><br/> PMT = (FV - (PV × (1 + r)<sup>n</sup>)) / (((1 +
                            r)<sup>n</sup> - 1) / r × (1 + r))
                        </MDTypography>
                        <MDTypography variant='body2'>
                            4. <b>Income Replacement (Inflated):</b><br/> PV = (Income × (1 - (1 + r)<sup>-n</sup>)) / r
                        </MDTypography>
                        <MDTypography variant='body2'>
                            5. <b>Retirement Tax Benefit:</b><br/> Tax benefit = Min(0.275 × taxable income, R350,000)
                        </MDTypography>
                        <MDTypography variant='body2'>
                            6. <b>Medical Aid Benefit:</b><br/> Yearly benefit = Benefit per dependant × 12
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </Card>
        </>
    );
}

export const assumptionslLoader = async ({params}) => {
    const csrfToken = await getCsrfTokenFromCookies();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getClientDetails/${params.clientId}`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    return response.data;
}
