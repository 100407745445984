/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images

function Header(props) {

    return (
        <Card id="profile">
            <MDBox p={2}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <MDTypography variant="h5" fontWeight="medium">
                                {props.clientFirstName} {props.clientLastName} ({props.mandateType} Mandate)
                            </MDTypography>
                            <MDTypography variant="h6" color="text" fontWeight="medium">
                                Analysis Done: {props.basicProfileSetup ? "Complete" : "Basic Profile Setup Required"}
                            </MDTypography>
                            {
                                props.closed ?
                                    <MDTypography variant="h6" color="text" fontWeight="medium">
                                        Status : Closed - {props.closedReason}

                                    </MDTypography>
                                    :
                                    <MDTypography variant="h6" color="text" fontWeight="medium">
                                        Status : Open
                                    </MDTypography>
                            }
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default Header;
