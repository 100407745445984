import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import MKBox from "components/MDBox";
import MKTypography from "components/MDTypography";


function DefaultNavbarDropdown({
                                   name = "",
                                   icon,
                                   children = false,
                                   collapseStatus = false,
                                   light = false,
                                   href = "",
                                   route = "",
                                   collapse,
                                   ...rest
                               }) {
    const linkComponent = {component: Link, to: route};
    const externalLinkComponent = {
        component: "a",  // Use <a> for external links
        href,
        target: "_blank",
        rel: "noreferrer",
    };

    return (
        <>
            <MKBox
                {...rest}
                mx={1}
                p={1}
                display="flex"
                alignItems="baseline"
                color={light ? "white" : "dark"}
                opacity={light ? 1 : 0.6}
                sx={{cursor: "pointer", userSelect: "none"}}
                {...(route && linkComponent)}
                {...(href && externalLinkComponent)}
            >
                {icon && (
                    <MKTypography
                        variant="body2"
                        lineHeight={1}
                        color="inherit"
                        sx={{alignSelf: "center", "& *": {verticalAlign: "middle"}}}
                    >
                        {icon}
                    </MKTypography>
                )}
                {name && (
                    <MKTypography
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                        color={light ? "white" : "dark"}
                        sx={{fontWeight: "100%", ml: icon ? 1 : 0, mr: 0.25}}
                    >
                        {name}
                    </MKTypography>
                )}
                {collapse && (
                    <MKTypography variant="body2" color={light ? "white" : "dark"} ml="auto">
                        <Icon sx={{fontWeight: "normal", verticalAlign: "middle"}}>
                            keyboard_arrow_down
                        </Icon>
                    </MKTypography>
                )}
            </MKBox>
            {children && (
                <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
}

DefaultNavbarDropdown.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.node,
    children: PropTypes.node,
    collapseStatus: PropTypes.bool,
    light: PropTypes.bool,
    href: PropTypes.string,
    route: PropTypes.string,
    collapse: PropTypes.bool.isRequired,
};

export default DefaultNavbarDropdown;
