// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AddActivity from "../addActivity";

function LogActivity() {
    return (
        <Card id="delete-account">
            <MDBox
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems={{xs: "flex-start", sm: "center"}}
                flexDirection={{xs: "column", sm: "row"}}
            >
                <MDBox p={3} lineHeight={1}>
                    <MDBox mb={1}>
                        <MDTypography variant="h5">Log Activity</MDTypography>
                    </MDBox>
                    <MDTypography variant="button" color="text">
                        Log an activity or action completed with this client. Feedback is used to optimise the system
                        for better quality mandates and conversions using AI.
                    </MDTypography>
                </MDBox>
                <MDBox display="flex" flexDirection={{xs: "column", sm: "row"}}>
                    <MDBox ml={{xs: 0, sm: 1}} mt={{xs: 1, sm: 0}}>
                        <AddActivity/>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default LogActivity;
