import {useLoaderData} from "react-router-dom";
import BasicLayout from "./components/BasicLayout";
import bgImage from "../../assets/images/back9.png";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import axios from "axios";

export const VerifyEmail = () => {
    const {verified} = useLoaderData();
    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >

                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        Email Verification
                    </MDTypography>
                </MDBox>
                {verified ? (
                    <MDBox pt={3} pb={3} px={3}>
                        <MDTypography display="block" variant="h5" color="text" my={1}>
                            Your email has been verified. You can now login to your account.
                        </MDTypography>
                    </MDBox>
                ) : (
                    <MDBox pt={3} pb={3} px={3}>
                        <MDTypography display="block" variant="h5" color="text" my={1}>
                            Invalid email verification link. Ensure you click on the link that was e-mailed to you.
                        </MDTypography>
                    </MDBox>
                )}
            </Card>

        </BasicLayout>
    );
};


export const verifyEmailLoader = async ({request}) => {
    const url = new URL(request.url);
    const email = url.searchParams.get('email');
    const token = url.searchParams.get('token');
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/auth/verifyemail`,
            {
                email,
                token
            }
        );

        if (response.status === 200) {
            return {verified: true}; // Redirect on successful reset
        }
    } catch (error) {
        console.error('Error during email verification:', error);
        return {verified: false};
    }
}