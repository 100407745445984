import React, {useEffect, useState} from 'react';
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import InputLabel from "@mui/material/InputLabel";
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {getCsrfTokenFromCookies} from "../../../../util/getCsrfTokenFromCookies";
import {useRevalidator} from "react-router-dom";
import axios from "axios";
import {useMaterialUIController} from "context";
import AssignmentHolidays from "./AssignmentHoliday";

export const ManageSubscription = (props) => {
    const {revalidate} = useRevalidator();
    const [controller] = useMaterialUIController();
    const {darkMode} = controller;
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);
    const [showUpdateCard, setShowUpdateCard] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    const handleShowUpdateCard = async () => {
        setLoading(true);
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const setupResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/system/updateCard`,
                {},
                {
                    withCredentials: true,
                    headers: {"x-csrf-token": csrfToken},
                }
            );

            elements.update({clientSecret: setupResponse.data.clientSecret});
            setClientSecret(setupResponse.data.clientSecret);
            setShowUpdateCard(true);
            setLoading(false);
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to initialise card update');
        }
    };

    useEffect(() => {
        if (clientSecret) {
            elements.update({
                clientSecret: clientSecret
            });
        }
    }, [clientSecret, elements]);

    const handleUpdateCard = async (event) => {
        event.preventDefault();
        if (!stripe || !elements || !clientSecret) return;

        setLoading(true);
        setError(null);

        try {
            const {error: submitError} = await elements.submit();
            if (submitError) {
                setError(submitError.message);
                return;
            }

            const {error: setupError} = await stripe.confirmSetup({
                elements,
                clientSecret,
                redirect: 'if_required',
            });

            if (setupError) {
                setError(setupError.message);
                return;
            }

            const csrfToken = await getCsrfTokenFromCookies();
            await axios.post(
                `${process.env.REACT_APP_API_URL}/system/finaliseCardUpdate`,
                {setupIntentId: clientSecret.split('_secret')[0]}, // Get setupIntentId from clientSecret
                {
                    withCredentials: true,
                    headers: {"x-csrf-token": csrfToken},
                }
            );

            setShowUpdateCard(false);
            await revalidate();

        } catch (err) {
            setError(err.response?.data?.error || 'Failed to update card');
        } finally {
            setLoading(false);
        }
    };

    const handleCancelSubscription = async () => {
        setLoading(true);
        setError(null);
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/system/cancelSubscription`,
                {},
                {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );

            if (response.data.success) {
                setShowCancelConfirm(false);
                revalidate();
            }
        } catch (err) {
            setError(err.response?.data?.error || 'Failed to cancel subscription');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card sx={{marginBottom: 3}}>
            <MDBox p={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AssignmentHolidays/>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h6" fontWeight="medium">
                            Current Credit Balance: {props.creditSum} credits
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h4" fontWeight="medium" mb={2}>
                            Active Subscription
                        </MDTypography>
                        <MDBox>
                            <MDTypography variant="h6" mb={1}>
                                Plan: {props.plan}
                            </MDTypography>
                            <MDTypography variant="body2" color="text" mb={2}>
                                Status: {props.active ? 'Active' : 'Inactive'}
                            </MDTypography>
                            <MDTypography variant="body2" color="text" mb={2}>
                                Next billing date: {new Date(props.nextBillingDate).toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit'
                            })}
                            </MDTypography>

                            {showCancelConfirm ? (
                                <MDBox>
                                    <MDTypography variant="body2" color="error" mb={2}>
                                        Are you sure you want to cancel your subscription?
                                    </MDTypography>
                                    <MDBox display="flex" gap={2}>
                                        <MDButton
                                            variant="contained"
                                            color="error"
                                            onClick={handleCancelSubscription}
                                            disabled={loading}
                                        >
                                            Yes, Cancel
                                        </MDButton>
                                        <MDButton
                                            variant="outlined"
                                            color="info"
                                            onClick={() => setShowCancelConfirm(false)}
                                            disabled={loading}
                                        >
                                            No, Keep
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            ) : (
                                <MDButton
                                    variant="outlined"
                                    color="error"
                                    onClick={() => setShowCancelConfirm(true)}
                                    startIcon={<Icon>cancel</Icon>}
                                >
                                    Cancel Subscription
                                </MDButton>
                            )}
                        </MDBox>
                    </Grid>

                    <Grid item xs={12}>
                        <MDTypography variant="h4" fontWeight="medium" mb={2}>
                            Payment Method
                        </MDTypography>
                        {!showUpdateCard ? (
                            <MDBox>
                                <MDTypography variant="body2" mb={1}>
                                    VISA ending in {props.cardLast4}
                                </MDTypography>
                                <MDTypography variant="body2" mb={2}>
                                    Expires: {props.cardExpiry}
                                </MDTypography>
                                <MDButton
                                    variant="outlined"
                                    color="info"
                                    onClick={handleShowUpdateCard}
                                    startIcon={!loading && <Icon>credit_card</Icon>}
                                    disabled={loading}
                                >
                                    {loading ? 'Loading...' : 'Update Card'}
                                </MDButton>
                            </MDBox>
                        ) : (
                            <MDBox>
                                <form onSubmit={handleUpdateCard}>
                                    <InputLabel
                                        sx={{
                                            mb: 1,
                                            color: darkMode ? "white" : "text.primary"
                                        }}
                                    >
                                        New Card Details
                                    </InputLabel>
                                    <MDBox
                                        sx={{
                                            p: 2,
                                            border: "1px solid",
                                            borderColor: darkMode ? "grey.600" : "grey.300",
                                            borderRadius: 1,
                                            backgroundColor: darkMode ? "transparent" : "white",
                                        }}
                                    >
                                        {clientSecret && (
                                            <PaymentElement
                                                options={{
                                                    paymentMethodOnly: true,
                                                    layout: {type: 'tabs', defaultCollapsed: false},
                                                    appearance: {
                                                        theme: darkMode ? 'night' : 'stripe',
                                                        variables: {
                                                            colorPrimary: '#1A73E8',
                                                            colorBackground: darkMode ? '#1a2035' : '#ffffff',
                                                            colorText: darkMode ? '#ffffff' : '#424770',
                                                            colorDanger: '#9e2146',
                                                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                                            spacingUnit: '4px',
                                                            borderRadius: '4px',
                                                        },
                                                    }
                                                }}
                                            />
                                        )}
                                        <MDBox mt={2} display="flex" justifyContent="flex-end">
                                            <img
                                                src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg"
                                                alt="Powered by Stripe"
                                                width="100"
                                            />
                                        </MDBox>
                                    </MDBox>

                                    <MDBox mt={4} display="flex" gap={2}>
                                        <MDButton
                                            type="submit"
                                            variant="gradient"
                                            color="info"
                                            size="large"
                                            disabled={loading || !clientSecret}
                                        >
                                            {loading ? 'Updating...' : 'Update Card'}
                                        </MDButton>
                                        <MDButton
                                            variant="outlined"
                                            color="error"
                                            size="large"
                                            onClick={() => setShowUpdateCard(false)}
                                            disabled={loading}
                                        >
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                </form>
                            </MDBox>
                        )}
                    </Grid>

                    {error && (
                        <Grid item xs={12}>
                            <MDBox mt={2}>
                                <MDTypography variant="caption" color="error" fontWeight="light">
                                    {error}
                                </MDTypography>
                            </MDBox>
                        </Grid>
                    )}
                </Grid>
            </MDBox>
        </Card>
    );
};

export default ManageSubscription;