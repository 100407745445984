import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MDBox";
import MDBox from "components/MDBox";
import MKTypography from "components/MDTypography";
import MKButton from "components/MDButton";

import {useEffect, useRef} from "react";
import Typed from "typed.js";

// Material Kit 2 PRO React examples
import DefaultBackgroundCard from "./DefaultBackgroundCard";
import ListItem from "./ListItem";

import bgImage1 from "assets/images/ExcitedAppBack.png";
import bgImage2 from "assets/images/ExcitedAppback2.png";

// Routes
// Images
import bgImage from "assets/images/back9.png";
import Signup from "./Signup";
import TrackingScriptLoader from "../../../util/TrackingScriptLoader";

function HomeContent() {
    const typedJSRef = useRef(null);
    useEffect(() => {
        const typedJS = new Typed(typedJSRef.current, {
            strings: ["reduce taxes", "improve your finances", "get peace of mind"],
            typeSpeed: 50,
            backSpeed: 60,
            backDelay: 200,
            startDelay: 500,
            loop: true,
        });

        return () => typedJS.destroy();
    }, []);

    const handleScrollToSignup = () => {
        const signupElement = document.getElementById("signup-section");
        if (signupElement) {
            const offset = 100; // Adjust this value to fine-tune the scroll position
            const elementPosition = signupElement.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    };

    return (
        <>
            <TrackingScriptLoader
                id="facebook-tracking"
                inlineScript={`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '2243764045999242');
fbq('track', 'PageView');
        `}
            />
            <MKBox
                minHeight="70vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" mt={9}>
                        <MKTypography
                            variant="h2"
                            color="white"
                            sx={({breakpoints, typography: {size}}) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                                textAlign: "center",
                            })}
                        >
                            The <MKTypography component="span" variant="h2" color="dark"
                                              display="inline">free</MKTypography> app that
                            helps you <br/> <span ref={typedJSRef}/>
                        </MKTypography>
                        <MDBox textAlign="center">
                            <MKTypography color="dark" variant='h6' mb={1}>
                                Transform your finances with our innovative app, built on over a decade of expertise and
                                research. Maximise tax benefits, secure your retirement, protect against uncertainties,
                                create bespoke wills, and boost your credit rating – all in one intuitive platform.
                            </MKTypography>
                            <MKTypography color="white" variant='h6'>
                                Scroll down for more information
                            </MKTypography>
                        </MDBox>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox component="section" py={12}>
                <Container>
                    <Grid
                        container
                        item
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        xs={10}
                        lg={5}
                        sx={{mx: "auto", textAlign: "center"}}
                    >
                        <MKTypography variant="h3" mt={1} color='info'>
                            How Our App Is Helping You
                        </MKTypography>
                        <MKTypography variant="body2" color="text">
                            Unlock Your Financial Power! Our app helps you maximise tax benefits, protect against life's
                            surprises, and plan a dream retirement. Get expert analysis, tailored planning, and
                            financial clarity - all in one! And it is 100% free.
                        </MKTypography>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" sx={{mt: 6}}>
                        <Grid item xs={12} md={4} sx={{ml: "auto"}}>
                            <DefaultBackgroundCard
                                image={bgImage1}
                                label="Technology"
                                title="Uses the latest technology"
                                description="Next-gen tech meets financial clarity! Our app harnesses the latest tech analysis to break down your entire financial portfolio, making it ridiculously easy to understand and optimise"
                                action={{
                                    type: "scroll",
                                    label: "Get Started",
                                    action: handleScrollToSignup,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={5} sx={{mr: "auto", ml: {xs: 0, md: 6}}}>
                            <ListItem title="Tax optimisation">
                                Sit back, relax! Our innovative app isn't for filing tax returns, but for
                                future-proofing your finances. It analyses and optimises your tax strategy, identifying
                                maximum savings opportunities to minimise your monthly tax bill, going forward.
                            </ListItem>
                            <ListItem title="Financial Analysis">
                                Ditch the financial headache! Our app takes the complexity out of planning for life's
                                unknowns and retirement, making light work of this crucial task. You'll get a
                                crystal-clear picture of your financial future, highlighting exactly where to focus.
                            </ListItem>
                            <ListItem title="Last Will and Testament (Coming Soon!)">
                                Worry less about the future. Our upcoming feature makes creating a Last Will and
                                Testament a breeze, ensuring your loved ones are protected and cared for, even when
                                you're no longer here. Say goodbye to uncertainty and give them the gift of peace of
                                mind.
                            </ListItem>
                            <ListItem title="Improve your credit (Coming Soon!)">
                                Take charge of your financial future! Our upcoming feature puts you in the driver's seat
                                of your credit rating, providing expert tools to boost your score over time. Unlock
                                exclusive deals on credit cards, home loans, and more - and start saving big!
                            </ListItem>
                        </Grid>
                    </Grid>
                    <Divider sx={{my: {xs: 2, sm: 8}, mx: 12}}/>
                    <Container sx={{
                        backgroundImage: `url(${bgImage}) !important`,
                        padding: 4
                    }}>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={8} lg={5}>
                                <MKTypography variant="h5" color="dark" fontWeight="bold">
                                    Get started with our free app now!
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12} lg={6} sx={{ml: "auto"}}>
                                <MKBox width="12rem" ml="auto">
                                    <MKButton
                                        variant="gradient"
                                        color="primary"
                                        fullWidth
                                        sx={{boxShadow: "none"}}
                                        onClick={handleScrollToSignup}
                                    >
                                        start now
                                    </MKButton>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Container>
                    <Divider sx={{my: {xs: 2, sm: 8}, mx: 12}}/>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} md={5} sx={{ml: "auto"}}>
                            <ListItem title="Access to experts">
                                Unlock expert financial guidance! Our app connects you with handpicked financial
                                specialists who'll help you make informed decisions and reach your financial
                                aspirations. From tax savings to investments, our experts have got you covered.
                            </ListItem>
                            <ListItem title="Safe and Secure">
                                Your security is our top priority! We've built our app using advanced security
                                protocols, industry-best practices, and rigorous international standards to provide a
                                safe and secure space for your financial data, ensuring your information is safeguarded
                                with the highest level of protection.
                            </ListItem>
                            <ListItem title="Use it everywhere">
                                Manage your finances from anywhere, on any device! With our web app available now and
                                mobile apps for Android and iOS launching soon, all you need is an internet connection
                                to take control of your financial future.
                            </ListItem>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{mr: "auto", ml: {xs: 0, md: 6}}}>
                            <DefaultBackgroundCard
                                image={bgImage2}
                                label="All in one place"
                                title="The ultimate one-stop-shop"
                                description="Our app is designed to be your one-stop-shop for total financial control, where you can manage your money with confidence, knowing you're safe, secure, and supported every step of the way"
                                action={{
                                    type: "scroll",
                                    label: "get started",
                                    action: handleScrollToSignup,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox id="signup-section" component="section" py={5} bgColor="white">
                <Container>
                    <Grid
                        container
                        item
                        justifyContent="center"
                        xs={10}
                        lg={7}
                        mx="auto"
                        mb={{xs: 0, md: 6}}
                        textAlign="center"
                    >
                        <MKTypography variant="h3" mb={1} color='info'>
                            Get Started Now For Free
                        </MKTypography>
                        <MKTypography variant="body2" color="text">
                            Getting started is as simple as creating your account with the form below
                        </MKTypography>
                    </Grid>
                </Container>
                <Signup/>
            </MKBox>
        </>
    );
}

export default HomeContent;