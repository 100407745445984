import React, {useEffect, useRef, useState} from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import axios from 'axios';
import {getCsrfTokenFromCookies} from 'util/getCsrfTokenFromCookies';


const MongoDBDashboard = ({
                              dashboardId,
                              baseUrl,
                              height = "800px",
                              width = "100%",
                              theme = "light",
                              onRendered,
                              className
                          }) => {
    const dashboardDiv = useRef(null);
    const dashboardInstance = useRef(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getJWTAndCreateFilters = async () => {
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/chartToken`, {
                withCredentials: true,
                headers: {
                    "x-csrf-token": csrfToken,
                    "Accept": "application/json",
                },
            });

            if (response.data.error) {
                throw new Error(response.data.error);
            }

            return {
                token: response.data.token,
                userId: response.data.userId,
            };
        } catch (err) {
            console.error("Failed to get dashboard token:", err);
            throw new Error(err.response?.data?.error || err.message || "Failed to get dashboard access token");
        }
    };

    useEffect(() => {
        let isMounted = true;

        const loadDashboard = async () => {
            try {
                if (!isMounted) return;
                setIsLoading(true);
                setError(null);

                if (!dashboardId) throw new Error("Dashboard ID is required");
                if (!baseUrl) throw new Error("Base URL is required");

                const {token, userId} = await getJWTAndCreateFilters();

                const startDate = new Date();
                startDate.setMonth(startDate.getMonth() - 1);
                startDate.setHours(0, 0, 0, 0);

                const endDate = new Date();
                endDate.setHours(23, 59, 59, 999);

                const dashboardFilter = {
                    advisorSuperior: {$in: [{$oid: `${userId}`}]},
                    createdAt: {
                        $gte: startDate,
                        $lte: endDate
                    }

                };

                const sdk = new ChartsEmbedSDK({
                    baseUrl: baseUrl,
                    getUserToken: async () => {
                        // Get fresh token each time
                        const {token} = await getJWTAndCreateFilters();
                        return token;
                    }
                });

                const dashboard = sdk.createDashboard({
                    dashboardId: dashboardId,
                    height: height,
                    width: width,
                    theme: theme,
                    background: "transparent",
                    showAttribution: false,
                    filter: dashboardFilter,
                    maxDataAge: 300,
                    refresh: true,
                    autoRefresh: true,
                    refreshInterval: 300,

                });

                await dashboard.render(dashboardDiv.current);

                if (!isMounted) return;
                dashboardInstance.current = dashboard;
                setIsLoading(false);

                if (onRendered) {
                    onRendered(dashboard);
                }

            } catch (err) {
                console.error("Error rendering MongoDB dashboard:", err);
                if (isMounted) {
                    setError(err.message || "Failed to load dashboard");
                    setIsLoading(false);
                }
            }
        };

        loadDashboard();

        return () => {
            isMounted = false;
            if (dashboardInstance.current && typeof dashboardInstance.current.cleanup === 'function') {
                dashboardInstance.current.cleanup();
            }
            dashboardInstance.current = null;
        };
    }, [dashboardId, baseUrl, height, width, theme, onRendered]);

    if (error) {
        return (
            <MDBox p={2}>
                <MDTypography variant="caption" color="error">
                    {error}
                </MDTypography>
            </MDBox>
        );
    }

    return (
        <MDBox position="relative" minHeight={height} className={className}>
            {isLoading && (
                <MDBox
                    position="absolute"
                    inset={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(255,255,255,0.8)"
                    zIndex={1}
                >
                    <CircularProgress color="info"/>
                </MDBox>
            )}
            <div
                ref={dashboardDiv}
                style={{height, width}}
                className="w-full h-full"
            />
        </MDBox>
    );
};

export default MongoDBDashboard;