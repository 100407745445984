import MKBox from "components/MDBox";
import {Footer} from "./components/Footer";
import DefaultNavbar from "./components/DefaultNavbar";
import routes from "./routes";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

function Home() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleScrollToSignup = () => {
        if (location.pathname === "/") {
            const signupElement = document.getElementById("signup-section");
            if (signupElement) {
                const offset = 100; // Adjust this value to fine-tune the scroll position
                const elementPosition = signupElement.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        } else {
            navigate("/");
        }
    };

    return (
        <>
            <DefaultNavbar
                routes={routes}
                actions={[
                    {
                        type: "scroll",
                        label: "Signup",
                        color: "info",
                        action: handleScrollToSignup,
                    },
                    {
                        type: "external",
                        route: "/login",
                        label: "Login",
                        color: "dark",
                    },
                ]}
                sticky
            />

            <Outlet/>
            <MKBox>
                <Footer/>
            </MKBox>
        </>
    );
}

export default Home;