import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDBadge from "../../../components/MDBadge";
import DataTable from "../../../components/DataTable";
import DefaultCell from "../../../components/DataTable/components/DefaultCell";
import {Link, useLoaderData} from "react-router-dom";
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";
import axios from "axios";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useEffect, useState} from "react";

export const ClientList = () => {
    const loaderData = useLoaderData();
    const [expanded, setExpanded] = useState('todo');


    const [counts, setCounts] = useState({
        todo: 0,
        upcoming: 0,
        closed: 0
    });

    useEffect(() => {
        setCounts({
            todo: filterClients(loaderData.clients, 'todo').length,
            upcoming: filterClients(loaderData.clients, 'upcoming').length,
            closed: filterClients(loaderData.clients, 'closed').length
        });
    }, [loaderData.clients]);


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const normalizeDate = (date) => {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d;
    };

    const filterClients = (clients, type) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        switch (type) {
            case 'todo':
                return clients.filter(client =>
                    normalizeDate(client.followupDate) <= today && !client.closed
                );
            case 'upcoming':
                return clients.filter(client =>
                    normalizeDate(client.followupDate) > today && !client.closed
                );
            case 'closed':
                return clients.filter(client => client.closed);
            default:
                return [];
        }
    };

    const createUserRows = (clients) => {

        return clients.map((client) => ({
            systemId: (
                <Link to={`client-details/${client.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
                    <DefaultCell>{client.id}</DefaultCell>
                </Link>
            ),
            firstName: (
                <Link to={`client-details/${client.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
                    <DefaultCell>{client.firstName}</DefaultCell>
                </Link>
            ),
            lastName: (
                <Link to={`client-details/${client.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
                    <DefaultCell>{client.lastName}</DefaultCell>
                </Link>
            ),
            followupDate: (
                <DefaultCell>{new Date(client.followupDate).toLocaleDateString()}</DefaultCell>
            ),
            closedDate: (
                <DefaultCell>{new Date(client.closedDate).toLocaleDateString()}</DefaultCell>
            )
        }));
    };

    return (
        <Card sx={{width: "100%", marginTop: 1, marginBottom: 1}}>
            <MDBox pt={2} pb={2}>
                <MDBox display="flex" flexDirection="column" mt={3} pt={3} px={3}>
                    <MDTypography variant="h5" fontWeight="medium">
                        My Clients
                    </MDTypography>
                    <MDBox mt={3}>

                        <Accordion defaultExpanded expanded={expanded === 'todo'}
                                   onChange={handleAccordionChange('todo')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <MDTypography variant="h6">To-Do</MDTypography>
                                <MDBadge variant="contained" color="info" badgeContent={counts.todo} container
                                         sx={{ml: 2}}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DataTable
                                    table={{
                                        columns: [
                                            {Header: "System Id", accessor: "systemId"},
                                            {Header: "First Name", accessor: "firstName"},
                                            {Header: "Last Name", accessor: "lastName"},
                                            {Header: "Follow-up Date", accessor: "followupDate"},
                                        ],
                                        rows: createUserRows(filterClients(loaderData.clients, 'todo')),
                                    }}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    isSorted={false}
                                    noEndBorder
                                />

                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'upcoming'} onChange={handleAccordionChange('upcoming')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <MDTypography variant="h6">Coming Up</MDTypography>
                                <MDBadge variant="contained" color="info" badgeContent={counts.upcoming} container
                                         sx={{ml: 2}}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DataTable
                                    table={{
                                        columns: [
                                            {Header: "System Id", accessor: "systemId"},
                                            {Header: "First Name", accessor: "firstName"},
                                            {Header: "Last Name", accessor: "lastName"},
                                            {Header: "Follow-up Date", accessor: "followupDate"},
                                        ],
                                        rows: createUserRows(filterClients(loaderData.clients, 'upcoming')),
                                    }}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    isSorted={false}
                                    noEndBorder
                                />

                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'closed'} onChange={handleAccordionChange('closed')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <MDTypography variant="h6">Completed</MDTypography>
                                <MDBadge variant="contained" color="info" badgeContent={counts.closed} container
                                         sx={{ml: 2}}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <DataTable
                                    table={{
                                        columns: [
                                            {Header: "System Id", accessor: "systemId"},
                                            {Header: "First Name", accessor: "firstName"},
                                            {Header: "Last Name", accessor: "lastName"},
                                            {Header: "Closed Date", accessor: "closedDate"},
                                        ],
                                        rows: createUserRows(filterClients(loaderData.clients, 'closed')),
                                    }}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    isSorted={false}
                                    noEndBorder
                                />

                            </AccordionDetails>
                        </Accordion>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
};


export const clientsLoader = async () => {
    const csrfToken = await getCsrfTokenFromCookies();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getClientData`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    return response.data;
}