import axios from 'axios';

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.data?.authRedirect) {
            window.location.replace(error.response.data.authRedirect);
            return new Promise(() => {
            });
        }
        return Promise.reject(error);
    }
);


