import React from "react";
import PropTypes from "prop-types";
import MKBox from "components/MDBox";
import MKTypography from "components/MDTypography";
import MKButton from "components/MDButton";
import {Link, useNavigate} from "react-router-dom";
import MuiLink from "@mui/material/Link";
import Icon from "@mui/material/Icon";

function DefaultNavbarMobile({routes, open, actions, handleClose}) {
    const navigate = useNavigate()
    const handleNavigation = (route) => {
        handleClose();  // Close the menu first
        if (route) navigate(route);  // Trigger React Router navigation and loader
    };
    return (
        <MKBox
            position="absolute"
            top="100%"
            left={0}
            width="100%"
            zIndex={20}
            bgcolor="#ffffff"
            display={open ? "flex" : "none"}
            flexDirection="column"
            py={2}
            px={3}
            sx={{
                overflowY: "auto",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "16px 16px 16px 16px",
                maxHeight: "calc(100vh - 64px)",
                backgroundColor: "#ffffff",
            }}
        >
            {routes.map(({name, icon, href, route}, index) => (
                <MKBox key={`nav-item-${name || index}`} display="flex" alignItems="center" py={1} px={1}>
                    <MKTypography

                        to={route ? route : ""}
                        href={href ? href : ""}
                        variant="button"
                        color="text"
                        textTransform="capitalize"
                        onClick={(e) => {
                            if (route) {
                                e.preventDefault();  // Prevent page reload
                                handleNavigation(route);  // Use navigate to trigger loader
                            } else {
                                handleClose();  // Handle external links or non-route cases
                            }
                        }}
                        sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "#000",
                        }}
                    >
                        {icon && <Icon sx={{verticalAlign: "middle", mr: 1}}>{icon}</Icon>}
                        {name}
                    </MKTypography>
                </MKBox>
            ))}

            {actions && actions.length > 0 && (
                <MKBox mt={2}>
                    {actions.map((action, index) => (
                        <MKBox key={`action-${action.label || index}`} py={1} px={1}>
                            <MKButton
                                component={action.type === "internal" ? Link : MuiLink}
                                to={action.type === "internal" ? action.route : ""}
                                href={action.type === "external" ? action.route : undefined}
                                variant="gradient"
                                color={action.color || "info"}
                                size="medium"
                                fullWidth
                                onClick={() => {
                                    if (action.type === "scroll" && action.action) {
                                        handleClose();
                                        setTimeout(() => {
                                            action.action();
                                        }, 300);
                                    } else {
                                        handleClose();
                                    }
                                }}
                                sx={{textTransform: "capitalize", boxShadow: "none"}}
                            >
                                {action.label}
                            </MKButton>
                        </MKBox>
                    ))}
                </MKBox>
            )}
        </MKBox>
    );
}

DefaultNavbarMobile.propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    open: PropTypes.bool.isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(["external", "internal", "scroll"]).isRequired,
            route: PropTypes.string,
            color: PropTypes.string,
            label: PropTypes.string.isRequired,
            action: PropTypes.func,
        })
    ),
    handleClose: PropTypes.func.isRequired,
};

export default DefaultNavbarMobile;
