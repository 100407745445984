/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useMemo} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {Doughnut} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";

// @mui material components
import MDBox from "../../../../../../components/MDBox";

// DefaultDoughnutChart configurations
import configs from "./configs";

ChartJS.register(ArcElement, Tooltip, Legend);

function DefaultDoughnutChart({height = "19.125rem", chart}) {
    const {data, options} = configs(chart.labels || [], chart.datasets || {}, chart.cutout);

    const renderChart = useMemo(
        () => (
            <MDBox height={height}>
                <Doughnut data={data} options={options} redraw/>
            </MDBox>
        ),
        [chart, height]
    );

    return renderChart;
}


// Typechecking props for the DefaultDoughnutChart
DefaultDoughnutChart.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default DefaultDoughnutChart;