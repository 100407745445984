import {Box, IconButton, Modal, Tooltip, Typography} from '@mui/material';
import {Form, useRevalidator} from 'react-router-dom';
import {useState} from 'react';
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import axios from 'axios';
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";
import TrackingScriptLoader, {loadScript} from "../../../util/TrackingScriptLoader";

const SetupBasicProfile = ({open, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [monthlyIncome, setMonthlyIncome] = useState("");
    const [dependents, setDependents] = useState("");
    const [errors, setErrors] = useState({});
    const {revalidate} = useRevalidator();

    // States for individual tooltips
    const [tooltipOpenIncome, setTooltipOpenIncome] = useState(false);
    const [tooltipOpenDependents, setTooltipOpenDependents] = useState(false);

    const validateInputs = () => {
        const newErrors = {};
        if (!dateOfBirth || isNaN(new Date(dateOfBirth).getTime())) {
            newErrors.dateOfBirth = "Valid date of birth is required. Use the format as specified above";
        }
        if (!monthlyIncome || isNaN(monthlyIncome) || monthlyIncome <= 0) {
            newErrors.monthlyIncome = "Valid monthly income is required and cannot be empty. Income can only be a number and must be more than zero";
        }
        if (!dependents || isNaN(dependents) || dependents < 0) {
            newErrors.dependents = "Valid number of dependents is required. Dependents can be a";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleUpdate = async () => {
        if (!validateInputs()) return;
        setLoading(true);
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            await axios.post(`${process.env.REACT_APP_API_URL}/clients/setProfile`, {
                    dob: dateOfBirth,
                    income: monthlyIncome,
                    dependents: dependents,
                }, {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );
            if (monthlyIncome >= 20000) {
                loadScript({
                    id: 'google-profile-event',
                    inlineScript: "gtag('event', 'conversion', {'send_to': 'AW-16757766302/fyDaCLu9luMZEJ7x3LY-'});"
                });
            }
            setLoading(false);
            onClose();
            revalidate();
        } catch (error) {
            console.error("Error updating profile:", error);
            setLoading(false);
        }
    };

    // Tooltip toggle functions for Income
    const handleTooltipToggleIncome = () => {
        setTooltipOpenIncome(!tooltipOpenIncome);
    };
    const handleTooltipCloseIncome = () => {
        setTooltipOpenIncome(false);
    };

    // Tooltip toggle functions for Dependents
    const handleTooltipToggleDependents = () => {
        setTooltipOpenDependents(!tooltipOpenDependents);
    };
    const handleTooltipCloseDependents = () => {
        setTooltipOpenDependents(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',  // Set a percentage width for smaller screens
        maxWidth: 400,  // Ensure it doesn’t grow too large on larger screens
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',  // Prevent overflow out of viewport
        overflowY: 'auto',   // Enable vertical scrolling if content overflows
    };

    return (
        <Modal
            open={open}
            onClose={() => {
            }}
            aria-labelledby="setup-basic-profile-title"
            aria-describedby="setup-basic-profile-description"
            closeAfterTransition={false}
        >
            <>
                <TrackingScriptLoader
                    id="facebook-tracking"
                    inlineScript={`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '2243764045999242');
fbq('track', 'PageView');
        `}
                />
                <Box sx={modalStyle}>
                    <MDBox mb={1}>
                        <MDTypography variant='h4'>Quick Profile Setup</MDTypography>
                    </MDBox>
                    <MDBox mb={2}>
                        <MDTypography variant='h6'>
                            Let's setup your profile! It takes less than 10 seconds! This is essential for our app to do
                            an
                            analysis for you, so you can start enjoying tax savings and an optimised financial
                            portfolio.
                        </MDTypography>
                    </MDBox>
                    <Form>
                        {/* Date of Birth Field */}
                        <MDTypography variant="h6" fontWeight="medium" color="dark">
                            What is your date of birth?
                        </MDTypography>
                        <MDBox
                            mb={2}
                            display="flex"
                            alignItems="center"
                            sx={{
                                width: '100%',     // Ensure full width
                                maxWidth: '400px',  // Optional: Limit on larger screens
                            }}
                        >
                            <MDDatePicker
                                input={{placeholder: "Select a date"}}
                                value={dateOfBirth}
                                onChange={(date) => setDateOfBirth(date)}
                                sx={{
                                    width: '100%', // Force the DatePicker to fill its container
                                    '& input': {
                                        display: 'block',            // Ensure it's block-level
                                        width: '100% !important',    // Force full width
                                        minWidth: '200px !important', // Prevent shrinking
                                        padding: '10px',             // Padding for better UX
                                        fontSize: '16px',            // Prevent Safari zoom
                                        boxSizing: 'border-box',     // Ensure padding fits inside the box
                                    },
                                }}
                            />
                        </MDBox>


                        {/* Monthly Income Field */}
                        <MDTypography variant="h6" fontWeight="medium" color="dark">
                            What is your monthly gross/total income?
                        </MDTypography>
                        <MDBox mb={2} display="flex" alignItems="center">
                            <MDInput
                                type="number"
                                value={monthlyIncome}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) setMonthlyIncome(value);  // Allow only digits, no spaces
                                }}  // Update income state
                                inputProps={{placeholder: "Monthly Income"}}
                            />
                            <Tooltip
                                title="Time to crunch some numbers! Please share your taxable income (or your gross monthly income - the big number on your payslip, before deductions). Don't sweat the exact amount, a good estimate is cool, and you can tweak later!"
                                arrow
                                open={tooltipOpenIncome}  // Tooltip open state for Income
                                onClose={handleTooltipCloseIncome}  // Close tooltip
                                disableHoverListener={false}  // Hover on desktop is enabled
                                disableTouchListener={false}  // Tooltip is interactive on mobile
                                enterTouchDelay={0}  // Immediate on mobile touch
                                leaveTouchDelay={999999}  // Prevents auto-close on mobile, waits for explicit action
                            >
                                <Box display="flex" alignItems="center" sx={{cursor: 'pointer', ml: 1}}
                                     onClick={handleTooltipToggleIncome}  // Toggle on click/touch
                                     onMouseEnter={handleTooltipToggleIncome}  // Toggle on hover
                                     onMouseLeave={handleTooltipCloseIncome}  // Close on hover away (desktop only)
                                >
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Unsure? Click here...
                                    </Typography>
                                    <IconButton size="small">
                                        <HelpOutlineIcon/>
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </MDBox>

                        {/* Dependents Field */}
                        <MDTypography variant="h6" fontWeight="medium" color="dark">
                            How many dependents do you have?
                        </MDTypography>
                        <MDBox mb={2} display="flex" alignItems="center">
                            <MDInput
                                type="number"
                                value={dependents}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) setDependents(value);  // Allow only digits, no spaces
                                }}  // Update dependents state
                                input={{placeholder: "Enter the number of dependents"}}
                            />
                            <Tooltip
                                title="For tax purposes, we need to know about your dependent crew! Who's counting on you? Share how many dependents you've got, including kids, partner, or financially dependent young adults (21 or under, or 26 if they're still a student). This helps us run the numbers for your financial analysis!"
                                arrow
                                open={tooltipOpenDependents}  // Tooltip open state for Dependents
                                onClose={handleTooltipCloseDependents}  // Close tooltip
                                disableHoverListener={false}  // Hover on desktop is enabled
                                disableTouchListener={false}  // Tooltip is interactive on mobile
                                enterTouchDelay={0}  // Immediate on mobile touch
                                leaveTouchDelay={999999}  // Prevents auto-close on mobile, waits for explicit action
                            >
                                <Box display="flex" alignItems="center" sx={{cursor: 'pointer', ml: 1}}
                                     onClick={handleTooltipToggleDependents}  // Toggle on click/touch
                                     onMouseEnter={handleTooltipToggleDependents}  // Toggle on hover
                                     onMouseLeave={handleTooltipCloseDependents}  // Close on hover away (desktop only)
                                >
                                    <Typography variant="subtitle2" color="textSecondary">
                                        Unsure? Click here...
                                    </Typography>
                                    <IconButton size="small">
                                        <HelpOutlineIcon/>
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </MDBox>
                        {Object.keys(errors).length > 0 && (
                            <MDBox display="flex"
                                   flexDirection="column"
                                   flexGrow={1}
                                   overflow="auto"
                                   mb={2}
                                   sx={{maxHeight: '100vh', overflow: 'auto'}}>
                                {Object.values(errors).map((error, index) => (
                                    <MDTypography variant='h6'
                                                  key={index}
                                                  color="error"
                                                  noWrap={false}
                                                  sx={{
                                                      wordBreak: 'break-word',
                                                      whiteSpace: 'normal',
                                                      textAlign: 'justify'
                                                  }}>
                                        {error}
                                    </MDTypography>
                                ))}
                            </MDBox>
                        )}

                        <MDBox display="flex" justifyContent="flex-end" mt={2}>

                            <MDButton
                                variant="contained"
                                color="primary"
                                onClick={handleUpdate}
                                disabled={loading}
                                sx={{ml: 2}}
                            >
                                {loading ? "Updating..." : "Update"}
                            </MDButton>
                        </MDBox>
                    </Form>
                </Box>
            </>
        </Modal>
    );
};

export default SetupBasicProfile;