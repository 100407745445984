import {Form, Link, redirect, useActionData, useLocation} from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "pages/public/components/BasicLayout";
import bgImage from "assets/images/back9.png";
import {useEffect, useState} from "react";
import {ClipLoader} from 'react-spinners';
import {getCsrfTokenFromCookies} from "../../util/getCsrfTokenFromCookies";
import TrackingScriptLoader, {loadScript} from "../../util/TrackingScriptLoader"; // Import the spinner

// VerifyPhone Component
function VerifyPhone() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const phoneNumber = urlParams.get('phone'); // Get phone number from URL

    const [codeSent, setCodeSent] = useState(false);
    const [error, setError] = useState('');
    const [countdown, setCountdown] = useState(0);
    const [resendAllowed, setResendAllowed] = useState(false);
    const [loading, setLoading] = useState(false); // State for loading spinner
    const [submitting, setSubmitting] = useState(false); // State for form submission
    const actionData = useActionData(); // Use action data to get error from the action function

    useEffect(() => {
        if (actionData?.error) {
            setSubmitting(false); // Reset submitting state on error
        }
    }, [actionData]);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setResendAllowed(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    const handleSendCode = async () => {
        setLoading(true); // Show loading spinner
        try {
            const csrfToken = await getCsrfTokenFromCookies();

            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verifyphone`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken,
                },
                body: JSON.stringify({phone: phoneNumber}),
            });

            if (!response.ok) {
                const errorText = await response.text();
                setError(errorText);
            } else {
                setCodeSent(true);
                setError('');
                setCountdown(30); // Restart countdown timer for 30 seconds
                setResendAllowed(false);
            }
        } catch (err) {
            setError(err.message || "An error occurred.");
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };

    const handleResendCode = async (e) => {
        e.preventDefault(); // Prevent default link behavior
        setLoading(true); // Show loading spinner for resend
        setError('');
        await handleSendCode(); // Reuse the handleSendCode function
    };

    const handleSubmit = (event) => {
        setSubmitting(true); // Set submitting state to true
    };

    const formatCountdown = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const spinnerColor = '#00bfff'; // Set spinner color to match the button color

    return (
        <BasicLayout image={bgImage}>
            <TrackingScriptLoader
                id="facebook-tracking"
                inlineScript={`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '2243764045999242');
fbq('track', 'PageView');
        `}
            />
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Verify your phone number
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Please enter the verification code sent to your phone number
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    {/* Display the phone number and edit link */}
                    <div>
                        <MDBox mb={2}>
                            <MDTypography variant="body1">
                                Phone Number: {phoneNumber}
                                <MDButton variant="text" color="info">
                                    <Link to={`/editphone?phone=${encodeURIComponent(phoneNumber)}`}>Edit</Link>
                                </MDButton>
                            </MDTypography>
                        </MDBox>

                        {!codeSent ? (
                            <div>
                                <MDBox mb={2}>
                                    <MDButton variant="gradient" color="info" fullWidth onClick={handleSendCode}>
                                        Send Code
                                    </MDButton>
                                    {loading && (
                                        <MDBox mt={2} display="flex" alignItems="center" justifyContent="center">
                                            <MDTypography variant="body2" mr={2}>Sending </MDTypography>
                                            <ClipLoader color={spinnerColor} size={20}/>
                                        </MDBox>
                                    )}
                                    {error && (
                                        <MDTypography color="error" variant="body2">
                                            {error}
                                        </MDTypography>
                                    )}
                                </MDBox>
                            </div>
                        ) : (
                            <div>
                                {/* Show code input, countdown, and resend link if code has been sent */}
                                <MDBox mb={2}>
                                    <MDTypography color="success" variant="body2">
                                        Code sent! It will arrive soon. Time left before you can
                                        resend: {formatCountdown(countdown)}
                                    </MDTypography>
                                    <MDTypography variant="body2">
                                        <Link
                                            to="#"
                                            onClick={resendAllowed ? handleResendCode : (e) => e.preventDefault()}
                                            style={{
                                                pointerEvents: resendAllowed ? 'auto' : 'none',
                                                color: resendAllowed ? 'blue' : 'gray'
                                            }}
                                        >
                                            Resend code
                                        </Link>
                                    </MDTypography>
                                </MDBox>
                                {loading && (
                                    <MDBox mt={2} display="flex" alignItems="center" justifyContent="center">
                                        <MDTypography variant="body2" mr={2}>Sending </MDTypography>
                                        <ClipLoader color={spinnerColor} size={20}/>
                                    </MDBox>
                                )}
                                <Form method="post" onSubmit={handleSubmit}>
                                    <MDBox mb={2}>
                                        <MDInput
                                            name="code"
                                            type="text"
                                            label="Code"
                                            fullWidth
                                            required
                                            disabled={submitting} // Disable input field when submitting
                                        />
                                    </MDBox>
                                    {actionData?.error && ( // Display error from action data
                                        <MDTypography color="error" variant="body2">
                                            {actionData.error}
                                        </MDTypography>
                                    )}
                                    <MDBox mt={4} mb={1}>
                                        <MDButton
                                            type="submit"
                                            variant="gradient"
                                            color="info"
                                            fullWidth
                                            disabled={submitting} // Disable button when submitting
                                        >
                                            {submitting ? (
                                                <>
                                                    <ClipLoader size={24} color="#fff"/>
                                                    {' '}
                                                    Loading...
                                                </>
                                            ) : (
                                                'Submit'
                                            )}
                                        </MDButton>
                                    </MDBox>
                                </Form>
                            </div>
                        )}
                    </div>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default VerifyPhone;

// Loader function
export async function verifyPhoneLoader({request}) {
    return null; // The loader is no longer responsible for sending the SMS
}

// Action function for form submission
export async function verifyPhoneAction({request}) {
    const formData = await request.formData();
    const code = formData.get('code');
    const url = new URL(request.url);
    const phoneNumber = url.searchParams.get('phone');

    if (!phoneNumber || !code) {
        return {error: 'Phone number or code is missing'};
    }

    try {
        const csrfToken = await getCsrfTokenFromCookies();

        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verifysmscode`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-csrf-token': csrfToken,
            },
            body: JSON.stringify({phone: phoneNumber, code}),
        });

        if (!response.ok) {
            const errorText = await response.text();
            return {error: errorText || 'Invalid code'}; // Return "Invalid code" on error
        }
        loadScript({
            id: 'google-verify-event',
            inlineScript: "gtag('event', 'conversion', {'send_to': 'AW-16757766302/-86bCLi9luMZEJ7x3LY-'});"
        });
        // Redirect to success page
        return redirect('/app');
    } catch (error) {
        console.error(error);
        return {error: error.message || "An error occurred."};
    }
}
