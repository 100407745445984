import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import 'react-circular-progressbar/dist/styles.css';
import Grid from "@mui/material/Grid";
import DefaultDoughnutChart from "../components/Charts/DoughnutCharts/DefaultDoughnutChart";
import {formatCurrency, formatPercentage} from "../../../util/generalUtils";
import {useState} from "react";
import DefaultCell from "../../../components/DataTable/components/DefaultCell";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../../components/DataTable";
import Modal from "@mui/material/Modal";
import {AddProductForm} from "./AddProductForm";
import {EditProductForm} from "./EditProductForm";
import ChatToAdvisor from "./ChatToAdvisor";

export const MedicalAid = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleOpenAddModal = () => {
        setModalType('add'); // Set type to 'add'
        setSelectedProduct(null); // No product selected for adding
        setOpenModal(true);
    };

    const handleOpenEditModal = (product) => {
        setModalType('edit'); // Set type to 'edit'
        setSelectedProduct(product); // Set the product to be edited
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const createProductRows = (products) => {
        return products.map((product) => ({
            provider: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{product.productProvider}</DefaultCell>
                </div>
            ),
            currentValue: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{formatCurrency(product.productBenefit)}</DefaultCell>
                </div>
            ),
            contribution: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{product.productPremium.toString()}</DefaultCell>
                </div>
            ),
        }));
    };

    const defaultDoughnutChartData = {
        labels: ["Benefit Used", "Benefit Remaining"],
        datasets: {
            label: "Benefits",
            backgroundColors: ["info", "primary"],
            data: [props.medicalAidUsed, props.medicalAidRemaining],
        },
    };
    return (
        <MDBox p={2}>
            <MDTypography variant="h4" sx={{mt: 2, mb: 1, px: 2}}>
                Medical Aid Tax Benefits
            </MDTypography>
            <MDTypography variant="h5" fontWeight="medium" sx={{mt: 2, mb: 1, px: 2}}>
                You are using {formatPercentage(props.medicalAidPercentage)} of your medical aid
                benefits
            </MDTypography>
            <MDBox mb={2} pt={2} pb={2} px={2}>
                <MDTypography variant="h6" sx={{mt: 2, mb: 1}}>
                    You have access to a valuable medical aid tax benefit that not only helps you save on your taxes but
                    also ensures you’re prepared for unforeseen medical expenses. This benefit guarantees that you and
                    your loved ones can receive the best possible care when it’s needed most. Your dedicated advisor is
                    here to guide you in finding the perfect plan or assist with optimizing your existing one, ensuring
                    your peace of mind and well-being!
                </MDTypography>
                <ChatToAdvisor source={'Tax Medical Aid'}/>
            </MDBox>

            {/* Grid for Bar Chart and Tax Portfolio */}
            <MDBox mt={3}>
                <Grid container alignItems="center" spacing={2}>
                    {/* Your Tax Portfolio Card (Left) */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{p: 2, height: "20rem"}}>
                            <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                                   height="100%">

                                <MDTypography variant="h6" fontWeight="medium" mt={2}>
                                    Est. Tax Reduction Benefit Available:
                                </MDTypography>
                                <MDTypography color="dark">{formatCurrency(props.medicalAidBenefit)}</MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={2}>
                                    Est. Tax Reduction Benefits Used:
                                </MDTypography>
                                <MDTypography color="info">{formatCurrency(props.medicalAidUsed)}</MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={2}>
                                    Est. Tax Reduction Benefit Unused:
                                </MDTypography>
                                <MDTypography color="primary">{formatCurrency(props.medicalAidRemaining)}</MDTypography>
                            </MDBox>
                        </Card>
                    </Grid>

                    {/* Bar Chart Card (Right) */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{p: 2, height: "20rem"}}>
                            <DefaultDoughnutChart
                                icon={{color: "success", component: "donut_small"}}
                                title="Medical Aid Tax Benefits"
                                height="20rem"
                                description="Medical Aid Tax Benefits"
                                chart={defaultDoughnutChartData}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={3}>
                <Card>
                    <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} pt={3} px={3}>
                        <MDTypography variant="h6" fontWeight="medium" align="left">
                            Existing Medical-Aid Products
                        </MDTypography>
                        {props.products.length === 0 && (
                            <IconButton onClick={handleOpenAddModal} color="info" sx={{mt: 1}}>
                                <AddIcon/>
                                <MDTypography variant="button" ml={1}>
                                    Add Existing Products
                                </MDTypography>
                            </IconButton>
                        )}
                        {props.products.length > 0 && (
                            <MDTypography mt={2} variant="h6" color='info'>
                                You can edit or delete a product by clicking on it.
                            </MDTypography>
                        )}
                    </MDBox>
                    <MDBox py={1}>
                        {props.products.length === 0 ? (
                            // Display this if there are no products
                            <MDBox
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    backgroundColor: '#f9f9f9', // Light background
                                    wordWrap: 'break-word', // Ensure text wraps properly
                                    overflowWrap: 'break-word',
                                }}
                            >
                                <MDTypography variant="h6" sx={{wordWrap: 'break-word'}}>
                                    There are no products added yet. You can add products with the button above.
                                </MDTypography>
                            </MDBox>
                        ) : (
                            // Display the table if there are products
                            <DataTable
                                table={{
                                    columns: [
                                        {Header: "Provider", accessor: "provider"},
                                        {Header: "Tax Benefit", accessor: "currentValue"},
                                        {Header: "Dependents", accessor: "contribution"},
                                    ],
                                    rows: createProductRows(props.products), // Render product rows
                                }}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                isSorted={false}
                                noEndBorder
                            />
                        )}
                    </MDBox>


                    <Modal open={openModal} onClose={handleCloseModal}>
                        <MDBox
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                bgcolor: "background.paper",
                                border: "2px solid #000",
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            {modalType === 'add' ? (  // Conditional rendering based on modal type
                                <AddProductForm onClose={handleCloseModal} productType={props.productType}
                                                routeType={props.routeType}/>
                            ) : (
                                <EditProductForm
                                    product={selectedProduct}  // Pass the selected product for editing
                                    onClose={handleCloseModal}
                                    productType={props.productType}
                                    routeType={props.routeType}
                                />
                            )}
                        </MDBox>
                    </Modal>
                </Card>
            </MDBox>
        </MDBox>
    );
};