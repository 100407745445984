import React from 'react';
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";

function DefaultCell({children}) {
    return (
        <MDTypography variant="button" fontWeight="regular" color="text">
            {React.isValidElement(children) ? children : String(children)}
        </MDTypography>
    );
}

DefaultCell.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
        PropTypes.node
    ]).isRequired,
};

export default DefaultCell;