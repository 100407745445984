import React from 'react';
import {Container} from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';


export const TermsAndConditionsUser = () => {
    return (
        <Container maxWidth="md">
            <MDBox my={4} pt={15} mt={-3}>
                <MDTypography variant="h4" gutterBottom>
                    Client Terms and Conditions
                </MDTypography>
                <MDTypography variant="body2" color="textSecondary" gutterBottom>
                    Published: 1 October 2024
                </MDTypography>
                <MDTypography variant="body2" gutterBottom>
                    These terms are effective as of 1 October 2024. PLEASE READ THESE TERMS OF USE CAREFULLY. Welcome to
                    GrowYourBucks! These terms and conditions cover important information about the Platform. The use of
                    this website, mobile application, or any of our Platforms are at your own risk and subject to these
                    Terms and Conditions.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    1. Contractual Relationship
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    These Terms and Conditions and any other terms and documents expressly incorporated herein, each as
                    may be amended, supplemented or replaced from time to time (together, the “Terms” or the
                    “Agreement”) apply to your use of the GrowYourBucks Website with URL: <a
                    href="https://growyourbucks.com">https://growyourbucks.com</a> (“the website”) and/or GrowYourBucks
                    mobile application (the “GrowYourBucks App”), blog, social media pages or one of our other products
                    or services all of which are part of the GrowYourBucks Platform (“the Platform”). These Terms are
                    entered into by and between you as the user (referred to as “User”, “you” or “your” or “the Client”)
                    and the System Owner, Domivi LLC having its registered offices in the State of Delaware at 1111B S
                    Governors Ave STE 23686 Dover, DE 19904, and its subsidiary the System Administrator, Domivi (Pty)
                    Ltd (referred to as “GrowYourBucks”, “we”, “us” or “our” having its registered offices at Block N1,
                    Regus Building, 107 Boardwalk Boulevard, Faerie Glen, Pretoria, South Africa, registered at the
                    Companies and Intellectual Property Commission (“CIPC”) under registration number 2023 / 986526 /
                    07, and constitute the entire Agreement and understanding of the parties with respect to its subject
                    matter and supersede and replace any terms and conditions that you have previously agreed to in
                    connection with the Platform. Your access to and use of the Platform constitutes your agreement to
                    be bound by these Terms. The offering of the Platform to you is conditional on your acceptance of
                    these Terms. If you do not agree to these Terms, you must not access or use the Platform.
                    GrowYourBucks may revise, amend and update the Terms from time to time in its sole discretion.
                    Amendments and updates will be effective immediately upon its publication on the Platform and apply
                    to all access to and use of the Platform directly thereafter. You will receive a notification of any
                    updates and you are advised to review these Terms periodically for any changes. For more detail
                    about our collection and use of Personal Information, please refer to our Privacy Policy.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    2. User
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>2.1. Eligibility</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    By accessing or using the Platform, you confirm that you are of legal age, are not under the age of
                    18 years old, have full power, capacity and authority to agree to the Terms and have not previously
                    been suspended or removed from using the Platform.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>2.2. User Account Creation</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    To use certain features of the Platform, you will need to create a user account (each, a “User
                    Account”) by providing your full names, an email address, verified contact number, date of birth, a
                    password and other information that we may require from time to time. You agree to provide accurate,
                    complete and current information when creating and maintaining your User Account. Your failure to
                    maintain accurate, complete and current User Account information may result in your inability to
                    access and use the Platform or termination. GrowYourBucks may at its sole discretion:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • limit the number of User Accounts that you may create or maintain; you may only possess one User
                    Account, unless otherwise permitted by GrowYourBucks in writing.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • stipulate additional conditions and requirements for the opening or maintaining of User Accounts;
                    and/or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • refuse to create a User Account for you.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    By creating a User Account, you agree that GrowYourBucks may send you text messages and
                    notifications as part of the normal business operation of your use of the Platform. You may opt-out
                    of receiving text messages, and you may manage your notification settings at any time. You
                    acknowledge that opting out of receiving text messages and notifications may impact your use of the
                    Platform.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>2.3. Security</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    By using the User Account, you will be responsible for maintaining the security and secrecy of your
                    User Account access credentials, for all activities that occur under the User Account and any other
                    actions in relation to the User Account (with or without your permission). You may not authorise
                    another person or third parties to access or use your User Account or transfer your User Account to
                    any other person or third party. GrowYourBucks is not responsible for any loss or activity that
                    results from the unauthorised use of your User Account due to your failure to secure your access
                    credentials and/or breaching these Terms. You should not share the access credentials of your User
                    Account (including passwords) with any other person or allow any other person to access your User
                    Account. You must immediately notify the GrowYourBucks support team of any unauthorised use of your
                    User Account or any other breaches of security by logging a support ticket on the Platform by
                    navigating to ‘support’-‘bugs’-‘issue/ error’.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>2.4. Suspension and Termination</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    GrowYourBucks has the right to suspend access to your User Account, or to terminate your User
                    Account, at any time and for any reason, including where you are in breach of any of the provisions
                    in these Terms. You acknowledge and agree that these Terms continue to apply even after your User
                    Account is suspended or terminated, or where you have stopped using the Platform.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    3. The Platform and Services
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>3.1. Scope and Purpose</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    The Platform is not available for use by persons under the age of 18. The Platform constitutes a
                    technology platform that introduces the Client to a Service Representative to provide easy access to
                    the services and/or products that the Service Providers offer, and to further provide access and use
                    of the features of the Platform with the aim to assist the Client in saving on insurance and tax and
                    to create financial wellness. Unless otherwise agreed by GrowYourBucks in a separate written
                    agreement with you, the Platform is made available solely for your personal non-commercial use. You
                    acknowledge that GrowYourBucks, its owners or affiliates does not provide financial services, credit
                    services, financial, tax or legal advice and that all such services are provided by independent
                    third-party Service Providers and their Service Representatives who are not employed by
                    GrowYourBucks or any of its owners or affiliates. All Service Partners and Service Representatives
                    have an exclusive Mandate to offer Authorised Services to you. It is your responsibility to ensure
                    that only Authorised Services are offered and to immediately report to GrowYourBucks the delivery of
                    any unauthorised services by any Service Representative.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>3.2. Service Provider Services, Products and Content</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    In using the Platform, you may view and access content provided by third-party Service Providers and
                    their Representatives, including links to web pages and services, advice or products (including
                    advertising) of such Service Providers (“Service Provider Content”). The Service Provider Content
                    made available or accessed on the Platform are not controlled by GrowYourBucks. You acknowledge that
                    different terms of use and privacy policies apply to your use of the Service Provider Content.
                    GrowYourBucks does not endorse the Service Provider’s services, advice, products and content and in
                    no event shall GrowYourBucks be responsible or liable for any Service Provider Content including
                    material that may be misleading, incomplete, erroneous, offensive, indecent or otherwise
                    objectionable. Your dealings or correspondence with such Service Providers and their Representatives
                    are solely between you and such Service Provider and Representative. GrowYourBucks is not
                    responsible or liable for any loss or damage of any sort incurred as a result of any such dealings
                    and you understand that your use of Service Provider Content, and your interactions with Service
                    Providers and their Representatives, are at your own risk.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>3.3. Assumptions and Disclaimer</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    As part of the Platform features, calculations may be made based on basic information received,
                    including to measure your tax efficiency, track your financial wellness, and credit rating. Such
                    calculations provide estimated results in order to suggest value-added services and products which
                    may be of benefit to you as offered by Service Providers and are not a guarantee of savings nor
                    intended to be accurate financial and/or tax advice, and the professional advice of a financial, tax
                    and/or legal advisor should therefore still be acquired before you make any financial decision.
                    Calculations are based on certain assumptions as described below:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • The medical schemes fees tax credit calculation does not take into account any additional medical
                    expenses tax credit.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • The allowable deduction in regard to retirement contributions assumes that the gross income
                    provided by the User is only in respect of remuneration and excludes retirement lump sum benefits,
                    withdrawal lump sum benefits, severance benefits, passive income, taxable capital gains and before
                    any s11F (deduction for lessee) and s18A (donations) deduction.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>3.4. Changes and Updates to the Platform</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    GrowYourBucks may delete or modify any feature or part of the Platform at any time without notice.
                </MDTypography>

                <MDTypography variant="h5" gutterBottom>
                    4. Subscription Services
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>4.1. Annual Tax Submission Subscription</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You can choose to subscribe on the Platform for tax submission services offered through one of our
                    Service Providers at an annual fee payable in advance/upfront through the Platform. The annual tax
                    submission fee will be determined according to the type of tax submission service required by you,
                    ranging from handling a simple to a more complex tax return. The applicable annual fee will cover
                    the tax submissions due within the next 12 months from your subscription start date. Any prior
                    period submissions and/or additional work to be done by the Service Representative will be at an
                    additional charge and payable on the Platform in advance/prior to delivery of any such additional
                    services.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>4.2. Promotions</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    GrowYourBucks may offer promotional offers and special discounts from time to time which will be
                    subject to separate terms and conditions to be read in conjunction with these Terms.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>4.3. Price Changes</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    We reserve the right to revise and update the applicable fees for subscriptions and the different
                    service packages available at any time at our sole discretion. Any such revision or updates to the
                    fees will apply prospectively to any subscription entered into following the effective date of the
                    fee revision or update. In the event of a fee update, we will attempt to notify you not less than
                    thirty (30) days in advance of the change by sending you an email and, where applicable, push
                    notification. If you do not wish to accept a fee update, you may cancel your subscription prior to
                    the fee update taking effect. If you do not cancel your subscription after the fee update takes
                    effect and prior to the start of your next billing period, your subscription will be renewed at the
                    price in effect at the time of the renewal, without any additional action by you, and you authorise
                    us to charge your payment method these updated amounts.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>4.4. Payment Details</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You can pay the fees for your subscription and additional services on the Platform. When you
                    purchase a subscription or pay for additional services, you must provide complete and accurate
                    payment information. By submitting payment details, you represent that you are entitled to make the
                    purchase using those payment details. If we do not receive payment authorisation or any
                    authorisation is subsequently cancelled, we may immediately terminate or suspend your access to your
                    subscription and notify the Service Provider and Representative of the termination or suspension.
                    GrowYourBucks reserves the right to contact the issuing bank/payment provider and/or law enforcement
                    authorities or other appropriate third parties if there is suspicious activity.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>4.5. Automatic Renewal of Subscription and Cancellations</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    Your subscription will automatically renew for the same term of your initial subscription unless you
                    cancel your subscription at least twenty-four (24) hours before the end of your current billing
                    period. However, you may cancel your subscription at any time. Upon such cancellation, you will
                    still be able to access the subscription services on the platform for the remainder of the duration
                    that you have paid for. A refund will not be issued upon cancellation.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    5. Use of the Platform
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    By using the Platform, you agree to comply with all applicable laws and to only use the Platform for
                    lawful purposes.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>5.1. User Restrictions</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You confirm that you will not use the Platform for any of the following:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • <strong>Unlawful Activity</strong> - any activity/ies that:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - involves proceeds from any illegal or unlawful activity;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - publishes, distributes, or disseminates any illegal or unlawful material or information; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - otherwise violates, or could possibly violate, any civil and common laws, statutes, subordinate
                    legislation, treaties, regulations, directives, by-laws, ordinances, circulars, codes, orders,
                    notices, demands, decrees, injunctions, resolutions, and judgments of any governmental, statutory,
                    administrative or regulatory body, court agency or association by which GrowYourBucks, its owners,
                    or affiliates and the User are bound (“Applicable Laws”).
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • <strong>Unauthorised Use</strong> - any activity/ies that:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - interferes with, disrupts, negatively affects, or inhibits other Users from accessing or fully
                    enjoying the Platform;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - introduces to the Platform any malware, virus, trojan horse, worms, logic bombs, or any other
                    programme that would otherwise result in any technical glitch, malfunction, failure, delay, default,
                    or security breach;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - attempts to gain unauthorised access, whether through password mining or otherwise, to other User
                    Accounts, computing systems or networks connected to the Platform;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - undermines the security or integrity of the computing systems or networks on which the Platform is
                    hosted;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - attempts to modify, copy, reproduce, reverse engineer or decompile the Platform or the computer
                    programmes used to deliver the Platform;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - uses the User Account information of another person to access or use the Platform;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - transfers access or rights to your User Account to a third party;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - removes any copyright, trademark, or other proprietary notices from any portion of the Platform;
                    or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell,
                    transfer, publicly display, publicly perform, transmit, stream, broadcast, or otherwise exploit the
                    Platform except as expressly permitted by GrowYourBucks.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • <strong>Abusive Acts</strong> - any act that:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - defames, abuses, extorts, harasses, stalks, threatens, or otherwise violates or infringes the
                    legal rights (such as but not limited to rights of privacy, publicity, and intellectual property) of
                    any other person, GrowYourBucks, or another entity;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - incites, threatens, facilitates, promotes, or encourages hate, racial intolerance, or violent acts
                    toward any other person; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - harvests or otherwise collects information from the Platform about other Users including
                    addresses, phone numbers, email addresses, and payment details.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • <strong>Fraud</strong> - any act/s that:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - attempts to defraud GrowYourBucks or any other person or entity; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - provides false, inaccurate, or misleading information to GrowYourBucks.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>5.2. User Contributed Content</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You are solely responsible for all content and information, whether textual, audio, and/or visual,
                    that you submit or otherwise upload to or through the Platform or to us, including but not limited
                    to commentary and feedback related to the Platform, Service Representatives, or Service Providers,
                    initiation of support requests, or ideas that you send to GrowYourBucks (“User Content”).
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You represent and warrant that you either are the sole and exclusive owner of all User Content or
                    you have all rights, licenses, consents, and releases necessary to grant GrowYourBucks the right to
                    process the User Content.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You expressly agree not to submit or upload any User Content which:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • advertises or promotes any services or brands (with respect to you or any third party);
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • contains material that is defamatory, libellous, discriminatory, obscene, indecent, abusive,
                    racist, offensive, harassing, violent, hateful, or is otherwise objectionable or unlawful;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • you know not to be true and honest, or which spreads false or misleading statements;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • you do not have the right to submit or upload, including where the content may infringe any
                    Intellectual Property rights, or rights of publicity or privacy or result in the violation of any
                    Applicable Law;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • impersonates any person or entity or otherwise associates, infers, or misrepresents the User’s
                    affiliation with a person or entity;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • contains the personal details or confidential information of any third party, unless that third
                    party has expressly consented to such use and disclosure;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • contains, or links to, viruses, corrupted data, or other harmful, disruptive, or destructive
                    files;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • constitutes unsolicited promotions, campaigning, advertising, or solicitations, or other types of
                    content which constitute “spam”;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • may expose GrowYourBucks or other Users to any harm or liability of any kind; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • is contrary to Applicable Laws.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    GrowYourBucks has the right, but not the obligation to monitor all conduct and content submitted to
                    or through the Platform, and may in its sole discretion without notice to you:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • refuse to publish, remove, or disable access to User Content that we consider breaches these
                    Terms; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • suspend or discontinue your opportunity to submit, post, or upload content to the Platform.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You acknowledge and agree that some of your User Content may continue to be available on the
                    Platform after your User Account is closed or otherwise terminated, subject to your right to have
                    your User Content removed upon request in accordance with Applicable Laws.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    If you believe that any User Content violated these Terms or any Applicable Laws, including any
                    copyright laws, you should report it to the GrowYourBucks support team by logging a support ticket
                    on the Platform by navigating to ‘support’-‘bugs’-‘issue/error’.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    6. Network Access and Security
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>6.1. Access and Downtime</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You are responsible for obtaining the network access necessary to use the Platform. You will be
                    responsible for your mobile network’s data and messaging rates and fees.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    GrowYourBucks will make reasonable efforts to ensure that the Platform is available to you. However,
                    access to the Platform may be disrupted from time to time due to necessary maintenance, technical
                    issues, network and system overloads, or events out of GrowYourBucks’s control. We will use
                    commercially reasonable efforts to avoid downtime of the Platform, but assumes no liability if the
                    Platform or any part thereof is unavailable at any time or for any period.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You must immediately notify the GrowYourBucks team in the event that you become aware of any part of
                    the Platform malfunctioning or if you otherwise experience any material malfunction or other
                    connectivity problem that adversely affects your access to or use of the Platform.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    Users may be required to download and install updates to the GrowYourBucks App so as to maintain
                    access to the Platform. A User’s failure to do so might lead to certain services offered on the
                    Platform becoming temporarily inaccessible to the User until such update has been downloaded and
                    installed.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>6.2. Compatibility</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You are further responsible for acquiring and updating compatible hardware or devices necessary to
                    access and use the Platform. GrowYourBucks does not guarantee that the Platform or any portion
                    thereof, will function on any particular hardware or devices.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>6.3. Security</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You acknowledge and agree that you are responsible for implementing sufficient procedures and
                    checkpoints to satisfy your particular requirements for antivirus protection and accuracy of data
                    input and output, and for maintaining a means external to the Platform for any reconstruction of
                    lost data.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>6.4. Release of liability</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You acknowledge and agree that GrowYourBucks shall not have any liability or be responsible in any
                    way for:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • your use of the internet to connect to the Platform or any technical problems, system failures or
                    security breaches;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • the hardware that you use to access the Platform (including in respect of viruses and malicious
                    software, and any inappropriate material) and the integrity of proper storage of any of your data
                    associated with the Platform that is stored on your own hardware; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • any fees you may incur in order to connect to the internet for the purpose of using or accessing
                    the Platform.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    7. Intellectual Property, Use License
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>7.1. Ownership</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    The Platform and all of the patents, trademarks, logos, trade names, rights in domain names,
                    copyrights, moral rights, design rights, database rights, rights in undisclosed or confidential
                    information (such as know-how, trade secrets and inventions, whether or not patentable, and other
                    similar intellectual property rights, whether registered or not) and applications for such rights as
                    may exist anywhere in the world (collectively “Intellectual Property Rights”) therein and the
                    material published on and through it (except the User Content) are owned by the System Owner or its
                    affiliates and other providers of such material and are protected by Applicable Laws. You may not
                    engage in any activity on or through the Platform, including transmitting or using User Content,
                    that infringes or otherwise makes unauthorised use of another party’s Intellectual Property Rights.
                    Neither these Terms nor your use of the Platform convey or grant you any rights:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • in or related to the Platform except for the limited license granted below; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • to use or reference in any manner GrowYourBuck’s names, logos, product and service names,
                    trademarks or service marks.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>7.2. User’s license to use the Platform</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You are not granted any right to use, and may not use, any of System Owner or its affiliates’
                    Intellectual Property Rights other than as set out in these Terms. Subject to your compliance with
                    these Terms, you are granted a limited, personal, non-exclusive, non-sub-licensable, revocable,
                    non-transferrable license to:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • access and use the Platform for your own personal use on your personal device solely in connection
                    with your use of the Platform and features; and
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • access and use any content, information and related materials that may be made available through
                    the Platform and features,
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    in each case solely for your personal, noncommercial use. Any rights not expressly granted herein
                    are reserved by System Owner and/or its affiliates. And you may NOT:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • copy, reproduce, republish, upload, re-post, modify, transmit, distribute or otherwise use the
                    Platform (or any part of it or its content) in any way for non personal, public or commercial use
                    without prior written consent from System Owner and/or its affiliates;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • remove or modify any copyright, trademark or other proprietary notices that have been placed in
                    any part of the Platform; and
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • use any data mining, robots or similar data-gathering or extraction methods.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    GrowYourBucks reserves the right to monitor your use of the Platform and to alter or revoke your
                    license or your access to the Platform at any time and for any reason. Your license shall terminate
                    upon the expiry of termination of your User Account.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>7.3. User Content</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    By posting or otherwise providing User content, you provide GrowYourBucks a non-exclusive,
                    royalty-free, transferable, sub-licensable, irrevocable, perpetual, worldwide license and right to
                    use (including for commercial use), distribute, reproduce, display and otherwise make available such
                    User Content on and through the Platform (and across different media for any purpose) and you waive
                    (and to the extent that you cannot waive agree irrevocably not to assert) any and all moral right to
                    which you may be entitled to anywhere in the world in respect of such User Content.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You represent and warrant that you own and control all the rights to the User Content, and have the
                    lawful right to post or otherwise provide such User Content on and through the Platform and
                    otherwise provide GrowYourBucks with such User Content.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    8. Limitations of Liability, Disclaimer, Indemnity
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>8.1. Disclaimer</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    To the maximum extent permitted under Applicable Laws, the Platform and any product, service or
                    other item provided by or on behalf of GrowYourBucks are provided on an “as is” and “as available”
                    basis. GrowYourBucks expressly disclaims, and you waive any and all representations and warranties
                    of any kind, whether express, implied or statutory, not expressly set out in these Terms, including
                    the implied warranties of merchantability, fitness for a particular purpose, title or
                    non-infringement or warranties arising from the course of performance, course of dealing or usage in
                    trade. In addition, GrowYourBucks makes no representation, warranty, or guarantee regarding the
                    reliability, timeliness, quality, suitability, accuracy, completeness or availability of the
                    Platform or any services or products requested through the use of third-party Service Providers, or
                    that the Platform will be uninterrupted or error-free. GrowYourBucks does not guarantee the quality,
                    suitability or ability of third-party Service Providers and their Representatives. You agree that
                    the entire risk arising out of your use of the Platform, and any service or product requested from
                    or through a Service Representative remains solely with you.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    To the maximum extent permitted under Applicable Law, GrowYourBucks is not responsible or liable for
                    any loss or damage of any sort incurred that results from your use of, or inability to use, the
                    features of the Platform. The features of the Platform that promote financial wellness are for your
                    informational purposes only and are not intended as financial advice or services. Before making any
                    financial decision, we recommend seeking advice from a qualified professional financial or tax
                    advisor or attorney available through the Platform. Never disregard the advice of a professional
                    because of something that you have read on the Platform.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>8.2. Limitation of Liability</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    Nothing in these Terms shall exclude or restrict your rights as a consumer that cannot be excluded
                    under Applicable Law.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    To the maximum extent permitted by Applicable Law, GrowYourBucks, its affiliates and its respective
                    shareholders, members, directors, officers, employees, attorneys, agents, representatives, suppliers
                    and contractors (collectively, “Associated Parties”) shall not be liable for any:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • indirect, incidental, special, exemplary, punitive or consequential damages including loss of
                    profit, data, business opportunity, anticipated savings, revenue or goodwill, personal injury or
                    property damage, in each case, whether arising from breach of contract, tort, delict (including
                    negligence), breach of statutory duty or otherwise arising out of, related to or in connection with
                    or otherwise resulting from any authorised or unauthorised use of the Platform or these Terms even
                    if GrowYourBucks has been advised of the possibility of such damages; and
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • damages, liability or losses arising out of:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - your use of or reliance on the Platform or your inability to access or use the Platform; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    - any transaction or relationship between you and any third-party Service Provider or Service
                    Representative, even if GrowYourBucks has been advised of the possibility of such damages; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • delay or failure in performance resulting from causes beyond GrowYourBucks's reasonable control.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    GrowYourBucks and the Associated Parties’ aggregate liability in contract, tort, delict or otherwise
                    (including any liability for any negligent act or omission) howsoever arising out of, or in
                    connection with, the performance of its obligations under these Terms in respect of any one or more
                    incidents or occurrences shall in no event exceed one thousand rand (ZAR 1,000.00).
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>8.3. Indemnity</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    To the maximum extent permitted by Applicable Law, you agree to indemnify and hold harmless
                    immediately upon demand GrowYourBucks and the Associated Parties from any claim, demand, lawsuit,
                    action, proceeding, investigation, liability, damage, loss, cost or expense including reasonable
                    attorney’s fees, in any way arising out of, in relation to or in connection with directly or
                    indirectly:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • your use of, or conduct in connection with the Platform;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • your breach of these Terms;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • the User Content you provide; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • your violation of any Applicable Laws or the rights of any other person or entity including
                    Service Providers.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You will provide GrowYourBucks and Associated Parties with any assistance that GrowYourBucks and the
                    Associated Parties reasonably request in defending any such action or proceeding.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    9. Complaints, Governing Law, and Dispute Resolution
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>9.1. Complaints</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    If you have any questions, feedback or complaints relating to the Platform, please contact the
                    GrowYourBucks support team by logging a support ticket on the Platform by navigating to
                    ‘support’-‘bugs’-‘issue/error’. You understand that GrowYourBucks reserves all its rights under the
                    Applicable law to take the necessary legal action against any comment or complaint made on any
                    public forum that is unfounded, defamatory, unreasonable or otherwise in breach of these Terms.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    <strong>9.2. Governing Law and Dispute Resolution</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    These Terms (including a dispute relating to its existence, validity or termination) and any
                    non-contractual obligation or other matter arising out of or in connection with it are governed by
                    South African Law. Any dispute, conflict, claim or controversy arising out of or broadly in
                    connection with or relating to the Platform, or these Terms, including those relating to its
                    validity, its construction or its enforceability (the “Dispute”) shall be first mandatorily
                    submitted to the other party to provide an opportunity to resolve the matter by negotiation between
                    the parties.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    If such Dispute has not been settled within sixty (60) days after a request was submitted for
                    negotiation, such Dispute can be referred to and shall be exclusively and finally resolved by
                    arbitration under the Arbitration Foundation of Southern Africa (“AFSA”) to be resolved by a single
                    arbitrator appointed by AFSA. The existence and content of the dispute and arbitration proceedings,
                    including documents and briefs submitted by the parties, correspondence from and to the AFSA,
                    correspondence from the arbitrator, and correspondence, orders and awards issued by the sole
                    arbitrator, shall remain strictly confidential and shall not be disclosed to any third party without
                    the express written consent from the other party unless:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • the disclosure to the third party is reasonably required in the context of conducting the
                    arbitration proceedings; and
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • the third party agrees unconditionally in writing to be bound by the confidentiality obligation
                    stipulated herein.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    Notwithstanding the above, each party shall have the right to proceed with legal action in a South
                    African Court with jurisdiction.
                </MDTypography>
                <MDTypography variant="h5" gutterBottom>
                    10. General
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.1. Force Majeure Event</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    A “Force Majeure Event” means any event caused by circumstances beyond GrowYourBucks’s reasonable
                    control, including but not limited to:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • acts of God, such as earthquakes, hurricanes, floods, or other natural disasters;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • war, terrorism, or civil unrest;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • government actions, regulations, or embargoes;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • strikes, lockouts, or labor disputes;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • pandemics or epidemics;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • power outages or communication disruptions;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • equipment or software malfunction; and
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • unforeseen changes in laws or regulations.
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    During the period of the Force Majeure Event, GrowYourBucks will contact you as soon as reasonably
                    possible to notify you. Although we will take reasonable steps to mitigate the effects of the Force
                    Majeure Event, GrowYourBucks’s obligations under these Terms will be suspended for the duration of
                    the Force Majeure Event, with the time of performance of obligations as soon as reasonably possible.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.2. Data Privacy</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    We will only use your personal information as set out in GrowYourBucks’s Privacy Policy (as amended
                    from time to time).
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.3. Notice</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    GrowYourBucks may give notice by means of a general notice on the Platform, electronic mail to your
                    email address as per your User Account, or by written communication sent to your address as set
                    forth in your User Account. You may give notice to GrowYourBucks by written communication to Regus
                    Building, 107 Boardwalk Boulevard, Faerie Glen, Pretoria, 0081.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.4. Transfers</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    You may not assign or transfer your rights and obligations under these Terms in whole or in part
                    without GrowYourBucks’s prior written approval. You give your approval to GrowYourBucks for it to
                    assign and transfer its rights and obligations under these Terms in whole or in part to another
                    entity, including to:
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • a subsidiary or affiliate;
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • an acquirer of GrowYourBucks’s equity, business or assets; or
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    • a successor by merger.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.5. Relationship of the Parties</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    Nothing in these Terms is intended to, nor shall create or be deemed to create, any partnership,
                    joint venture, employment or agency relationship, consultancy or trusteeship nor shall it give rise
                    to any fiduciary or equitable duties owed by GrowYourBucks to you as a result of the Agreement
                    between you and GrowYourBucks or use of the Platform.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.6. Interpretation</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    In these Terms, the words “including” and “include” mean “including without limitation” limited to.
                    The headings and subheadings used in these Terms are for convenience only and shall not be used to
                    interpret or construe the provisions of these Terms. Unless the context otherwise requires, headings
                    shall not be deemed to be part of the Terms or be used to interpret any provision of the Terms. In
                    these Terms “singular” includes “plural” and vice versa; and “person” includes individuals,
                    corporations, partnerships, trusts, and unincorporated organisations.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.7. Validity</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    If any provision of these Terms is held to be illegal, invalid or unenforceable, in whole or in
                    part, under any law, such provision or part thereof shall to that extent be deemed not to form part
                    of these Terms, but the legality, validity and enforceability of the other provisions in these Terms
                    shall not be affected. In that event, the parties shall replace the illegal, invalid or
                    unenforceable provision or part thereof with a provision or part thereof that is legal, valid and
                    enforceable and that has, to the greatest extent possible, a similar effect as the illegal, invalid
                    or unenforceable provision or part thereof, given the contents and purpose of these Terms.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.8. Waiver</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    No waiver of any provision in these Terms shall be valid unless in writing and signed by the
                    parties. Any failure to enforce any right or remedy under these Terms shall not operate as a waiver
                    of the right to enforce such right or remedy in the future or of any other right or remedy.
                </MDTypography>

                <MDTypography variant="body1" paragraph>
                    <strong>10.9. Third Party Rights</strong>
                </MDTypography>
                <MDTypography variant="body1" paragraph>
                    A person who is not a party to these Terms has no right whether by applicable statute or otherwise
                    to enforce any term of these Terms.
                </MDTypography>


            </MDBox>
        </Container>
    );
};