export const formatCurrency = (amount) => {
    const formattedAmount = new Intl.NumberFormat('en-ZA', {
        minimumFractionDigits: 0, // No decimals
        maximumFractionDigits: 0
    }).format(amount);
    return `R ${formattedAmount.replace(/,/g, ' ')}`;
}

export const formatPercentage = (decimal) => {
    return `${Math.round(decimal * 100)}%`;
}