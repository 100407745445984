/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {collapseArrow, collapseIcon, collapseIconBox, collapseItem, collapseText,} from "./styles/sidenavCollapse";

// Material Dashboard 2 PRO React context
import {useMaterialUIController} from "context";

function SidenavCollapse({icon, name, children = false, active = false, noCollapse = false, open = false, ...rest}) {
    const [controller] = useMaterialUIController();
    const {miniSidenav, transparentSidenav, whiteSidenav, darkMode} = controller;

    return (
        <>
            <ListItem component="li">
                <MDBox
                    {...rest}
                    sx={(theme) =>
                        collapseItem(theme, {active, transparentSidenav, whiteSidenav, darkMode})
                    }
                >
                    <ListItemIcon
                        sx={(theme) => collapseIconBox(theme, {transparentSidenav, whiteSidenav, darkMode})}
                    >
                        {typeof icon === "string" ? (
                            <Icon sx={(theme) => collapseIcon(theme, {active})}>{icon}</Icon>
                        ) : (
                            icon
                        )}
                    </ListItemIcon>

                    <ListItemText
                        primary={name}
                        sx={(theme) =>
                            collapseText(theme, {
                                miniSidenav,
                                transparentSidenav,
                                whiteSidenav,
                                active,
                            })
                        }
                    />

                    {!noCollapse && (
                        <Icon
                            sx={(theme) =>
                                collapseArrow(theme, {
                                    noCollapse,
                                    transparentSidenav,
                                    whiteSidenav,
                                    miniSidenav,
                                    open,
                                    active,
                                    darkMode,
                                })
                            }
                        >
                            {open ? "expand_more" : "expand_less"}
                        </Icon>
                    )}
                </MDBox>
            </ListItem>
            {children && (
                <Collapse in={open} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
}


// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    active: PropTypes.bool,
    noCollapse: PropTypes.bool,
    open: PropTypes.bool,
};

export default SidenavCollapse;
