import React, {useState} from 'react';
import {Box, CircularProgress, Modal, TextField,} from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import axios from "axios";
import {getCsrfTokenFromCookies} from "util/getCsrfTokenFromCookies";
import TrackingScriptLoader from "../../../util/TrackingScriptLoader";

// Import your existing function to send email to advisor
// import {sendEmailToAdvisor} from 'path/to/your/emailFunction';

const ChatToAdvisor = (props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'
    const [errorMessage, setErrorMessage] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setMessage('');
        setStatus('idle');
        setErrorMessage('');
    };

    const handleSend = async () => {
        setStatus('loading');
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            await axios.post(`${process.env.REACT_APP_API_URL}/clients/chatToAdvisor`, {
                    source: props.source,
                    message: message,

                }, {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );
            if (window.fbq) {
                window.fbq('track', 'SubmitApplication', {
                    content_name: 'Profile Setup',
                    status: 'Completed',
                });
            } else {
                console.warn('Facebook Pixel not initialized.');
            }
            setStatus('success');
        } catch (error) {
            setStatus('error');
            setErrorMessage(error.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <>
            <MDButton variant="gradient" color="dark" size="small" onClick={handleOpen}>
                Chat to Your Designated Advisor
            </MDButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="chat-to-advisor-modal"
                aria-describedby="chat-to-advisor-modal-description"
            >
                <>
                    <TrackingScriptLoader
                        id="facebook-tracking"
                        inlineScript={`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '2243764045999242');
fbq('track', 'PageView');
        `}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '90%',  // Set a percentage width for smaller screens
                            maxWidth: 400,  // Ensure it doesn’t grow too large on larger screens
                            bgcolor: 'background.paper',
                            borderRadius: '8px',
                            boxShadow: 24,
                            p: 4,
                            maxHeight: '90vh',  // Prevent overflow out of viewport
                            overflowY: 'auto',   // Enable vertical sc
                        }}
                    >
                        <MDBox>
                            <MDTypography variant="h3" component="h2" mb={2}>
                                Message Your Advisor
                            </MDTypography>
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="h6" color="dark" size='small' mb={2} sx={{fontSize: '0.875rem'}}>
                                Reach out to your designated advisor! They're experts in growing your portfolio and
                                reduce
                                tax with financial products,
                                but <MDTypography
                                variant="h6"
                                color="primary"
                                component="span"
                                sx={{fontSize: '0.875rem'}} // Smaller font size for the "please note" text
                            >
                                please note:
                            </MDTypography> they don't handle
                                system issues (log a support
                                ticket using the ? icon) or
                                SARS-related or return queries (contact SARS directly). Let's focus on making your money
                                work for you!
                            </MDTypography>

                        </MDBox>
                        {status === 'idle' && (
                            <>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    placeholder="Type your message here..."
                                    variant="outlined"
                                    mb={2}
                                />
                                <MDBox display="flex" justifyContent="flex-end" mt={2}>
                                    <MDButton color="secondary" onClick={handleClose} sx={{mr: 1}}>
                                        Cancel
                                    </MDButton>
                                    <MDButton color="info" onClick={handleSend} disabled={!message.trim()}>
                                        Send
                                    </MDButton>
                                </MDBox>
                            </>
                        )}
                        {status === 'loading' && (
                            <MDBox display="flex" justifyContent="center" alignItems="center" height={200}>
                                <CircularProgress/>
                            </MDBox>
                        )}
                        {status === 'success' && (
                            <MDBox textAlign="center">
                                <MDTypography variant="body1" mb={2}>
                                    Message sent! Your advisor will contact you soon.
                                </MDTypography>
                                <MDButton color="info" onClick={handleClose}>
                                    Close
                                </MDButton>
                            </MDBox>
                        )}
                        {status === 'error' && (
                            <MDBox textAlign="center">
                                <MDTypography variant="body1" color="error" mb={2}>
                                    {errorMessage}
                                </MDTypography>
                                <MDButton color="info" onClick={() => setStatus('idle')}>
                                    Try Again
                                </MDButton>
                            </MDBox>
                        )}
                    </Box>
                </>
            </Modal>
        </>
    );
};

export default ChatToAdvisor;