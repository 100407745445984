import {useEffect, useState} from 'react';
import {Navigate, Outlet, useLoaderData, useLocation, useNavigate} from 'react-router-dom';
import gybpig from 'assets/images/logos/gybpig500x500.png'
// Import Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import Sidenav from 'components/Sidenav';
import {Icon} from '@mui/material';
// Import context
import {setMiniSidenav, setOpenConfigurator, useMaterialUIController} from 'context';

// Import the MainApp component
import MainApp from 'pages/main/MainApp'; // Adjust the import path based on your project structure
// Import routes configuration from approutes.js
import routes from 'routes/approutes';
import {PrivacyPolicyUser} from "../pages/main/PrivacyPolicyUser";
import {TermsAndConditionsUser} from "../pages/main/TermsAndConditionsUser";
import DashboardLayout from "pages/main/components/DashboardLayout";
import DashboardNavbar from "pages/main/components/DashboardNavbar";
import Footer from "pages/main/components/Footer";
import ProtectedRoute from "./ProtectedRoute";
import EmailVerificationAlert from "./components/EmailVerificationAlert";
import {getCsrfTokenFromCookies} from "../util/getCsrfTokenFromCookies";
import axios from "axios";
import SetupBasicProfile from "../pages/main/components/SetupBasicProfile"
import Configurator from "../components/Configurator";
import {ProfileProvider, useProfile} from '../pages/main/context/GlobalContext';
import {ClientDetail, clientDetailLoader} from "../pages/main/admin/ClientDetail";
import {adminTaxSummaryLoader, TaxSummary} from "../pages/main/TaxSummary";
import {adminFinancialAnalysisLoader, FinancialAnalysis} from "../pages/main/FinancialAnalysis";
import {ClientMessages, clientMessagesLoader} from "../pages/main/admin/cleintMessages";
import {ActivityLog, activityLogLoader} from "../pages/main/admin/activityLog";
import {Assumptions, assumptionslLoader} from "../pages/main/admin/Assumptions";

// Wrapper component for the dashboard layout
const DashboardLayoutWrapper = ({children}) => {
    const loaderData = useLoaderData();
    const [showModal, setShowModal] = useState(false);
    const [disableConfigurator, setDisableConfigurator] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); // Get the current route
    const {setProfileType} = useProfile();

    useEffect(() => {
        // Set profileType in the context from the loader data
        if (loaderData && loaderData.profileType) {
            setProfileType(loaderData.profileType);  // Store profileType in context
        }
    }, [loaderData, setProfileType]);

    useEffect(() => {
        if (loaderData.basicProfileSetup === false) {
            setShowModal(true);
            setDisableConfigurator(true)// Show modal if the profile is incomplete
            // Only navigate to "/app" if not already on "/app"
            if (location.pathname !== "/app") {
                navigate("/app", {replace: true});
            }
        }
    }, [loaderData.basicProfileSetup, navigate, location.pathname]);

    const closeModal = () => {
        setShowModal(false); // Function to close the modal
    };
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;

    const handleOnMouseEnter = () => {
        if (miniSidenav) {
            setMiniSidenav(dispatch, false);
        }
    };

    const handleOnMouseLeave = () => {
        if (!miniSidenav) {
            setMiniSidenav(dispatch, true);
        }
    };

    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    return (

        <DashboardLayout>
            <DashboardNavbar brand={gybpig}/>
            {/* Ensure layout is dashboard for displaying the dashboard components */}
            {layout === 'dashboard' && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brand={gybpig}
                        brandName="GrowYourBucks"
                        routes={routes}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                    {!disableConfigurator && (<Configurator/>)}

                    {showModal && (
                        <SetupBasicProfile open={showModal} onClose={closeModal}/>
                    )}
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="3.25rem"
                        height="3.25rem"
                        bgColor="white"
                        shadow="sm"
                        borderRadius="50%"
                        position="fixed"
                        right="2rem"
                        bottom="2rem"
                        zIndex={99}
                        color="dark"
                        sx={{cursor: 'pointer'}}
                        onClick={handleConfiguratorOpen}
                    >
                        <Icon fontSize="small" color="inherit">
                            settings
                        </Icon>
                    </MDBox>
                </>
            )}
            {loaderData.emailVerified === false && (<EmailVerificationAlert/>)}
            {children}
            <Footer/>
        </DashboardLayout>
    );
};
const generateNestedRoutes = () => {
    const nestedRoutes = [];

    routes.forEach(route => {
        // Check if the route has nested children
        if (route.collapse) {
            route.collapse.forEach(subRoute => {
                nestedRoutes.push({
                    path: subRoute.route.replace('/app/', ''),
                    element: subRoute.element,
                    loader: subRoute.loader || undefined,
                    action: subRoute.action || undefined,
                    children: subRoute.children || undefined, // Keep any nested children
                });
            });
        } else {
            nestedRoutes.push({
                path: route.route.replace('/app/', ''),
                element: route.element,
                loader: route.loader || undefined,
                action: route.action || undefined,
            });
        }
    });

    return nestedRoutes;
};

const mainRouterLoader = async ({request}) => {
    let csrfToken;
    try {
        csrfToken = await getCsrfTokenFromCookies();
    } catch (error) {
        console.error("Error retrieving CSRF token: ", error);
        // Return default values if the token cannot be retrieved
        return {
            emailVerified: false,
            basicProfileSetup: false,
        }
    }

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/getuserdata`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });

    if (response.data.phoneVerified === false) {
        const encodedPhone = encodeURIComponent(response.data.phone);
        window.location.href = `/verifyphone?phone=${encodedPhone}`;
        return new Promise(() => {
        });
    }
    const checkServiceProfile = await axios.get(`${process.env.REACT_APP_API_URL}/auth/serviceProfile`, {
        withCredentials: true,
        headers: {
            "x-csrf-token": csrfToken,
        },
    });
    let profileType
    if (checkServiceProfile.status === 200 && checkServiceProfile.data.message === 'No Service Profile found') {
        profileType = null;
    } else if (checkServiceProfile) {
        profileType = checkServiceProfile.data.type;

    } else {
        throw new Error("Service Profile check failed");
    }

    let taxResponse;
    let fnaResponse;
    if (response.data.basicProfileSetup === true) {
        fnaResponse = await axios.get(`${process.env.REACT_APP_API_URL}/clients/getFnaData`, {
            withCredentials: true,
            headers: {
                "x-csrf-token": csrfToken,
            },
        });
        taxResponse = await axios.get(`${process.env.REACT_APP_API_URL}/clients/getTaxData`, {
            withCredentials: true,
            headers: {
                "x-csrf-token": csrfToken,
            },
        });

    }
    const taxOptimised = taxResponse ? taxResponse.data.taxTotalOptimisationPercentage : 0;  // Fallback value (0 or null)
    const fnaOptimised = fnaResponse ? fnaResponse.data.totalFinancialNeedsPercentage : 0;
    if (response.status === 200 && response.data) {
        return {
            emailVerified: response.data.emailVerified,
            basicProfileSetup: response.data.basicProfileSetup,
            taxOptimised: taxOptimised,
            fnaOptimised: fnaOptimised,
            profileType,
        };
    } else {
        throw new Error("Failed to fetch user data");
    }
}


// Define the main route that uses the BaseLayout
const mainRoutes = [
    {
        path: 'app',
        element: (
            <ProfileProvider>
                <DashboardLayoutWrapper>
                    <ProtectedRoute>
                        <Outlet/>
                    </ProtectedRoute>
                </DashboardLayoutWrapper>
            </ProfileProvider>
        ),
        loader: mainRouterLoader,
        children: [
            {
                path: '',
                element: <MainApp/>,
                loader: mainRouterLoader,

            },
            {
                path: 'privacy-policy',
                element: <PrivacyPolicyUser/>,
            },
            {
                path: 'terms-and-conditions',
                element: <TermsAndConditionsUser/>,
            },
            {
                path: 'admin/client-list/client-details/:clientId',
                element: <ClientDetail/>,
                loader: clientDetailLoader,
                children: [
                    {
                        path: "tax",
                        element: <TaxSummary/>,
                        loader: adminTaxSummaryLoader,
                    },
                    {
                        path: "fna",
                        element: <FinancialAnalysis/>,
                        loader: adminFinancialAnalysisLoader,
                    },
                    {
                        path: "messages",
                        element: <ClientMessages/>,
                        loader: clientMessagesLoader,
                    },
                    {
                        path: "activity",
                        element: <ActivityLog/>,
                        loader: activityLogLoader,
                    },
                    {
                        path: "assumptions",
                        element: <Assumptions/>,
                        loader: assumptionslLoader,
                    },
                ],
            },
            ...generateNestedRoutes(),
        ],
    },
    {path: '*', element: <Navigate to="/app"/>}, // Redirect any unmatched routes to /app
];

export default mainRoutes;