// react-router-dom components
import {Link} from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MDBox";
import MKButton from "components/MDButton";
import MKTypography from "components/MDTypography";

function DefaultBackgroundCard({image, label = "", title, description, action}) {
    return (
        <Card
            sx={({
                     functions: {rgba, linearGradient},
                     palette: {black},
                     borders: {borderRadius},
                 }) => ({
                backgroundImage: `${linearGradient(
                    rgba(black.main, 0.5),
                    rgba(black.main, 0.5)
                )}, url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: borderRadius.xl,
            })}
        >
            <MKBox textAlign="center" pt={12} pb={3} px={3}>
                {label && (
                    <MKTypography variant="body2" color="white" textTransform="uppercase" mb={2}>
                        {label}
                    </MKTypography>
                )}
                <MKTypography variant="h3" color="white">
                    {title}
                </MKTypography>
                <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
                    {description}
                </MKTypography>
                {action.type === "internal" ? (
                    <MKButton component={Link} to={action.route} color="white" size="small" sx={{my: 2}}>
                        {action.label}
                    </MKButton>
                ) : action.type === "scroll" ? (
                    <MKButton
                        color="primary"
                        size="small"
                        sx={{my: 2}}
                        onClick={action.action}
                    >
                        {action.label}
                    </MKButton>
                ) : (
                    <MKButton
                        component="a"
                        href={action.route}
                        target="_blank"
                        rel="noreferrer"
                        color="white"
                        size="small"
                        sx={{my: 2}}
                    >
                        {action.label}
                    </MKButton>
                )}
            </MKBox>
        </Card>
    );
}

// Typechecking props for the DefaultBackgroundCard
DefaultBackgroundCard.propTypes = {
    image: PropTypes.string.isRequired,
    label: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    action: PropTypes.shape({
        type: PropTypes.oneOf(["external", "internal", "scroll"]).isRequired,
        route: PropTypes.string,
        label: PropTypes.string.isRequired,
        action: PropTypes.func,
    }).isRequired,
};

export default DefaultBackgroundCard;