import {useLoaderData} from "react-router-dom"; // Import the useLoaderData hook
import bgImage from "../../assets/images/back9.png";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import BasicLayout from "./components/BasicLayout";

export const ResetRequested = () => {
    const {type} = useLoaderData();
    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                        Reset Link Sent
                    </MDTypography>
                </MDBox>

                <MDBox pt={3} pb={3} px={3}>
                    {type === 'email' ? (
                        <MDTypography display="block" variant="h5" color="text" my={1}>
                            Your reset link has been emailed to you. Please check your email and click on the link to
                            reset your password. Also make sure to check your spam folder.
                        </MDTypography>
                    ) : type === 'phone' ? (
                        <MDTypography display="block" variant="h5" color="text" my={1}>
                            Your reset link has been sent via SMS. Please check your phone and click on the link to
                            reset your password.
                            If you cannot click on the link, please copy and paste it into your browser.
                        </MDTypography>
                    ) : (
                        <MDTypography display="block" variant="h5" color="text" my={1}>
                            Please check your email or SMS for the reset link.
                        </MDTypography>
                    )}
                </MDBox>
            </Card>

        </BasicLayout>
    );
};


export const resetRequestedLoader = ({request}) => {
    const url = new URL(request.url);
    const type = url.searchParams.get('type');
    return {type};

};