import FacebookIcon from "@mui/icons-material/Facebook";
import CenteredFooter from "./CenteredFooter";
import InstagramIcon from "@mui/icons-material/Instagram";

export const Footer = () => {

    const links = [
        {href: "/termsAndConditions", name: "Terms and Conditions"},
        {href: "/privacyPolicy", name: "Privacy Policy"},

    ];
    const socials = [
        {icon: <FacebookIcon fontSize="small"/>, link: "https://www.facebook.com/profile.php?id=61567124342187"},
        {icon: <InstagramIcon fontSize="small"/>, link: "https://www.instagram.com/growyourbucksapp"},

    ];

    return <CenteredFooter links={links} socials={socials}/>;
}

