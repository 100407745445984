import React, {useEffect, useState} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {useMaterialUIController} from "context";
import InputLabel from "@mui/material/InputLabel";
import {getCsrfTokenFromCookies} from "../../../../util/getCsrfTokenFromCookies";
import axios from "axios";
import {useRevalidator} from "react-router-dom";


const SUBSCRIPTION_TIERS = {
    MINTM: {
        lookup_key: 'mint_monthly',
        name: 'Mint Plan Monthly',
        description: 'R5000 Monthly subscription that gives you 20 credits (10 Standard/6 Premium Mandates) a month @ R250 per credit',
        icon: 'payments'
    },
    VAULTM: {
        lookup_key: 'vault_monthly',
        name: 'Vault Plan Monthly',
        description: 'R6750 Monthly subscription that gives you 30 credits (15 Standard/10 Premium Mandates) a month @ R225 per credit',
        icon: 'card_travel'
    },
    EMPIREM: {
        lookup_key: 'empire_monthly',
        name: 'Empire Plan Monthly',
        description: 'R8500 Monthly subscription that gives you 40 credits (20 Standard/13 Premium Mandates) a month @ R212.50 per credit',
        icon: 'account_balance'
    },
    MINTQ: {
        lookup_key: 'mint_quarterly',
        name: 'Mint Plan Quarterly',
        description: 'R12750 Quarterly subscription that gives you 20 credits (10 Standard/6 Premium Mandates) a month @ R212.50 per credit',
        icon: 'payments'
    },
    VAULTQ: {
        lookup_key: 'vault_quarterly',
        name: 'Vault Plan Quarterly',
        description: 'R18450 Quarterly subscription that gives you 30 credits (15 Standard/10 Premium Mandates) a month @ R205 per credit',
        icon: 'card_travel'
    },
    EMPIREQ: {
        lookup_key: 'empire_quarterly',
        name: 'Empire Plan Quarterly',
        description: 'R24000 Quarterly subscription that gives you 40 credits (20 Standard/13 Premium Mandates) a month @ R200 per credit',
        icon: 'account_balance'
    },
};

export const SubscriptionForm = () => {
    const [clientSecret, setClientSecret] = useState(null);
    const {revalidate} = useRevalidator();
    const elements = useElements();
    const [controller] = useMaterialUIController();
    const {darkMode} = controller;

    const stripe = useStripe();
    const [selectedTier, setSelectedTier] = useState(SUBSCRIPTION_TIERS.MINTM);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const saveSubscription = async (subscriptionId, lookupKey) => {
        const csrfToken = await getCsrfTokenFromCookies();
        await axios.post(
            `${process.env.REACT_APP_API_URL}/system/saveSubscription`,
            {subscriptionId, lookup_key: lookupKey},
            {withCredentials: true, headers: {"x-csrf-token": csrfToken}}
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const {error: elementsSubmitError} = await elements.submit();
            if (elementsSubmitError) {
                setError(elementsSubmitError.message);
                setLoading(false);
                return;
            }
            // Create the subscription
            const csrfToken = await getCsrfTokenFromCookies();
            const setupResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/system/createSubscription`,
                {
                    lookup_key: selectedTier.lookup_key
                },
                {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );

            const {subscriptionId, clientSecret} = setupResponse.data;

            const {error: confirmError} = await stripe.confirmPayment({
                elements,
                clientSecret,
                redirect: 'if_required',
            });

            if (confirmError) {
                setError(confirmError.message);
                setLoading(false);
                return;
            }

            await saveSubscription(subscriptionId, selectedTier.lookup_key);
            await revalidate();

        } catch (err) {
            setError(err.response?.data?.error || 'Failed to process subscription');
            console.error('Error:', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        const initialize = async () => {
            const csrfToken = await getCsrfTokenFromCookies();
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/system/createSubscription`,
                {lookup_key: SUBSCRIPTION_TIERS.MINTM.lookup_key},
                {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );
            setClientSecret(response.data.clientSecret);
        };
        initialize();
    }, []);

    if (!clientSecret) return null;
    elements.update({clientSecret});
    return (

        <>
            <Card sx={{marginBottom: 3}}>
                <MDBox p={3}>
                    <MDTypography variant="h4" fontWeight="medium" mb={3}>
                        You don't have any active subscriptions yet
                    </MDTypography>
                    <MDTypography variant="h5" fontWeight="medium" mb={3}>
                        Choose Your Subscription Plan
                    </MDTypography>

                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            {Object.values(SUBSCRIPTION_TIERS).map((tier) => (
                                <Grid item xs={12} md={4} key={tier.lookup_key}>
                                    <Card
                                        onClick={() => setSelectedTier(tier)}
                                        sx={{
                                            height: "100%",
                                            cursor: "pointer",
                                            border: tier.lookup_key === selectedTier.lookup_key ? 2 : 0,
                                            borderColor: "info.main",
                                            backgroundColor: darkMode ? "transparent" : "grey.100",
                                        }}
                                    >
                                        <MDBox p={3} textAlign="center">
                                            <MDBox mb={2}>
                                                <Icon fontSize="large" color="info">
                                                    {tier.icon}
                                                </Icon>
                                            </MDBox>
                                            <MDTypography variant="h5" fontWeight="bold">
                                                {tier.name}
                                            </MDTypography>
                                            <MDTypography variant="body2" color="text">
                                                {tier.description}
                                            </MDTypography>
                                        </MDBox>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                        <MDBox mt={4}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <MDBox mb={2}>
                                        <InputLabel
                                            sx={{
                                                mb: 1,
                                                color: darkMode ? "white" : "text.primary"
                                            }}
                                        >
                                            Card Details
                                        </InputLabel>
                                        <MDBox
                                            sx={{
                                                p: 2,
                                                border: "1px solid",
                                                borderColor: darkMode ? "grey.600" : "grey.300",
                                                borderRadius: 1,
                                                backgroundColor: darkMode ? "transparent" : "white",
                                            }}
                                        >
                                            <PaymentElement
                                                id="payment-element"
                                                options={{
                                                    layout: {type: 'tabs', defaultCollapsed: false},
                                                    defaultValues: {
                                                        billingDetails: {
                                                            email: email
                                                        }
                                                    },
                                                    appearance: {
                                                        theme: darkMode ? 'night' : 'stripe',
                                                        variables: {
                                                            colorPrimary: '#1A73E8',
                                                            colorBackground: darkMode ? '#1a2035' : '#ffffff',
                                                            colorText: darkMode ? '#ffffff' : '#424770',
                                                            colorDanger: '#9e2146',
                                                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                                            spacingUnit: '4px',
                                                            borderRadius: '4px',
                                                        },
                                                    }
                                                }}
                                            />
                                            <MDBox mt={2} display="flex" justifyContent="flex-end">
                                                <img
                                                    src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg"
                                                    alt="Powered by Stripe"
                                                    width="100"
                                                />
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>
                                </Grid>
                            </Grid>

                            {error && (
                                <MDBox mt={2}>
                                    <MDTypography variant="caption" color="error" fontWeight="light">
                                        {error}
                                    </MDTypography>
                                </MDBox>
                            )}

                            <MDBox mt={4}>
                                <MDButton
                                    type="submit"
                                    variant="gradient"
                                    color="info"
                                    size="large"
                                    fullWidth
                                    disabled={!stripe || loading}
                                >
                                    {loading ? 'Processing...' : `Subscribe to ${selectedTier.name}`}
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </form>
                </MDBox>
            </Card>
        </>

    );
};
