/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useMemo, useRef, useState} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {Line} from "react-chartjs-2";
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../../../components/MDBox";
import MDTypography from "../../../../../../components/MDTypography";

// Material Dashboard 2 PRO React helper functions
import gradientChartLine from "../../../../../../assets/theme/functions/gradientChartLine";

// GradientLineChart configurations
import configs from "./configs";

// Material Dashboard 2 PRO React base styles
import colors from "../../../../../../assets/theme/base/colors";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

function GradientLineChart({
                               title = "",
                               description = "",
                               height = "19.125rem",
                               chart
                           }) {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        const chartElement = chartRef.current;

        if (!chartElement) return;

        const chartDatasets = chart.datasets
            ? chart.datasets.map((dataset) => ({
                ...dataset,
                tension: 0,
                pointRadius: 0,
                borderWidth: 4,
                borderColor: colors[dataset.color]
                    ? colors[dataset.color || "dark"].main
                    : colors.dark.main,
                fill: true,
                maxBarThickness: 6,
                backgroundColor: gradientChartLine(
                    chartElement.ctx,
                    colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main
                ),
            }))
            : [];

        setChartData(configs(chart.labels || [], chartDatasets));
    }, [chart]);

    const {data, options} = useMemo(() => chartData, [chartData]);

    const renderChart = (
        <MDBox py={2} pr={2} pl={2}>
            {title || description ? (
                <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
                    <MDBox mt={0}> {/* Adjusted margin-top */}
                        {title && <MDTypography variant="h4">{title}</MDTypography>}
                        <MDBox mb={2}>
                            <MDTypography component="div" variant="button" color="text">
                                {description}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            ) : null}
            <MDBox height={height}>
                <Line
                    ref={chartRef}
                    data={{
                        labels: data?.labels || [],
                        datasets: data?.datasets || [],
                    }}
                    options={options}
                    redraw
                />
            </MDBox>
        </MDBox>
    );

    return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Typechecking props for the GradientLineChart
GradientLineChart.propTypes = {
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default GradientLineChart;