import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import MDTypography from 'components/MDTypography';
import React from "react";

export const PrivacyPolicyUser = () => {

    return (
        <Container maxWidth="md">
            <MDBox my={4} pt={15} mt={-3}>
                <MDTypography variant="h4" gutterBottom>
                    Privacy Policy
                </MDTypography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MDTypography variant="body2" color="textSecondary">
                            PLEASE READ THIS PRIVACY NOTICE CAREFULLY.
                        </MDTypography>
                    </Grid>

                    <Grid item xs={12}>
                        <MDTypography variant="body1" paragraph color="text">
                            We are committed to protecting your privacy as a user (referred to as "User", "you" or
                            "your"),
                            and we take our responsibility regarding the security of your Personal Information (defined
                            below) very seriously. We will be clear and transparent about the Personal Information we
                            are collecting and what we will do with that Personal Information.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            This Privacy Policy describes the types of Personal Information we collect on the
                            GrowYourBucks Website with URL: <a
                            href="https://growyourbucks.com">https://growyourbucks.com</a> ("the
                            Website") and/or GrowYourBucks mobile application ("GrowYourBucks App"), blog, social media
                            pages, or one of our other products or services, all of which are part of the GrowYourBucks
                            Platform ("the Platform").
                        </MDTypography>
                    </Grid>

                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            CONTENTS:
                        </MDTypography>
                        <MDTypography variant="body1" color="text">
                            1. Who is responsible for processing your Personal Information?
                            <br/>
                            2. What Personal Information do we collect?
                            <br/>
                            3. Why and how do we use your Personal Information?
                            <br/>
                            4. How do we protect and manage your Personal Information (including international transfers
                            and
                            retention period)?
                            <br/>
                            5. Who do we share your Personal Information with?
                            <br/>
                            6. Your rights and choices
                            <br/>
                            7. Cookies
                            <br/>
                            8. Links to other websites
                            <br/>
                            9. Children
                            <br/>
                            10. Updates to this Privacy Policy
                            <br/>
                            11. How to contact us?
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            1. Who is responsible for processing your Personal Information?
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            Domivi (Pty) Ltd, registration number: 2023 / 986526 / 07 (referred to as "GrowYour Bucks",
                            "we", "us" or "our") is the System Administrator and the "responsible person" (i.e., the
                            organisation responsible) for all Personal Information that is collected and used via the
                            Platform for purposes of data privacy laws, principles and regulations including the
                            Protection
                            of Personal Information Act 4 of 2013 ("POPIA"), (collectively "Data Privacy Law").
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            2. What Personal Information do we collect?
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We may ask for and collect your Personal Information (either directly through your use of
                            the Platform or when you communicate with us in any other way, or indirectly through our
                            third party partners and providers) in a number of ways to provide you with the products
                            and services that you request via our Service Providers. We may also collect information
                            from you automatically when you visit our Platform - for more information, please refer to
                            our Cookie Policy available under Section 7 below.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            "Personal Information" has the meaning given in Chapter 1 of the POPIA and includes,
                            but is not limited to any information or opinion relating to you which allows us to identify
                            you, such as your name, contact number, email address, details of products or services
                            you have purchased, tax information, financial information, payment details and
                            information about your access to our Platforms.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            Specifically we may (either directly or indirectly) collect the following categories of
                            Personal
                            Information:
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - First and last names, unique identifiers such as your identity number, date of birth,
                            contact number and email address (this is mandatory information which we require
                            when opening a user account on the Platform ("User Account"));
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Other information such as your residential address and income tax number;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Financial and related information such as your gross income, tax information, information
                            about the number of dependents on your medical aid, consumer credit information (the meaning
                            ascribed to it in section 70 of the National Credit Act, No. 34 of 2005, as amended from
                            time to
                            time, including any regulations made under the Act ("NCA")), and insurance portfolio
                            information
                            provided by you on the Platform or collected from third party Verification Agencies, Astute
                            Financial Services Exchange and/or such information shared back on the Platform by a Service
                            Provider or a Service Representative (the person directly offering an authorised service to
                            a
                            User under the supervision, management and authorisation of the relevant Service Provider);
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Information shared with GrowYourBucks, a Service Provider or Service Representative in
                            free text fields for providing reviews, feedback on your experience of the Platform or
                            Service
                            Provider or Service Representative, or when completing customer surveys. Users may also
                            choose to share personal information with our support team;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Information you provide about yourself and any preference in your User Account;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Biometric information from your Identity Document and photo (selfie) for facial
                            recognition. We will never collect special personal information about you without your
                            explicit consent, unless freely shared by you;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Information about your purchases of products and services from us or from our Service
                            Providers;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Payment information, such as your transaction details, if you purchase something directly
                            on our Platform. Note that we do not receive or store Credit / Debit Card Details which is
                            hosted and managed by our Payment Processing Service Provider in its PCI compliant
                            data centre;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Information about your use of the Platform;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Communications with us or directed to us via letters, emails, chat services, calls and
                            social media;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - When you have sent a referral link to a friend, colleague or family member or have been
                            referred to GrowYourBucks by a friend, we may be able to identify the identity of the person
                            who you referred or who made the referral;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Any information you share in a social media post where you tag, use a hashtag, link, or
                            otherwise direct the post to our attention;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Your location where the IP address of your computer or device is used to determine your
                            geographic location for Platform accessibility;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - Internet or other similar network activity depending on your permissions. For example
                            browsing identifiers, advertising ID, cookie identifiers, information on your interaction
                            with the
                            website or advertisement.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            You can withdraw your consent for the processing of any of the Personal Information at
                            any time on the Platform or by contacting us using the contact information in Section 11
                            below. If you provided GrowYourBucks with consent to process Special Personal
                            Information or you’re unsure about how to withdraw consent or about what information
                            you did provide, please contact us using the contact information in Section 11 below.
                            Depending on the type of information, withdrawing consent may not prohibit you from
                            using the GrowYourBucks App, but please note that some functionality, products and
                            services, could be disabled or may not function properly.
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            3. Why and how do we use your Personal Information?
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            Your personal Information may be used for the following purposes:
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            3.1. Provide features of the Platform and the products and services you request:
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            We use the Personal Information you give us to provide the Platform features and services
                            you request, including:
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            - To create and set up your User Account;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - To connect you with a Service Provider and Service Representative according to the
                            products or services you have shown an interest in or may potentially be interested in;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - To fulfill any request for services that you place on the Platform, including to
                            communicate with you about the service request and to process information for our internal
                            accounting, billing and audit purposes;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - To track your financial wellness and/or credit rating, we will collect and store this
                            information so that you can review it on the Platform. We may also use this information to
                            calculate further information about your financial wellness, tax efficiency and credit
                            rating and share feedback, service or product information with you so that this can be
                            provided to you as part of the Platform features.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            3.2. Communicate information about Platform features, products and services provided through
                            our Service Providers and for other promotional purposes:
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            With your consent, we will use your Personal Information to provide information and
                            recommendations that we believe is of interest to you, prior to, during, and after your
                            interaction with us, including marketing communications and news concerning our Platform
                            functionalities, products and services offered by our Service Providers, events and other
                            promotions. You can opt-out at any time after you have given consent to such communications.
                            In providing tailored value adding promotional materials to you, GrowYourBucks may use the
                            Personal Information collected through your use of the Platform, such as the information you
                            have shared on the Platform, any information and feedback received about you from our
                            Service Providers, Service Representatives, Verification Agencies and Astute Financial
                            Services Exchange.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            3.3. Customer service communications:
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We use your information to manage our relationship with you as our customer and to improve
                            our services and enhance your experience with us and our Service Providers. From time to
                            time, we may also conduct customer surveys to gauge satisfaction with our Platform and the
                            features and the services and products from our Service Providers that you have access to
                            via the Platform.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            3.4. Administrative or legal purposes:
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We use your Personal Information to operate our business, including for statistical and
                            marketing analysis, system testing or to diagnose technical and service problems,
                            maintenance and development of our Platform, or in order to deal with a dispute or claim. We
                            may also perform data analysis based on the information we collect about you for statistical
                            and marketing analysis purposes - for example, we may use information about how users
                            navigate on the Platform to better understand the best ways to organise and present Platform
                            features.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            3.5. Security, crime prevention/detection and legal purposes:
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We may use your Personal Information to verify your information and identity, and to protect
                            against and prevent fraud and other unlawful activities. We may also share your Personal
                            Information with government authorities or enforcement or regulating authorities for
                            compliance with legal requirements, or as otherwise required or permitted by applicable Data
                            Privacy Law.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            3.6. Other purposes:
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We may also use your Personal Information in other ways, and where we do so, we will provide
                            specific notice at the time of collection and obtain your consent unless otherwise permitted
                            by applicable Data Privacy Law. We will only process your Personal Information where we have
                            a legal basis to do so, which will depend on the reasons for which we have collected and
                            need to use your Personal Information. In most cases, we will need to process your Personal
                            Information so that we can enter into agreements with you under our Terms (when you request
                            or enquire about a certain product or service) and to fulfil the provision of the Platform
                            functionality and features and access to Service Provider services or products to you. Our
                            processing of your Personal Information for the above purposes is done pursuant to the
                            following legal basis:
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - to comply with a legal obligation;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - the processing is necessary for the performance of an agreement with you, such as
                            providing you with the Platform features and access to Service Representatives offering
                            Service Provider Products and Services via the Platform;
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - if it is in our legitimate interest to do so as a business (for example, for
                            administrative purposes or to improve the functionality of our Platform);
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - where you have consented to us using your Personal Information (for example, for marketing
                            related purposes);
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - where you have made such information public; and
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            - to protect your interests or those of another person.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            Where we receive your Personal Information as part of providing system features to you based
                            on an agreement, we require such Personal Information to be able to carry out the terms of
                            the agreement. Without that necessary Personal Information, we and the Service Providers
                            will not be able to provide you with any features, products or services. Personal
                            Information including about your religious or philosophical beliefs, race or ethnic origin,
                            health, biometric information or criminal behaviour are considered “Special Personal
                            Information” under applicable Data Privacy Law. We will process any such information only if
                            you have given your explicit consent, or if it is necessary (for instance, if you request
                            special assistance), or if the Special Personal Information has been manifestly made public
                            by you (example if you post about your health on one of our social media posts), or
                            otherwise in compliance with applicable Data Privacy Law.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            You may withdraw your consent for GrowYourBucks processing your Personal or Special Personal
                            Information at any time. As described more fully below, you may always contact us at the
                            details in Section 11 with any questions or requests about your Personal Information.
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            4. How do we protect and manage your Personal Information (including international transfers
                            and retention period)?
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            4.1. Encryption and security
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We follow strict security procedures according to best practices and industry standards in
                            the storage, protection and disclosure of your Personal information, which are designed to
                            protect against misuse, unauthorised access, modification or disclosure and accidental loss,
                            destruction or damage. The data you provide to us is protected using SSL/TLS (Secure Socket
                            Layer) technology. SSL is the industry standard method of encrypting Personal information
                            and card details so that it can be securely transferred over the internet.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            4.2. International transfers of your Personal information
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            The Personal Information that GrowYourBucks collects from users are stored in The Republic
                            of South Africa and/or the European Union and/or the United States of America may have
                            Geo-redundancy capabilities in other countries as well (Geo-redundancy is the distribution
                            of mission-critical components or infrastructures, such as servers, across multiple data
                            centres that reside in different geographic locations. Geo-redundancy acts as a safety net
                            in case your primary site fails or in the event of a disaster or an outage that impacts an
                            entire region) and might also be shared with third party data recipients (for example,
                            e-commerce, ticketing services and logistics providers and customer support providers) that
                            are not located in The Republic of South Africa. Therefore the Personal Information that you
                            provide to GrowYourBucks may be transferred internationally. We require that all third party
                            data recipients (including our Service Providers and other third parties) process your
                            information in a secure manner and in accordance with the POPIA and other applicable Data
                            Privacy Law. If you have any further questions about this, please contact us at the details
                            provided in Section 11 below.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            4.3. Retention of your Personal Information
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We will not retain your information longer than it is necessary to fulfil the purposes for
                            which it is being processed. To determine the appropriate retention period, we consider the
                            amount, nature and sensitivity of the Personal Information, the purposes for which we
                            process it, and whether we can achieve those purposes through other means. We also consider
                            the periods for which we might need to retain Personal Information in order to meet our
                            legal obligations, or to deal with complaints and queries, and to protect our legal rights
                            in the event of a claim being made. In general, this means that we will likely keep your
                            Personal Information for as long as your User Account is open. Following closure of your
                            User Account, however, we may still retain a limited portion of your Personal Information so
                            that we can maintain a continuous relationship with you if and when you want to make use of
                            the Platform again, and to comply with our internal processes and legal obligations.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            When we no longer need your Personal Information, we will securely delete or destroy it. We
                            will also consider if and how we can minimise over time the Personal Information that we
                            use, and we can fully anonymise your Personal Information so that it can no longer be
                            associated with you or identify you, in which case we may use that information without
                            further notice to you.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            The security of your personal information is important to us, but no method of transmission
                            over the Internet, or method of electronic storage, is 100% secure. While we therefore
                            strive to use commercially acceptable means to protect your personal information, we cannot
                            guarantee its absolute security.
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            5. Who do we share your Personal Information with?
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            We may share your Personal Information with the following people for the purposes as
                            described in this Privacy Policy:
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • where you have requested, selected or enquired about specific products or services, your
                            Personal Information will be shared with the relevant Service Representative from one of our
                            Service Providers.
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • for quality, supervision and management purposes your Personal Information may be shared
                            with the Service Representative’s manager or supervisor linked to the Service Provider and
                            also to third parties on Administrative Level (for example with the registered Financial
                            Service Provider such as the Discovery Group, Banks, Credit or Home Loan Providers,
                            Resellers and GrowYourBucks staff and support team).
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • if you subscribe to or purchase any services or products offered by Service Providers you
                            may be a customer of both GrowYourBucks and the Service Provider and the Service Provider
                            may collect and share information about you such as your contact information, billing
                            details, financial, health and credit information. We are not responsible for third parties’
                            use of your personal Information where such use is permitted for their own purposes. Please
                            ensure to consult their privacy policies for further information;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • for identity verification purposes we may share your Personal Information with
                            Verification Agencies, including if explicit consent was provided by you, your Special
                            Personal information, such as your biometrics;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • for soft enquiries to pre-approve you for potential services or products which may be of
                            interest to you, your Personal Information may be shared with Verification agencies to do
                            credit checks;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • with Astute Financial Services Exchange to obtain your insurance portfolio information so
                            that the Service Representative will have a more holistic view of your portfolio for better
                            financial advice on additional or new products you may want to include in your portfolio;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • if you joined GrowYourBucks via a referral link sent to you by one of your contacts (such
                            as a family member, friend, or colleague), we may let that contact know that you have
                            created a GrowYourBucks account, and provide them with a referral token;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • third party social media platforms in various circumstances, including where:
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            - you are able to access third party social media services through our Platform or before
                            coming to our Platform;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            - third party social media sites have placed cookies or pixels on our website;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            - you post or otherwise participate in or direct your social media posts to us via hashtag,
                            tag, link, etc. and
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            - we use social media plugins on our Platform (for example a “share” or “like” button) and
                            your use of these plugins may result in the disclosure of certain of your Personal
                            Information to the social media platform in question, and possibly presented on your social
                            media profile, to be shared with others in your network - however we will only share your
                            Personal Information with these social media platforms if you have provided your express
                            consent for us to do so. Please refer to the privacy policy of those third-party social
                            media platforms to find out more about these practices;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • other companies, contractors, employees or agents that assist us in providing services to
                            you for example in providing support ticketing, legal services, debt collection,
                            administration services, customer services and information technology support;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • other companies, contractors, employees or agents in connection with our marketing
                            strategies, or marketing platform providers;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • government authorities, regulatory bodies, law enforcement bodies and regulators for
                            compliance with legal requirements, or where otherwise required by applicable Data Privacy
                            Law;
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • our legal and professional advisors in order to enforce and protect our legal rights in
                            relation to our agreement with you; and
                        </MDTypography>

                        <MDTypography variant="body1" paragraph color="text">
                            • we may also transfer your Personal Information to potential buyers in the event that we
                            sell or transfer all or a part of our business or assets (including in the event of a
                            reorganisation or dissolution/liquidation), under strict non-disclosure restrictions, and
                            solely in order to allow a buyer to make an informed decision.
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            6. Your Rights and Choices
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            Under certain circumstances, by applicable Data Privacy Law you may have the right to:
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            6.1. Transparency
                            <br/>
                            You have the right to request information about whether we hold Personal Information about
                            you, and if so, what that information is and why we are holding/using it.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            6.2. Access
                            <br/>
                            You may request access to your Personal Information. This enables you to receive a copy of
                            the Personal Information we hold about you and to check that we are lawfully processing it.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            6.3. Correction
                            <br/>
                            You have the right to request correction of the Personal Information that we hold about you.
                            This enables you to have any incomplete or inaccurate information we hold about you
                            corrected.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            6.4. Erasure
                            <br/>
                            You can request erasure of your Personal Information. This enables you to ask us to delete
                            or to remove Personal Information where there is no good reason for us continuing to process
                            it. You also have the right to ask us to delete or to remove your Personal Information where
                            you have exercised your right to object to the processing thereof.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            6.5. Automated Decision Making and Profiling
                            <br/>
                            You may also object to automated decision making including profiling, that means not to be a
                            subject of any automated decision making by us using your Personal Information or profiling
                            you.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            6.6. Restriction of Processing
                            <br/>
                            You have the right to request a restriction to processing of your Personal Information. This
                            enables you to ask us to suspend the processing of your Personal Information (for example if
                            you want us to establish its accuracy or the reason for processing it).
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            6.7. Transfer
                            <br/>
                            Request transfer of your Personal Information in an electronic and structured form to you or
                            to another party. This enables you to take your data from us and to be able to transfer your
                            data to another party in an electronically usable format.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            6.8. Withdraw Consent
                            <br/>
                            In the limited circumstances where you may have provided your consent to the collection,
                            processing and transfer of your Personal Information for a specific purpose, you have the
                            right to withdraw your consent for that specific processing at any time by contacting us
                            using the details in Section 11 below. Once we have received notification that you have
                            withdrawn your consent, we will no longer process your information for the purpose or
                            purposes to which you originally agreed, unless we have another property and legitimate
                            basis for doing so.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            If you wish to exercise your rights related to your Personal Information (including the
                            rights set out above), please contact the GrowYourBucks support team using the details under
                            Section 11.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We are entitled to charge a reasonable fee for access and transfer of your Personal
                            Information or we may decline such request where permitted by applicable Data Privacy Law.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We may need to request specific information from you to help us to confirm your identity and
                            ensure your right to access the information. This is a security measure to ensure that
                            personal data is not disclosed to any person who has no right to receive it and to prevent
                            unauthorised modification of your information.
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            You also have the right to lodge a complaint about our processing of your Personal
                            Information with the Information Regulator by visiting their website at:
                            https://inforegulator.org.za or send them an email:
                            <br/>
                            Protection of Personal Information Act: POPIAComplaints@inforegulator.org.za
                            <br/>
                            Promotion of Access to Information Act: PAIAComplaints@inforegulator.org.za
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            7. Cookies
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            “Cookies” are files with a small amount of data stored on your device that includes
                            information about your device and which may include an anonymous unique identifier. You have
                            certain options with respect to the use of cookies and other tracking technologies on our
                            Website, including for the purposes of online interest-based advertising. You can instruct
                            your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
                            you do not accept cookies, you may not be able to use some portions of our Website.
                        </MDTypography>
                    </Grid>

                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            8. Links to other websites
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            Our Platform may provide links to other websites for your convenience and information. These
                            websites operate independently from us. If you visit any website linked to our Platform, you
                            are subject to that website’s own privacy policies. Linked websites may have their own
                            privacy notices or policies which we strongly suggest you review as well. To the extent that
                            any linked website is not owned or controlled by us, we are not responsible for their
                            content, any use of the website, or the privacy practices of the websites.
                        </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            9. Children
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            Users must be 18 years or older. Individuals are not allowed to sign up for a User Account
                            on the Platform without verifying that they are 18 years of age or older. To the extent that
                            we identify any User that appears to be below the age of 18 years, we will perform the
                            reasonable and necessary verifications.
                        </MDTypography>
                    </Grid>

                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            10. Updates to this Privacy Policy
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            GrowYourBucks may revise and update this Privacy Policy at any time in its sole discretion
                            by posting an updated Privacy Policy on the Platform and for which you will receive a
                            notification. All such changes are effective immediately when posted to the Platform and
                            apply to all access to and use of the Platform directly thereafter. You are advised to
                            review this Privacy Policy periodically for any changes.
                        </MDTypography>
                    </Grid>

                    <Grid item xs={12}>
                        <MDTypography variant="h5" fontWeight="medium" gutterBottom>
                            11. How to contact us?
                        </MDTypography>
                        <MDTypography variant="body1" paragraph color="text">
                            We welcome questions and comments about this Privacy Policy and our privacy practices. If we
                            receive a complaint from you about the way we handle your Personal Information, we will
                            investigate and determine what reasonable action we may take to resolve the complaint. We
                            aim to resolve all complaints in a timely manner and will provide feedback to you within a
                            reasonable time and may request that you provide us with more information to assist us with
                            our investigation into the matter.
                            <br/>
                            If you wish to provide feedback or if you have questions or concerns or wish to exercise
                            your rights related to your Personal Information, our Information Officer is Hennie Ferreira
                            and you may contact the GrowYourBucks support team by logging a support ticket on the
                            Platform by navigating to ‘support’-‘bugs’-‘issue/error’.
                        </MDTypography>
                    </Grid>


                </Grid>
            </MDBox>
        </Container>
    );
};
