import React from 'react';
import MongoDBDashboard from "./components/MongoDBDashboard";

const Analytics = () => {

    return (
        <div className="w-full min-h-screen p-4 space-y-4">
            <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold">Analytics Dashboard</h1>
            </div>

            <MongoDBDashboard
                dashboardId="e222fbad-8c88-47fc-865c-e3707ccd546e"
                baseUrl="https://charts.mongodb.com/charts-growyourbucksproduction-xpwmnqk"
                theme="light"
                className="mt-4"

            />
        </div>
    );
};

export default Analytics;