// ProtectedRoute.js
import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({children}) => {
    const csrfToken = Cookies.get('csrfToken');
    const location = useLocation();


    if (!csrfToken) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    const [, csrfTimestamp] = csrfToken.split('.');
    const currentTime = Math.floor(Date.now() / 1000);

    if (!csrfTimestamp || currentTime > parseInt(csrfTimestamp, 10)) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }
    return children;
};

export default ProtectedRoute;