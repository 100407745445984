import * as Sentry from "@sentry/react";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,} from "react-router-dom";
import {useEffect} from "react";


Sentry.init({
    dsn: "https://ad095257150845df2a01eb2aac38dd93@o4507933092085760.ingest.de.sentry.io/4508138236084304",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    enabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
    autoSessionTracking: true, // Ensure this is set
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});