import React from 'react';
import {SubscriptionForm} from './components/SubscriptionForm';
import axios from "axios";
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";
import {useLoaderData} from "react-router-dom";
import {ManageSubscription} from "./components/manageSubscription";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";


const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

export const SubscriptionPage = () => {
    const loaderData = useLoaderData();

    return (
        <>
            <Elements stripe={stripePromise}>
                {!loaderData.subscriptionActive ? (
                    <SubscriptionForm isRedirectFromStripe={loaderData.isRedirectFromStripe}/>

                ) : (

                    <ManageSubscription
                        cardLast4={loaderData.subscriptionDetails.cardLast4}
                        cardExpiry={loaderData.subscriptionDetails.cardExpiry}
                        plan={loaderData.subscriptionDetails.plan}
                        nextBillingDate={loaderData.subscriptionDetails.nextBillingDate}
                        creditSum={loaderData.subscriptionDetails.creditSum}
                        active={loaderData.subscriptionActive}
                    />
                )}
            </Elements>
        </>
    );
};

export const subscriptionPageLoader = async () => {
    const csrfToken = await getCsrfTokenFromCookies();
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/system/checkSubscription`,
        {
            withCredentials: true,
            headers: {
                "x-csrf-token": csrfToken,
            },
        }
    );
    if (response.data.active) {
        return {
            subscriptionActive: true,
            subscriptionDetails: response.data.subscriptionDetails,

        }
    } else {
        return {
            subscriptionActive: false,

        }
    }
}