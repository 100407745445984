import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css"; // Flatpickr styles
import MDInput from "components/MDInput"; // Custom MDInput component

function MDDatePicker({input = {}, ...rest}) {
    return (
        <Flatpickr
            {...rest}
            options={{
                ...rest.options,
                onReady: (selectedDates, dateStr, instance) => {
                    instance.input.style.width = '100%'; // Set full width on load
                },
            }}
            render={({defaultValue}, ref) => (
                <MDInput
                    {...input}
                    defaultValue={defaultValue}
                    inputRef={ref}
                    sx={{
                        width: '100%',        // Full width to match other inputs
                        height: '40px',       // Ensure consistent height with other inputs
                        '& input': {
                            width: '100% !important',  // Enforce full width
                            minWidth: '200px',         // Prevent shrinking
                            height: '40px',            // Same height as other MDInputs
                            padding: '8px 12px',       // Consistent padding
                            fontSize: '16px',          // Prevent zoom on mobile
                            lineHeight: '1.5',         // Align text properly
                            boxSizing: 'border-box',   // Include padding in width
                        },
                    }}
                />
            )}
        />
    );
}

MDDatePicker.propTypes = {
    input: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePicker;
