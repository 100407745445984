import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import 'react-circular-progressbar/dist/styles.css';
import Grid from "@mui/material/Grid";
import HorizontalBarChart from "./Charts/BarCharts/HorizontalBarChart";
import {formatCurrency, formatPercentage} from "../../../util/generalUtils";
import {useState} from "react";
import DefaultCell from "../../../components/DataTable/components/DefaultCell";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../../components/DataTable";
import Modal from "@mui/material/Modal";
import {AddProductForm} from "./AddProductForm";
import {EditProductForm} from "./EditProductForm";
import ChatToAdvisor from "./ChatToAdvisor";

export const DisabilityProvision = (props) => {


    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleOpenAddModal = () => {
        setModalType('add'); // Set type to 'add'
        setSelectedProduct(null); // No product selected for adding
        setOpenModal(true);
    };

    const handleOpenEditModal = (product) => {
        setModalType('edit'); // Set type to 'edit'
        setSelectedProduct(product); // Set the product to be edited
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const createProductRows = (products) => {
        return products.map((product) => ({
            provider: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{product.productProvider}</DefaultCell>
                </div>
            ),
            currentValue: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{formatCurrency(product.productBenefit)}</DefaultCell>
                </div>
            ),
            contribution: (
                <div onClick={() => handleOpenEditModal(product)} style={{cursor: 'pointer'}}>
                    <DefaultCell>{formatCurrency(product.productPremium)}</DefaultCell>
                </div>
            ),
        }));
    };

    const horizontalBarChartData = {
        labels: ["Benefit Available", "Benefit Used", "Benefit Remaining"],
        datasets: [
            {
                label: "Benefit",
                color: ["dark", "info", "primary"],
                data: [props.disabilityTotalNeeded, props.disabilityProvision, props.disabilityShortfall],
            },
        ],
    };
    return (
        <MDBox p={2}>
            <MDTypography variant="h4" sx={{mt: 1, mb: 1, pt: 2, pb: 1, px: 2}}>
                Provision For Disability
            </MDTypography>
            <MDTypography variant="h5" fontWeight="medium" sx={{mt: 1, mb: 1, pt: 2, pb: 2, px: 2}}>
                You have {formatPercentage(props.disabilityPercentageOptimised)} of
                the cover you need for disability.
            </MDTypography>
            <MDBox mt={1} pb={2} px={2}>
                <MDBox mb={2}>
                    <MDTypography variant="h6" sx={{mt: 2, mb: 1}}>
                        In the unfortunate event that you become disabled and lose the ability to earn an income, it is
                        crucial to have the necessary provisions in place to care for yourself and your loved ones.
                        Planning for disability ensures peace of mind, providing security and support if life takes an
                        unexpected turn.
                    </MDTypography>
                    <ChatToAdvisor source={'FNA Disability'}/>
                </MDBox>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{p: 2, height: "21rem"}}>
                            <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                                   height="100%">
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Cover needed:
                                </MDTypography>
                                <MDTypography variant="h6" fontWeight="medium">Est. {props.disabilityIncomePercentage}%
                                    Income Replacement
                                    for {props.disabilityYears} years:</MDTypography>
                                <MDTypography
                                    color="dark">{formatCurrency(props.disabilityIncomeNeeded)}</MDTypography>
                                <MDTypography variant="h6" fontWeight="medium">Est. Debt/Other Coverage:</MDTypography>
                                <MDTypography
                                    color="dark">{formatCurrency(props.disabilityOtherNeeded)}</MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Est. Cover Available:
                                </MDTypography>
                                <MDTypography color="info">{formatCurrency(props.disabilityProvision)}</MDTypography>
                                <MDTypography variant="h6" fontWeight="medium" mt={1}>
                                    Est. Shortfall:
                                </MDTypography>
                                <MDTypography color="primary">{formatCurrency(props.disabilityShortfall)}</MDTypography>

                            </MDBox>
                        </Card>
                    </Grid>

                    {/* Bar Chart Card (Right) */}
                    <Grid item xs={12} md={6}>
                        <Card sx={{p: 2, height: "21rem"}}>
                            <HorizontalBarChart
                                icon={{color: "dark", component: "splitscreen"}}
                                title="Bar chart horizontal"
                                height="20rem"
                                description="Sales related to age average"
                                chart={horizontalBarChartData}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={3}>
                <Card>
                    <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} pt={3} px={3}>
                        <MDTypography variant="h6" fontWeight="medium" align="left">
                            Existing Disability Products
                        </MDTypography>
                        <IconButton onClick={handleOpenAddModal} color="info" sx={{mt: 1}}>
                            <AddIcon/>
                            <MDTypography variant="button" ml={1}>
                                Add Existing Products
                            </MDTypography>
                        </IconButton>
                        {props.products.length > 0 && (
                            <MDTypography mt={2} variant="h6" color='info'>
                                You can edit or delete a product by clicking on it.
                            </MDTypography>
                        )}
                    </MDBox>
                    <MDBox py={1}>
                        {props.products.length === 0 ? (
                            // Display this if there are no products
                            <MDBox
                                sx={{
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    backgroundColor: '#f9f9f9', // Light background
                                    wordWrap: 'break-word', // Ensure text wraps properly
                                    overflowWrap: 'break-word',
                                }}
                            >
                                <MDTypography variant="h6" sx={{wordWrap: 'break-word'}}>
                                    There are no products added yet. You can add products with the button above.
                                </MDTypography>
                            </MDBox>
                        ) : (
                            // Display the table if there are products
                            <DataTable
                                table={{
                                    columns: [
                                        {Header: "Provider", accessor: "provider"},
                                        {Header: "Cover", accessor: "currentValue"},
                                        {Header: "Premium", accessor: "contribution"},
                                    ],
                                    rows: createProductRows(props.products), // Render product rows
                                }}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                isSorted={false}
                                noEndBorder
                            />
                        )}
                    </MDBox>


                    <Modal open={openModal} onClose={handleCloseModal}>
                        <MDBox
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                bgcolor: "background.paper",
                                border: "2px solid #000",
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            {modalType === 'add' ? (  // Conditional rendering based on modal type
                                <AddProductForm onClose={handleCloseModal} productType={props.productType}
                                                routeType={props.routeType}/>
                            ) : (
                                <EditProductForm
                                    product={selectedProduct}  // Pass the selected product for editing
                                    onClose={handleCloseModal}
                                    productType={props.productType}
                                    routeType={props.routeType}
                                />
                            )}
                        </MDBox>
                    </Modal>
                </Card>
            </MDBox>
        </MDBox>
    );
};