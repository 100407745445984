import {PropagateLoader} from 'react-spinners';
import {useTheme} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MDBox from 'components/MDBox'; // Custom MDBox component
import logo from 'assets/images/logos/gybpig500x500.png'; // Ensure correct path

const Loader = () => {
    const theme = useTheme();  // Access Material UI theme

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                height: '100vh',
                bgcolor: 'rgba(255, 255, 255, 0.9)',  // Semi-transparent background
                textAlign: 'center',
            }}
        >
            <Grid item>
                <MDBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{gap: 2}}
                >
                    {/* Logo */}
                    <MDBox
                        component="img"
                        src={logo}
                        alt="Loading Logo"
                        sx={{width: 150, height: 'auto'}}
                    />

                    {/* Loading Text */}
                    <Typography
                        variant="h6"
                        sx={{color: theme.palette.text.primary}}
                    >
                        Loading
                    </Typography>

                    {/* Spinner Adjusted Two Ticks to the Left */}
                    <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{width: '100%', mt: 2}}
                    >
                        <PropagateLoader
                            size={15}
                            color={theme.palette.info.main}
                            loading
                            cssOverride={{marginLeft: '-12px'}}  // Adjust spinner position
                        />
                    </MDBox>
                </MDBox>
            </Grid>
        </Grid>
    );
};

export default Loader;
