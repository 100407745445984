/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useMemo} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {Bar} from "react-chartjs-2";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from "chart.js";

// @mui material components
import MDBox from "../../../../../../components/MDBox";

// HorizontalBarChart configurations
import configs from "./configs";

// Material Dashboard 2 PRO React base styles
import colors from "../../../../../../assets/theme/base/colors";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function HorizontalBarChart({
                                height = "19.125rem",
                                chart
                            }) {
    const chartDatasets = chart.datasets
        ? chart.datasets.map((dataset) => ({
            ...dataset,
            weight: 5,
            borderWidth: 0,
            borderRadius: 4,
            backgroundColor: dataset.color.map((color) =>
                colors[color] ? colors[color].main : colors.dark.main),
            fill: false,
            maxBarThickness: 35,
        }))
        : [];

    const {data, options} = configs(chart.labels || [], chartDatasets);

    const renderChart = useMemo(
        () => (
            <MDBox height={height}>
                <Bar data={data} options={options} redraw/>
            </MDBox>
        ),
        [chart, height]
    );

    return renderChart;
}

// Typechecking props for the HorizontalBarChart
HorizontalBarChart.propTypes = {
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default HorizontalBarChart;