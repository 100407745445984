import React, {useEffect, useState} from 'react';
import {useLoaderData, useRevalidator} from 'react-router-dom';
import axios from 'axios';
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "components/DataTable";
import DefaultCell from "components/DataTable/components/DefaultCell";
import Modal from "@mui/material/Modal";
import {getCsrfTokenFromCookies} from "util/getCsrfTokenFromCookies";

export const ClientMessages = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState({columns: [], rows: []});
    const messagesData = useLoaderData();
    const revalidator = useRevalidator();

    useEffect(() => {
        if (Array.isArray(messagesData)) {
            const columns = [
                {Header: "Status", accessor: "status"},
                {Header: "Client", accessor: "client"},
                {Header: "Message", accessor: "message"},
            ];
            const rows = createMessageRows(messagesData);
            setTableData({columns, rows});
        } else {
            console.error('Unexpected messages data structure:', messagesData);
        }
    }, [messagesData]);

    const handleMessageClick = async (message) => {
        setSelectedMessage(message);
        setOpenModal(true);

        if (!message.read) {
            try {
                const csrfToken = await getCsrfTokenFromCookies();
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/provider/markAsRead/${message._id}`,
                    {},
                    {
                        withCredentials: true,
                        headers: {
                            "x-csrf-token": csrfToken,
                        },
                    }
                );
                message.read = true;
            } catch (error) {
                console.error('Error marking message as read:', error);
            }
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedMessage(null);
        revalidator.revalidate();
    };

    const createMessageRows = (messages) => {
        return messages.map((message) => ({
            status: (
                <DefaultCell>
                    {message.read !== undefined ? (
                        message.read ? (
                            <Icon fontSize="small" color="success">mark_email_read</Icon>
                        ) : (
                            <Icon fontSize="small" color="error">mark_email_unread</Icon>
                        )
                    ) : null}
                </DefaultCell>
            ),
            client: (
                <DefaultCell>{message.user ? `${message.user.firstname} ${message.user.lastname}` : 'N/A'}</DefaultCell>
            ),
            message: (
                <DefaultCell>
                    {message.message ? (
                        <MDButton variant="text" color="info" onClick={() => handleMessageClick(message)}>
                            {message.message.length > 10
                                ? `${message.message.substring(0, 10)}...`
                                : message.message}
                        </MDButton>
                    ) : 'No message'}
                </DefaultCell>
            ),
        }));
    };

    return (
        <MDBox>
            <Card sx={{width: "100%", marginTop: 1, marginBottom: 1}}>
                <MDBox pt={2} pb={2}>
                    <MDBox display="flex" flexDirection="column" alignItems="center" mt={3} pt={3} px={3}>
                        <MDTypography variant="h6" fontWeight="medium" align="left">
                            Messages
                        </MDTypography>
                    </MDBox>
                    <MDBox py={1}>
                        {tableData.rows.length > 0 ? (
                            <DataTable
                                table={tableData}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                isSorted={false}
                                noEndBorder
                            />
                        ) : (
                            <MDTypography variant="body2" align="center">
                                No messages to display
                            </MDTypography>
                        )}
                    </MDBox>
                </MDBox>
            </Card>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="message-modal-title"
                aria-describedby="message-modal-description"
            >
                <MDBox
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {selectedMessage && (
                        <>
                            <MDTypography id="message-modal-title" variant="h6" component="h2">
                                Message from {selectedMessage.user.firstname} {selectedMessage.user.lastname}
                            </MDTypography>
                            <MDTypography id="message-modal-description" sx={{mt: 2}}>
                                {selectedMessage.message}
                            </MDTypography>
                        </>
                    )}
                    <MDButton onClick={handleCloseModal}>Close</MDButton>
                </MDBox>
            </Modal>
        </MDBox>
    );
};

export const clientMessagesLoader = async ({params}) => {
    try {
        const csrfToken = await getCsrfTokenFromCookies();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider/getClientMessages/${params.clientId}`, {
            withCredentials: true,
            headers: {
                "x-csrf-token": csrfToken,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error loading messages:', error);
        throw error;
    }
};