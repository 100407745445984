import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import MKBox from "components/MDBox";
import MKButton from "components/MDButton";
import DefaultNavbarDropdown from "./DefaultNavbarDropdown";
import DefaultNavbarMobile from "./DefaultNavbarMobile";
import breakpoints from "assets/theme/base/breakpoints";
import logo from "assets/images/logos/GYBMaillogosmall.png";
import MuiLink from "@mui/material/Link";

function DefaultNavbar({
                           brand = "GrowYourBucks",
                           routes,
                           transparent = false,
                           light = false,
                           actions = [],
                           sticky = false,
                           relative = false,
                           center = false,
                       }) {
    const [mobileNavbar, setMobileNavbar] = useState(false);
    const [mobileView, setMobileView] = useState(false);

    const openMobileNavbar = () => setMobileNavbar(true);
    const closeMobileNavbar = () => setMobileNavbar(false);
    const navigate = useNavigate();
    useEffect(() => {
        function displayMobileNavbar() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
                setMobileNavbar(false);
            } else {
                setMobileView(false);
                setMobileNavbar(false);
            }
        }

        window.addEventListener("resize", displayMobileNavbar);
        displayMobileNavbar();

        return () => window.removeEventListener("resize", displayMobileNavbar);
    }, []);

    const renderNavbarItems = routes.map((route, index) => {
        const {name, icon, href, route: routePath, collapse} = route;

        if (!name) return null;

        return (
            <DefaultNavbarDropdown
                key={`${name}-${index}`}
                name={name}
                icon={icon}
                href={href}
                route={routePath}
                collapse={Boolean(collapse)}
                light={light}
            />
        );
    });

    const renderActionButtons = actions.map((action, index) => (
        <MKButton
            key={`action-${index}`}
            component={action.type === "internal" ? Link : MuiLink}
            to={action.type === "internal" ? action.route : ""}
            href={action.type === "external" ? action.route : undefined}
            variant={
                action.color === "white" || action.color === "default"
                    ? "contained"
                    : "gradient"
            }
            color={action.color ? action.color : "info"}
            size="small"
            sx={{ml: 2}}
            onClick={(e) => {
                if (action.type === "internal") {
                    e.preventDefault();  // Prevent reload for internal links
                    navigate(action.route);  // Use navigate to trigger loader
                } else if (action.type === "scroll" && action.action) {
                    action.action();
                }
            }}
        >
            {action.label}
        </MKButton>
    ));

    return (
        <Container sx={sticky ? {position: "sticky", top: 0, zIndex: 10} : null}>
            <MKBox
                py={1}
                px={{xs: 4, sm: 3, lg: 2}}
                my={relative ? 0 : 2}
                mx={relative ? 0 : 3}
                width={relative ? "100%" : "calc(100% - 48px)"}
                borderRadius="xl"
                shadow="md"
                color={light ? "white" : "dark"}
                position={relative ? "relative" : "absolute"}
                left={0}
                zIndex={3}
                sx={({palette: {white}, functions: {rgba}}) => ({
                    backgroundColor: white.main,
                })}
            >
                <MKBox display="flex" justifyContent="space-between" alignItems="center">
                    <MKBox component={Link} to="/" lineHeight={1} py={0.75}>
                        <img
                            src={logo}
                            alt="Brand Logo"
                            style={{
                                height: "auto",
                                maxHeight: "50px",
                                maxWidth: "150px",
                                width: "auto",
                                objectFit: "contain",
                            }}
                        />
                    </MKBox>
                    <MKBox
                        color="inherit"
                        display={{xs: "none", lg: "flex"}}
                        ml="auto"
                        mr={center ? "auto" : 0}
                    >
                        {renderNavbarItems}
                        {renderActionButtons}
                    </MKBox>
                    <MKBox
                        display={{xs: "inline-block", lg: "none"}}
                        lineHeight={0}
                        py={1.5}
                        pl={1.5}
                        color="inherit"
                        sx={{cursor: "pointer"}}
                        onClick={mobileNavbar ? closeMobileNavbar : openMobileNavbar}
                    >
                        <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
                    </MKBox>
                </MKBox>
                {mobileView && (
                    <DefaultNavbarMobile
                        routes={routes}
                        open={mobileNavbar}
                        actions={actions}
                        handleClose={closeMobileNavbar}
                    />
                )}
            </MKBox>
        </Container>
    );
}

DefaultNavbar.propTypes = {
    brand: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
    transparent: PropTypes.bool,
    light: PropTypes.bool,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(["external", "internal", "scroll"]).isRequired,
            route: PropTypes.string,
            color: PropTypes.oneOf([
                "primary",
                "secondary",
                "info",
                "success",
                "warning",
                "error",
                "dark",
                "light",
                "default",
                "white",
            ]),
            label: PropTypes.string.isRequired,
            action: PropTypes.func,
        })
    ),
    sticky: PropTypes.bool,
    relative: PropTypes.bool,
    center: PropTypes.bool,
};

export default DefaultNavbar;
