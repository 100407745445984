import {useState} from "react";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import axios from 'axios';
import {getCsrfTokenFromCookies} from "util/getCsrfTokenFromCookies";
import ConfiguratorRoot from "./ConfiguratorRoot";
import {setOpenConfigurator, useMaterialUIController} from "context";

function Configurator() {
    const [controller, dispatch] = useMaterialUIController();
    const {openConfigurator, darkMode} = controller;

    const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

    const [loading, setLoading] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [monthlyIncome, setMonthlyIncome] = useState("");
    const [dependents, setDependents] = useState("");
    const [errors, setErrors] = useState({});

    // States for individual tooltips
    const [tooltipOpenIncome, setTooltipOpenIncome] = useState(false);
    const [tooltipOpenDependents, setTooltipOpenDependents] = useState(false);

    const validateInputs = () => {
        const newErrors = {};
        if (!dateOfBirth || isNaN(new Date(dateOfBirth).getTime())) {
            newErrors.dateOfBirth = "Valid date of birth is required. Use the format as specified above";
        }
        if (!monthlyIncome || isNaN(monthlyIncome) || monthlyIncome <= 0) {
            newErrors.monthlyIncome = "Valid monthly income is required and cannot be empty. Income can only be a number and must be more than zero";
        }
        if (!dependents || isNaN(dependents) || dependents < 0) {
            newErrors.dependents = "Valid number of dependents is required. Dependents can be a";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleUpdate = async () => {
        if (!validateInputs()) return;
        setLoading(true);
        try {
            const csrfToken = await getCsrfTokenFromCookies();
            await axios.put(`${process.env.REACT_APP_API_URL}/clients/updateProfile`, {
                    dob: dateOfBirth,
                    income: monthlyIncome,
                    dependents: dependents,
                }, {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );
            setLoading(false);
            handleCloseConfigurator();
            window.location.reload();
        } catch (error) {
            console.error("Error updating profile:", error);
            setLoading(false);
        }
    };

    // Tooltip toggle functions for Income
    const handleTooltipToggleIncome = () => {
        setTooltipOpenIncome(!tooltipOpenIncome);
    };
    const handleTooltipCloseIncome = () => {
        setTooltipOpenIncome(false);
    };

    // Tooltip toggle functions for Dependents
    const handleTooltipToggleDependents = () => {
        setTooltipOpenDependents(!tooltipOpenDependents);
    };
    const handleTooltipCloseDependents = () => {
        setTooltipOpenDependents(false);
    };

    return (
        <ConfiguratorRoot variant="permanent" ownerState={{openConfigurator}} sx={{overflow: 'auto'}}>
            <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={4}
                pb={0.5}
                px={3}
            >
                <MDTypography variant="h5">Edit Profile</MDTypography>
                <Icon
                    sx={({typography: {size}, palette: {dark, white}}) => ({
                        fontSize: `${size.lg} !important`,
                        color: darkMode ? white.main : dark.main,
                        stroke: "currentColor",
                        strokeWidth: "2px",
                        cursor: "pointer",
                        transform: "translateY(5px)",
                    })}
                    onClick={handleCloseConfigurator}
                >
                    close
                </Icon>
            </MDBox>
            <Divider/>
            <MDBox p={3}>
                <MDBox mb={1}>
                    <MDTypography variant='h4'>Edit Profile</MDTypography>
                </MDBox>
                <MDBox display="flex"
                       flexDirection="column"
                       flexGrow={1}
                       overflow="auto"
                       mb={2}
                       sx={{maxHeight: '100vh', overflow: 'auto'}}>
                    <MDTypography variant='h6'
                                  noWrap={false}
                                  sx={{
                                      wordBreak: 'break-word',
                                      whiteSpace: 'normal',
                                      textAlign: 'justify'
                                  }}>
                        You can make changes to your profile here.
                    </MDTypography>
                </MDBox>
                <form>
                    {/* Date of Birth Field */}
                    <MDTypography variant="h6" fontWeight="medium" color="dark">
                        What is your date of birth?
                    </MDTypography>
                    <MDBox mb={2} display="flex" alignItems="center">
                        <MDDatePicker
                            input={{placeholder: "Select a date"}}
                            value={dateOfBirth}
                            onChange={(date) => setDateOfBirth(date)}  // Update date state
                        />
                    </MDBox>

                    {/* Monthly Income Field */}
                    <MDTypography variant="h6" fontWeight="medium" color="dark">
                        What is your gross/total monthly income?
                    </MDTypography>
                    <MDBox mb={2} display="flex" alignItems="center">
                        <MDInput
                            type="number"
                            value={monthlyIncome}
                            onChange={(e) => setMonthlyIncome(e.target.value)}  // Update income state
                            input={{placeholder: "Enter your monthly income"}}
                        />
                        <Tooltip
                            title="Time to crunch some numbers! Please share your taxable income (or your gross monthly income - the big number on your payslip, before deductions). Don't sweat the exact amount, a good estimate is cool, and you can tweak later!"
                            arrow
                            open={tooltipOpenIncome}  // Tooltip open state for Income
                            onClose={handleTooltipCloseIncome}  // Close tooltip
                            disableHoverListener={false}  // Hover on desktop is enabled
                            disableTouchListener={false}  // Tooltip is interactive on mobile
                            enterTouchDelay={0}  // Immediate on mobile touch
                            leaveTouchDelay={999999}  // Prevents auto-close on mobile, waits for explicit action
                        >
                            <Box display="flex" alignItems="center" sx={{cursor: 'pointer', ml: 1}}
                                 onClick={handleTooltipToggleIncome}  // Toggle on click/touch
                                 onMouseEnter={handleTooltipToggleIncome}  // Toggle on hover
                                 onMouseLeave={handleTooltipCloseIncome}  // Close on hover away (desktop only)
                            >
                                <Typography variant="subtitle2" color="textSecondary">
                                    Unsure? Click here...
                                </Typography>
                                <IconButton size="small">
                                    <HelpOutlineIcon/>
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </MDBox>

                    {/* Dependents Field */}
                    <MDTypography variant="h6" fontWeight="medium" color="dark">
                        How many dependents do you have?
                    </MDTypography>
                    <MDBox mb={2} display="flex" alignItems="center">
                        <MDInput
                            type="number"
                            value={dependents}
                            onChange={(e) => setDependents(e.target.value)}  // Update dependents state
                            input={{placeholder: "Enter the number of dependents"}}
                        />
                        <Tooltip
                            title="For tax purposes, we need to know about your dependent crew! Who's counting on you? Share how many dependents you've got, including kids, partner, or financially dependent young adults (21 or under, or 26 if they're still a student). This helps us run the numbers for your financial analysis!"
                            arrow
                            open={tooltipOpenDependents}  // Tooltip open state for Dependents
                            onClose={handleTooltipCloseDependents}  // Close tooltip
                            disableHoverListener={false}  // Hover on desktop is enabled
                            disableTouchListener={false}  // Tooltip is interactive on mobile
                            enterTouchDelay={0}  // Immediate on mobile touch
                            leaveTouchDelay={999999}  // Prevents auto-close on mobile, waits for explicit action
                        >
                            <Box display="flex" alignItems="center" sx={{cursor: 'pointer', ml: 1}}
                                 onClick={handleTooltipToggleDependents}  // Toggle on click/touch
                                 onMouseEnter={handleTooltipToggleDependents}  // Toggle on hover
                                 onMouseLeave={handleTooltipCloseDependents}  // Close on hover away (desktop only)
                            >
                                <Typography variant="subtitle2" color="textSecondary">
                                    Unsure? Click here...
                                </Typography>
                                <IconButton size="small">
                                    <HelpOutlineIcon/>
                                </IconButton>
                            </Box>
                        </Tooltip>
                    </MDBox>

                    {/* Error Messages */}
                    {Object.keys(errors).length > 0 && (
                        <MDBox display="flex"
                               flexDirection="column"
                               flexGrow={1}
                               overflow="auto"
                               mb={2}
                               sx={{maxHeight: '100vh', overflow: 'auto'}}>
                            {Object.values(errors).map((error, index) => (
                                <MDTypography variant='h6'
                                              key={index}
                                              color="error"
                                              noWrap={false}
                                              sx={{
                                                  wordBreak: 'break-word',
                                                  whiteSpace: 'normal',
                                                  textAlign: 'justify'
                                              }}>
                                    {error}
                                </MDTypography>
                            ))}
                        </MDBox>
                    )}

                    <MDBox display="flex" justifyContent="flex-end" mt={2}>
                        <MDButton
                            variant="text"
                            color="secondary"
                            onClick={handleCloseConfigurator}
                            disabled={loading}
                        >
                            Cancel
                        </MDButton>
                        <MDButton
                            variant="contained"
                            color="primary"
                            onClick={handleUpdate}
                            disabled={loading}
                            sx={{ml: 2}}
                        >
                            {loading ? "Updating..." : "Update"}
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>
        </ConfiguratorRoot>
    );
}

export default Configurator;