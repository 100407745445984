/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2024 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavItem
import {item, itemArrow, itemContent} from "./styles/sidenavItem";

// Material Dashboard 2 PRO React contexts
import {useMaterialUIController} from "context";

function SidenavItem({color = 'info', name, active = false, nested = false, children = false, open = false, ...rest}) {
    const [controller] = useMaterialUIController();
    const {miniSidenav, transparentSidenav, whiteSidenav, darkMode} = controller;

    return (
        <>
            <ListItem
                {...rest}
                component="li"
                sx={(theme) => item(theme, {active, color, transparentSidenav, whiteSidenav, darkMode})}
            >
                <MDBox
                    sx={(theme) =>
                        itemContent(theme, {
                            active,
                            miniSidenav,
                            name,
                            open,
                            nested,
                            transparentSidenav,
                            whiteSidenav,
                            darkMode,
                        })
                    }
                >
                    <ListItemText primary={name}/>
                    {children && (
                        <Icon
                            component="i"
                            sx={(theme) =>
                                itemArrow(theme, {open, miniSidenav, transparentSidenav, whiteSidenav, darkMode})
                            }
                        >
                            expand_less
                        </Icon>
                    )}
                </MDBox>
            </ListItem>
            {children && (
                <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
                    {children}
                </Collapse>
            )}
        </>
    );
}

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    name: PropTypes.string.isRequired,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    nested: PropTypes.bool,
    children: PropTypes.node,
    open: PropTypes.bool,
};

export default SidenavItem;
