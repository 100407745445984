import React from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {useMaterialUIController} from "context";

function Sidenav({basicProfileSetup}) {
    const [controller] = useMaterialUIController();
    const {darkMode} = controller;
    const location = useLocation();
    const {clientId} = useParams();

    const baseUrl = `/app/admin/client-list/client-details/${clientId}`;

    const sidenavItems = [
        {icon: "person", label: "profile", to: baseUrl},
        {icon: "assured_workload", label: "tax", to: `${baseUrl}/tax`},
        {icon: "account_balance_wallet", label: "fna", to: `${baseUrl}/fna`},
        {icon: "message", label: "messages", to: `${baseUrl}/messages`},
        {icon: "calendar_month", label: "activity", to: `${baseUrl}/activity`},
        {icon: "calculate", label: "assumptions", to: `${baseUrl}/assumptions`},
    ];

    const renderSidenavItems = sidenavItems.map(({icon, label, to}, key) => {
        const itemKey = `item-${key}`;
        const isActive = location.pathname === to;

        const commonStyles = {
            display: "flex",
            alignItems: "center",
            width: "100%",
            borderRadius: ({borders: {borderRadius}}) => borderRadius.md,
            padding: ({functions: {pxToRem}}) => `${pxToRem(10)} ${pxToRem(16)}`,
            transition: ({transitions}) =>
                transitions.create("background-color", {
                    easing: transitions.easing.easeInOut,
                    duration: transitions.duration.shorter,
                }),
            backgroundColor: isActive ? ({palette: {light}}) => light.main : "transparent",
            "&:hover": {
                backgroundColor: basicProfileSetup ? ({palette: {light}}) => light.main : "transparent",
            },
            cursor: basicProfileSetup ? "pointer" : "not-allowed",
            opacity: basicProfileSetup ? 1 : 0.5,
        };

        const ContentComponent = basicProfileSetup ? Link : 'div';

        return (
            <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
                <MDTypography
                    component={ContentComponent}
                    to={basicProfileSetup ? to : undefined}
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    sx={commonStyles}
                    onClick={basicProfileSetup ? undefined : (e) => e.preventDefault()}
                >
                    <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                        <Icon fontSize="small">{icon}</Icon>
                    </MDBox>
                    {label}
                </MDTypography>
            </MDBox>
        );
    });

    return (
        <Card
            sx={{
                borderRadius: ({borders: {borderRadius}}) => borderRadius.lg,
                position: "sticky",
                top: "1%",
            }}
        >
            <MDBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{listStyle: "none"}}
            >
                {renderSidenavItems}
            </MDBox>
        </Card>
    );
}

export default Sidenav;