import React, {useState} from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import axios from 'axios';
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";
import {useParams, useRevalidator} from "react-router-dom";


export const EditProductForm = (props) => {
    const {clientId} = useParams();
    const [formValues, setFormValues] = useState({
        productType: props.product?.productType || "",
        productProvider: props.product?.productProvider || "",
        productBenefit: props.product?.productBenefit || "",
        productPremium: props.product?.productPremium || "",
    });
    const [errors, setErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);  // State to control dialog
    const {revalidate} = useRevalidator();
    // Full form validation (similar to AddProductForm)
    const validateForm = () => {
        const newErrors = {};

        if (!formValues.productProvider) {
            newErrors.productProvider = "Product provider is required.";
        }

        // Skip benefit validation if product type is MedicalAid
        if (formValues.productType !== 'MedicalAid') {
            if (!formValues.productBenefit || isNaN(formValues.productBenefit) || Number(formValues.productBenefit) < 0) {
                newErrors.productBenefit = "Product benefit must be a number greater than 0.";
            }
        }

        if (!formValues.productPremium || isNaN(formValues.productPremium) || Number(formValues.productPremium) < 0) {
            newErrors.productPremium = "Product premium must be a number greater than 0.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // If no errors, form is valid
    };

    // Handle form field changes
    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    // Handle form submission for updating the product
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsSaving(true);
        setErrorMessage("");

        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const benefitValue = formValues.productType === 'MedicalAid' ? 1 : formValues.productBenefit;
            let apiUrl
            if (props.routeType === 'client') {
                apiUrl = `${process.env.REACT_APP_API_URL}/clients/editProduct`
            } else if (props.routeType === 'admin') {
                apiUrl = `${process.env.REACT_APP_API_URL}/provider/editProduct/${clientId}`
            } else {
                setErrorMessage("Invalid route type")
            }

            await axios.put(apiUrl,
                {
                    productType: props.product.productType,
                    productProvider: formValues.productProvider,
                    productBenefit: benefitValue,
                    productPremium: formValues.productPremium,
                    productId: props.product._id  // Use the correct product ID
                },
                {
                    headers: {"x-csrf-token": csrfToken},
                    withCredentials: true,
                });
            props.onClose();
            revalidate(); // Close the modal on success
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "An error occurred. Please try again.");
        } finally {
            setIsSaving(false);
        }
    };

    // Handle dialog opening for delete confirmation
    const openDeleteDialog = () => {
        setIsDialogOpen(true);
    };

    // Handle dialog closing
    const closeDeleteDialog = () => {
        setIsDialogOpen(false);
    };

    // Handle product deletion after confirmation
    const handleDelete = async () => {
        try {
            const csrfToken = await getCsrfTokenFromCookies();

            let apiUrl
            if (props.routeType === 'client') {
                apiUrl = `${process.env.REACT_APP_API_URL}/clients/deleteProduct/${props.product._id}`
            } else if (props.routeType === 'admin') {
                apiUrl = `${process.env.REACT_APP_API_URL}/provider/deleteProduct/${props.product._id}/client/${clientId}`
            } else {
                setErrorMessage("Invalid route type")
            }

            await axios.delete(apiUrl, {
                headers: {"x-csrf-token": csrfToken},
                withCredentials: true,
            });
            props.onClose(); // Close the modal on success
            revalidate(); // Reload the page to reflect changes
        } catch (error) {
            setErrorMessage("Error deleting product.");
        }
    };

    // Update labels based on the product type
    const fieldLabels = {
        Retirement: {
            benefit: "Current Fund Value If Any",
            premium: "Monthly Contribution",
        },
        TaxFree: {
            benefit: "Total Contributions to date",
            premium: "Monthly Contribution",
        },
        MedicalAid: {
            benefit: "Tax Benefit",
            premium: "Amount of Dependents",
        },
        Life: {
            benefit: "Cover",
            premium: "Premium",
        },
        Disability: {
            benefit: "Cover",
            premium: "Premium",
        },
        CriticalIllness: {
            benefit: "Cover",
            premium: "Premium",
        }
    };

    const currentLabels = fieldLabels[formValues.productType] || fieldLabels.retirement;

    return (
        <MDBox component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={2}>
            <MDTypography variant="h5" fontWeight="medium" mb={2}>
                Edit Product
            </MDTypography>

            {/* Product Provider */}
            <MDBox display="flex" alignItems="center">
                <MDInput
                    label="Product Provider"
                    name="productProvider"
                    value={formValues.productProvider}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.productProvider}
                    helperText={errors.productProvider}
                />

            </MDBox>

            {/* Product Benefit */}
            <MDBox display="flex" alignItems="center">
                <MDInput
                    label={currentLabels.benefit}
                    name="productBenefit"
                    value={formValues.productType === 'MedicalAid' ? 'Will be calculated' : formValues.productBenefit}
                    onChange={handleChange}
                    fullWidth
                    disabled={formValues.productType === 'MedicalAid'}  // Disable for MedicalAid
                    error={!!errors.productBenefit}
                    helperText={errors.productBenefit}
                />
            </MDBox>


            {/* Product Premium */}
            <MDBox display="flex" alignItems="center">
                <MDInput
                    label={currentLabels.premium}
                    name="productPremium"
                    value={formValues.productPremium}
                    onChange={handleChange}
                    fullWidth
                    error={!!errors.productPremium}
                    helperText={errors.productPremium}
                />

            </MDBox>

            {/* Error Message if Any */}
            {errorMessage && (
                <MDTypography color="error" mt={2}>
                    {errorMessage}
                </MDTypography>
            )}

            {/* Save and Cancel Buttons */}
            <MDBox display="flex" justifyContent="space-between" mt={2}>
                <MDButton variant="gradient" color="info" type="submit" disabled={isSaving}>
                    {isSaving ? "Saving..." : "Save"}
                </MDButton>
                <MDButton variant="outlined" color="secondary" onClick={props.onClose} type="button"
                          disabled={isSaving}>
                    Cancel
                </MDButton>
            </MDBox>

            {/* Delete Button */}
            {props.product && (
                <MDBox mt={2}>
                    <MDButton variant="contained" color="error" onClick={openDeleteDialog}>
                        Delete Product
                    </MDButton>
                </MDBox>
            )}

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={isDialogOpen}
                onClose={closeDeleteDialog}
                fullScreen={window.innerWidth < 600}  // Full-screen on mobile
            >
                <DialogTitle>{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this product? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={closeDeleteDialog} color="secondary">
                        Cancel
                    </MDButton>
                    <MDButton onClick={handleDelete} color="error" autoFocus>
                        Proceed
                    </MDButton>
                </DialogActions>
            </Dialog>
        </MDBox>
    );
};
