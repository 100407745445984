import React, {useState} from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import axios from 'axios';
import {getCsrfTokenFromCookies} from "../../../util/getCsrfTokenFromCookies";
import {useParams, useRevalidator} from "react-router-dom";

export const AddProductForm = (props) => {
    const {clientId} = useParams();
    const [formValues, setFormValues] = useState({
        productType: props.productType,
        productProvider: "",
        productBenefit: "",
        productPremium: "",
    });
    const [errors, setErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {revalidate} = useRevalidator();

    // Full form validation on submit
    const validateForm = () => {
        const newErrors = {};

        if (!formValues.productProvider) {
            newErrors.productProvider = "Product provider is required.";
        }

        // Skip benefit validation if product type is MedicalAid
        if (formValues.productType !== 'MedicalAid') {
            if (!formValues.productBenefit || isNaN(formValues.productBenefit) || Number(formValues.productBenefit) < 0) {
                newErrors.productBenefit = "Product benefit must be a number greater than 0.";
            }
        }

        if (!formValues.productPremium || isNaN(formValues.productPremium) || Number(formValues.productPremium) < 0) {
            newErrors.productPremium = "Product premium must be a number greater than 0.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // If no errors, form is valid
    };

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Full form validation before submitting
        if (!validateForm()) {
            return;
        }

        setIsSaving(true);
        setErrorMessage("");

        try {
            const csrfToken = await getCsrfTokenFromCookies();
            const benefitValue = formValues.productType === 'MedicalAid' ? 1 : formValues.productBenefit;

            let apiUrl
            if (props.routeType === 'client') {
                console.log('client')
                apiUrl = `${process.env.REACT_APP_API_URL}/clients/createProduct`
            } else if (props.routeType === 'admin') {
                console.log('admin')
                apiUrl = `${process.env.REACT_APP_API_URL}/provider/createProduct/${clientId}`
            } else {
                setErrorMessage("Invalid route type")
            }

            await axios.post(apiUrl, {
                    productType: props.productType,
                    productProvider: formValues.productProvider,
                    productBenefit: benefitValue,
                    productPremium: formValues.productPremium
                }, {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": csrfToken,
                    },
                }
            );

            setIsSaving(false);
            props.onClose(); // Close the modal

            // Call parent's loader function to reload the page
            revalidate();
        } catch (error) {
            setIsSaving(false);
            // Log the full error response for debugging purposes
            console.error("Form submission error:", error);
            setErrorMessage(error.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    // Change displayed labels based on product type
    const fieldLabels = {
        Retirement: {
            benefit: "Current Fund Value If Any",
            premium: "Monthly Contribution",
        },
        TaxFree: {
            benefit: "Total Contributions to date",
            premium: "Monthly Contribution",
        },
        MedicalAid: {
            benefit: "Tax Benefit",
            premium: "Amount of Dependents",
        },
        Life: {
            benefit: "Life Cover",
            premium: "Premium",
        },
        Disability: {
            benefit: "Disability Cover",
            premium: "Premium",
        },
        CriticalIllness: {
            benefit: "Critical Illness Cover",
            premium: "Premium",
        }
    };

    const currentLabels = fieldLabels[formValues.productType] || fieldLabels.retirement;

    return (
        <MDBox component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={2}>
            <MDTypography variant="h5" fontWeight="medium" mb={2}>
                Add New Product
            </MDTypography>

            {/* Product Provider */}
            <MDInput
                label="Product Provider/Company"
                name="productProvider"
                value={formValues.productProvider}
                onChange={handleChange}
                fullWidth
                error={!!errors.productProvider}
                helperText={errors.productProvider}
            />

            <MDBox display="flex" alignItems="center">
                <MDInput
                    label={currentLabels.benefit}
                    name="productBenefit"
                    value={formValues.productType === 'MedicalAid' ? 'Will be calculated' : formValues.productBenefit}
                    onChange={handleChange}
                    fullWidth
                    disabled={formValues.productType === 'MedicalAid'}  // Disable for MedicalAid
                    error={!!errors.productBenefit}
                    helperText={errors.productBenefit}
                />
            </MDBox>


            {/* Product Premium */}
            <MDInput
                label={currentLabels.premium}
                name="productPremium"
                value={formValues.productPremium}
                onChange={handleChange}
                fullWidth
                error={!!errors.productPremium}
                helperText={errors.productPremium}
            />

            {errorMessage && (
                <MDTypography color="error" mt={2}>
                    {errorMessage}
                </MDTypography>
            )}

            <MDBox display="flex" justifyContent="space-between" mt={2}>
                <MDButton variant="gradient" color="info" type="submit" disabled={isSaving}>
                    {isSaving ? "Saving..." : "Save"}
                </MDButton>
                <MDButton variant="outlined" color="secondary" onClick={props.onClose} type="button"
                          disabled={isSaving}>
                    Cancel
                </MDButton>
            </MDBox>
        </MDBox>
    );
};
